.page-component{
    position: relative;
}
.page-component:hover .page-component__add_button_box,
.page-component:hover .page-component__component_controls,
.page-component--visible-controls .page-component__add_button_box,
.page-component--visible-controls .page-component__component_controls,
.page-component.page-component--force-visible-controls .page-component__component_controls {
    opacity: 1;
}
.page-component__add_button_box{
    position: absolute;
    z-index: 500;
    left: 0;
    right: 0;
    margin: auto;
    height: 0;
    opacity: 0;
    transform: translateY(-1px);
    text-align: center;
    transition: opacity .1s ease;
}
.page-component__add_button{
    transform: translateY(-50%);
    line-height: 14px;
}
.page-component__add_button.has-bg {
  background-color: #fff !important;
}
.page-component__add_button .anticon {
  line-height: 0;
}
.page-component__component_control--ai {
  background-color: #722ed1;
  display: flex;
  align-items: center;
  gap: 6px;
}
.page-component__component_control--ai path {
  fill: #fff !important;
}
.page-component__component_control--ai:hover,
.page-component__component_control--ai:focus {
  background-color: #9254de; 
  border-color: #9254de;
}
.page-component__component_control--ai:active {
  background-color: #531dab; 
  border-color: #531dab;
}

.page-component__component_visibility{
  left: 20px;
  top: 20px;
  position: absolute;
  z-index: 500;
}
.page-component__component_controls{
    opacity: 0;
    right: 0px;
    padding: 0px 20px;
    z-index: 500;
    transition: opacity .1s ease;
    text-align: right;
    position: absolute;
    width: 100%;
    height: calc(100% - 64px);
    pointer-events: none !important;
}
.page-component__component_controls * {
  pointer-events: auto !important;
}
.page-component__component_controls_list{
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}
.page-component__component_controls .page-component__component_controls_list{
    position: sticky;
    top: 20px;
}
.page-component__component_controls_item{
    list-style: none;

    margin-right: 10px;
}
.page-component__component_control {
  line-height: 0;
  min-width: 32px;
}
.page-component__component_control .anticon {
  line-height: 0;
}
.page-component__editor_fields_list{
    list-style: none;
}



.page-component__box{
    margin-bottom: 50px;
}
.page-component__box:last-child{
    margin-bottom: 0;
}
.page-component__title{
    margin-bottom: 25px;
    line-height: 1.4;
}

.page-component__integrations_list{
    list-style: none;
}
.page-component__integration_item{
    margin-bottom: 15px;
}
.page-component__integration_item:last-child{
    margin-bottom: 0;
}
.page-component__integration_not_ready{
    margin-top: 30px;
    padding: 20px;
    background-color: var(--main-bg-gray);
    border-radius: 10px;

    text-align: center;
}
.page-component__img_box{
    display: inline-block;
    width: 26px;
    text-align: center;
    margin-right: 5px;
}

.page-component__bg_overlay_box{
    transition: background-color 0.25s ease; /*we want the color to change in a fancy way in the editor*/
}

.page-component__component_id{

}
.page-component__padding_icon{
  vertical-align: middle;
  position: relative;
  bottom: 1px;
  margin-right: 6px;
}

.page-component__margin_tuner{
  text-align: center;
}
.page-component__margin_tuner_item{
  margin-bottom: 10px;
}
.page-component__margin_tuner_item:last-child{
  margin-bottom: 0;
}
.page-component__margin_tuner_label{
  display: inline-block;
  width: 130px;
}

.page-component__copy_blink_animation{
  transition: 0.2s ease-in-out all;
}
.page-component__margin_tuner_box{
  padding: 5px 15px;
}
.comps .editor__component .page-component__gpt-loading {
  max-height: none;
}

.page-component__gpt-loading-wrapper .ant-spin-dot>i {
  background-color: #722ed1;
}

.highlighted-on-focus:after{
  content: '';
  box-shadow: 0 -2px 0 0 var(--ant), 2px -2px 0 0 var(--ant), -2px -2px 0 0 var(--ant);
  width: 100%;
  display: none;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none !important;
}
.highlighted-on-focus:before{
  content: '';
  box-shadow: 0 0 0 2px var(--ant);
  width: 100%;
  display: none;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

.highlighted-on-focus:hover:after{
  display: block;
  z-index: 600;
}
.highlighted-on-focus:hover:before{
  display: block;
  z-index: 450;
}

.highlighted-on-focus.is-being-edited:after {
  display: block;
  z-index: 600;
}
.highlighted-on-focus.is-being-edited:before{
  display: block;
  z-index: 450;
}

.page-component__margin_tuner_submenu .ant-dropdown-menu-submenu-arrow {
  right: 4px;
}

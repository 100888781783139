.after_form_submission_config__action_box{
    margin-bottom: 30px;
}
.after_form_submission_config__action_box:last-child{
    margin-bottom: 0;
}

.after_form_submission_config__tag{
    cursor: default;
    margin-bottom: 5px;
}

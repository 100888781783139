.custom-color-palette{
    display: inline-block;

    vertical-align: top;

    margin-bottom: 60px;

    text-align: center;
}
.custom-color-palette:not(.custom-color-palette--active){
    margin-right: 30px;
}
/*.custom-color-palette:hover .custom-color-palette__card{*/
    /*transform: translateY(-5px);*/
/*}*/
.custom-color-palette__card{
    position: relative;
    overflow: hidden;
    z-index: 10;

    min-height: 206px;
    padding: 20px 20px 20px 15px;
    margin-bottom: 20px;
    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    background-color: white;

    text-align: left;

    /*transition: box-shadow 0.25s ease, transform 0.25s ease;*/
}

.custom-color-palette__button_box{
    position: relative;
}
.custom-color-palette__button_box::after{
    content: "";
    position: absolute;

    width: 1px;
    height: 30px;
    background-color: var(--piter);
    top: 0;
    transform: translateY(-100%);
    left: 0;
    right: 0;
    margin: auto;
}
.custom-color-palette__button{
    display: inline-block;
    position: relative;

    padding: 4px 10px;
    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    background-color: white;
    margin-right: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

.custom-color-palette--active .custom-color-palette__button{
    border-color: var(--ocean);
}
.custom-color-palette--active .custom-color-palette__button_box::after{
    background-color: var(--ocean);
}
.custom-color-palette--active .custom-color-palette__card{
    border-color: var(--ocean);
}
.custom-color-palette--active .custom-color-palette__button{
    color: var(--ocean);
}
.custom-color-palette__inputs_label{
    display: inline-block;
    width: 170px;
    margin-left: 7px;

    font-size: 14px;

    transition: opacity 0.4s ease;
}
.settings-box__input .custom-color-palette__input{
    width: 80px;
}
.custom-color-palette--disabled .custom-color-palette__inputs_label{
    opacity: 0.5;
}

.custom-color-palette__activator{
    margin-bottom: 20px;
}

.custom-color-palette--disabled .custom-color-palette__inputs_row{
    cursor: not-allowed;
}

.custom-color-palette__inputs_row{
    margin-bottom: 20px;
}
.custom-color-palette__inputs_row:last-child{
    margin-bottom: 0;
}

/*.custom-color-palette__inputs_box{*/
    /*width: 290px;*/
/*}*/


.custom-color-palette__wrapper{
    display: flex;
    justify-content: space-between;
}


.custom-color-palette__example_box{
    width: 180px;
    margin-left: 10px;
}
@media (max-width: 1100px){
	.custom-color-palette__example_box{
        display: none;
    }
}

.custom-color-palette__example{
    height: 160px;
}
.custom-color-palette__example_body{
    /*background-color: red;*/
    border: 1px solid var(--piter);
    position: relative !important; /*because page-component__bg_overlay_box is pos:absolute*/
    height: 100%;

    display: flex;
    align-items: center;

    transition: background-color 0.2s ease;
}
.custom-color-palette__example_info{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    transform: translateY(-50%);
    text-align: center;
}
.custom-color-palette__example_info_text{
    display: inline-block;
    text-transform: uppercase;
    border-radius: 99px;
    border: 1px solid var(--piter);
    background-color: white;
    font-size: 10px;
    padding: 3px 8px;
}
.custom-color-palette__example_center{
    height: 80px;
    width: 100%;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-color-palette__example_buttons{
    display: flex;
    justify-content: center;
    align-items: center;

    transform: scale(0.6);
    transform-origin: center;
}
.custom-color-palette__example_button{
    margin-left: 9px;
    margin-right: 9px;

    /*height: 24px;*/
    /*width: 70px;*/

    /*border-radius: 3px;*/


}
.custom-color-palette__example_button--bg{

}
.custom-color-palette__example_button--alt-outline{

}

.captcha-drawer>* {
  margin-bottom: 30px;
}
.captcha-drawer>*:last-child {
  margin-bottom: 0;
}
.captcha-drawer__section:last-child {
    margin-bottom: 0;
}
.captcha-drawer__input {
    width: 100%;
}
.captcha-drawer__group .captcha-drawer__section {
    margin-bottom: 0;
}
.captcha-drawer__alert p:last-child {
    margin-bottom: 0;
}

.illustrated_spinner_box{
  padding: 50px 20px;
  text-align: center;
  height: 100vh;
  position: relative;
}
.illustrated_spinner_box__content{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
.illustrated_spinner_box__text{
  margin-top: 10px;
}
.illustrated_spinner_box__image{
  max-width: 120px;
  animation: levitate 5s ease-out infinite;
}

@keyframes levitate{
  0%{
    transform: translateY(0);
  }
  70%{
    transform: translateY(20%);
  }
  100%{
    transform: translateY(0);
  }
}
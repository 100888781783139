

.website_widgets__about {
    padding-left: 20px;
}

.website_widgets__info {
    margin-top: 10px;
}

.website_widgets__preview {
    height: 100%;
}

.website_widgets__preview_box {
    position: relative;
    height: 250px;
    background-position: center;
    background-size: cover;

    box-shadow: var(--card-shadow);
    border-radius: 10px;
    overflow: hidden;
}

.website_widgets__preview_link {
    text-align: center;
    margin-top: 20px;
}

.website_widgets__preview_box .simple-cookie-widget {
    position: absolute;
    transform: scale(0.9);
}

.website_widgets__preview_box .lang-widget {
    position: absolute;
}

.website_widgets__redo_button {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 999;
    bottom: 0;
    margin: auto;
    transform: translateX(-50%);
}

.website_widgets__title {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-dropdown-items{
    display: inline;
}
.nav-dropdown-items__nav_divider{
    margin-left: 10px;
    margin-right: 10px;

    color: white;
    opacity: 0.4;
}

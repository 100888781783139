.cancellation-modal .ant-modal-body {
  padding: 0;
  line-height: 0;
}
.cancellation-modal__form {
  position: relative;
  z-index: 1;
}
.cancellation-modal .spinner_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.5;
}

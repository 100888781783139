.my-button{
    /*we need to keep the shape of the box even when the button is hidden*/
    height: 32px;
    width: 32px;
    position: relative;
}
.my-button_tick{
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    text-align: center;

    margin: auto;

    z-index: 100;

    font-size: 30px;

    animation: move_up_and_disappear 1s ease-out forwards;
}

@keyframes move_up_and_disappear {
    0%{

    }
    50%{
        opacity: 1;
    }
    90%{
        transform: translateY(-30px);
    }
    100%{
        opacity: 0;
    }
}
.account-settings{
}
.account-settings__menu_box{
    text-align: center;
}
.account-settings__wrapper{
    padding: 0px 20px 60px;
    background-color: white;
    border-radius: 10px;
    box-shadow: var(--card-shadow);
}

.account-settings__item{
    margin-bottom: 60px;
}
.account-settings__user_email{
    font-weight: 400;
    border-radius: 999px;
    background-color: var(--main-bg-gray);
    padding: 5px 15px;
    font-size: 14px;
}
.account-settings__header{
  margin-bottom: 40px;
}
.plain-html__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.plain-html__saving-status {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #505050;
    margin-top: 15px;
    margin-bottom: 15px;
}
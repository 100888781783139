.permissions {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-bg-gray);
}
.permissions__info .ant-empty-image {
  height: 50px;
}
.permissions__buttons > * {
  margin-right: 10px;
}
.permissions__buttons > *:last-child {
  margin-right: 0;
}
.permissions__title {
  font-size: 2em;
  display: block;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  font-weight: 700;
  line-height: 1.3;
  color: #303030;
}
.permissions__text {
  display: block;
  max-width: 550px;
}
.permissions__paragraph {
  margin-bottom: 1em;
  display: block;
}
.permissions__paragraph:last-child {
  margin-bottom: 0;
}

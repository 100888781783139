.plan {
  width: 150px;

  text-align: center;
}
.plan__card {
  position: relative;
}
.plan__card_users{
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;
}
.plan__card_users_avatars{
  vertical-align: top;
  transform: translateY(-50%);
  text-align: center;
}
.plan__card_users_avatar{
  border: 2px solid var(--ant);
  background-color: var(--ant);
  border-radius: 50%;
  position: relative;
}
.plan__card_users_avatar:last-child{
  right: 5px;
}
.plan__card_users_avatar:first-child{
  left: 5px;
}
.plan__card_users_avatar--mid{
  z-index: 10;
}
.plan__card--focus .plan__top{
background-color: var(--ant);
border-radius: 10px 10px 0 0;
}
.plan__card--focus .plan__top .plan__card_info,
.plan__card--focus .plan__top .plan__price,
.plan__card--focus .plan__top .plan__title{
  color: white;
}
.plan__top {
  height: 173px;
  border-bottom: 1px solid var(--cloud);
}
.plan__price_wrapper {
}
.plan__title {
  font-weight: 500;
  margin-top: 24px;
  display: inline-block;
}
.plan__price_box {
  position: relative;
}
.plan__description{
  position: relative;
}
.plan__period{
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 105px;
  display: none;
}
.plan__feature{
  padding: 16px;
  border-bottom: 1px solid var(--cloud);
}
.plan__feature_button{
    border: none;
    height: auto;
    box-shadow: none;
}
.plan__feature:last-child{
  border: none;
}
.plan__card--focus .plan__feature{
  border-left: 1px solid var(--ant);
  border-right: 1px solid var(--ant);
}
.plan__card--focus .plan__feature:last-child{
  position: relative;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 1px var(--ant);
}
.plan__feature:first-child{
  font-weight: 700;
}
.plan:hover .plan__period{
  display: block;
}
.plan:hover .plan__description_info{
  visibility: hidden;
}
.plan__card_info{
  color: var(--concrete);
  font-size: 12px;
}
.plan__price {
  position: relative;
  font-size: 32px;
  color: var(--darkness);
  font-weight: 700;
}
.plan__price:not(.plan__price--no-info)::before,
.plan__price:not(.plan__price--no-info)::after {
  position: absolute;
  font-size: 12px;
  font-weight: 400;
}
.plan__price:not(.plan__price--no-info)::before{
  content: '$';
  transform: translateX(-10px) translateY(10px);
}
.plan__price:not(.plan__price--no-info)::after {
  content: "/mo";
  transform: translateX(5px) translateY(20px);
}
.plan__features_list {
}
.plan__feature {
}
.plan__feature:nth-child(3),
.plan__feature:nth-child(4),
.plan__feature:nth-child(7) {
}
.plan__feature:last-child {
}
.plan__current {
}

.plan__cta_box {
  margin-top: 7px;
}
.plan__loader {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 300px;
}
.plan__feature_icon {
}


.plan__card_users_faces_box{
  display: flex;
  justify-content: flex-start;
  padding: 5px 0 15px;
  border-bottom: 1px solid #E5E5E5;
}
.plan__card_users_face{
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
}
.plan__card_users_face--1{
  
}
.plan__card_users_face--2{
  transform: translateX(-5px);
}
.plan__card_users_face--3{
  transform: translateX(-10px);
}
.plan__card_users_face--4{
  transform: translateX(-15px);
}
.plan__card_users_face--5{
  transform: translateX(-20px);
}


.plan__card_users_faces_plus{
  background-color: #303030;
  color: white;
  padding: 5px 14px 5px;
  border-radius: 9999px;
  border: 2px solid white;
  margin-right: -25px;
  transform: translateX(-25px);
}
.plan__card_users_info{
  padding: 15px 0 0;
}
.plan__button-group {
  display: flex;
}
.plan__notification-button {
  margin-right: 12px;
}
.plan__notification-button:last-child {
  margin-right: 0;
}
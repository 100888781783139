.custom-fonts{
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 15px 20px;
    margin-bottom: 30px;

    width: 350px;
    min-height: 155px;

    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);

    transition: border-color 0.25s ease;
    z-index: 20; /*so custom fonts dropdown can overlap colors*/
}
.custom-fonts--active{
    border-color: var(--ocean);
}
.custom-fonts__button_wrapper{
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-fonts__divider{
    border-top: 1px solid var(--piter);
    border-bottom: none;
    width: 100%;
    margin: 15px 0;
}
.custom-fonts__items_box{
    height: 123px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-flow: column;
}
.custom-fonts__title_box{

}
.custom-fonts__title{
    font-size: 22px;
    margin-right: 15px;
    vertical-align: middle;
    font-weight: 700;
}
.custom-fonts__text_box{

}
.custom-fonts__text{
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
}
.custom-fonts__text_item{

}
.custom-fonts__button_wrapper{
    height: 123px;
}
.custom-fonts__picker{
    display: inline-block;
    vertical-align: middle;
}
.custom-fonts__save_button_box{
    text-align: center;
    margin-top: 10px;
}

.custom-sitemap-url-settings__item {
  display: flex;
  gap: 15px;
}
.custom-sitemap-url-settings__item:hover .custom-sitemap-url-settings__delete{
  opacity: 1;
}
.ant-btn.custom-sitemap-url-settings__delete{
  z-index: 10;
  position: absolute;
  right: -11px;
  top: 4px;
  transform: scale(0.7);

  opacity: 0;
  transition: all 0.2s;
}

.custom-sitemap-url-settings__item .ant-input{
  width: 100%;
}

.custom-sitemap-url-settings__loader {
  .ant-skeleton-paragraph {
    margin: 0;
  }
  .ant-skeleton-content li {
    height: 30px;
    border-radius: 4px;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 15px;
  }
}

.website-languages__items_box{
    /*width: 760px;*/
    display: flex;
    justify-content: space-between;
}
.website-languages__title{
    /*white-space: nowrap;*/
    display: block;
    /*margin-right: 20px;*/
    margin-bottom: 5px;
    color: var(--ant);
}
.website-languages__box{
    /*display: flex;*/
    align-items: flex-start;
    justify-content: space-between;
    width: 455px;
}
.website-languages__items_box{
  flex-direction: column;
  row-gap: 20px;
}
.website-languages__version{
    position: relative;
    display: flex;
    margin-bottom: 15px;
}

.website-languages__language_dropdown{
    margin-right: 5px;
}
.website-languages__current_website_input{

}
.website-languages__select_website{

}
.website-languages__version_form{
    position: relative;
}
.website-languages__version:hover .website-languages__delete{
    opacity: 1;
}
.website-languages__delete{
    z-index: 10;
    position: relative;
    right: 11px;
    top: 5px;
    transform: scale(0.7);

    opacity: 0;
    transition: 0.2s ease opacity;
}
.website-languages__age{
    margin-top: 3px;

    font-size: 10px;
    opacity: 0;
    transition: 0.2s ease opacity;
}
.website-languages__version_form:hover .website-languages__age{
    opacity: 0.6;
}

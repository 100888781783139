.other-02-parent .page-component__wrapper {
  z-index: initial !important;
}
.editor .senja-embed > .fixed {
  z-index: 950 !important;
}

.other-02 {
  .other-02__widget-container .sj-powered-by {
    justify-content: center;
  }

  .sj-avatars {
    justify-content: center;
  }
  .sj-card-carousel {
    padding: 0 16px;
  }
  .sj-bubble-card-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 16px;
  }
  .sj-card-wall {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 16px;
  }
  .sj-lindoy {
    padding: 0 16px;
    max-width: 1080px;
    margin: 0 auto;
  }
  .sj-avatars-pro > .flex-col {
    overflow: auto;
    padding: 12px 8px;
    & > .flex-col {
      align-items: center;
      & > .flex {
        width: auto;
      }
    }
  }
  .sj-bricks + .mt-4.flex.justify-end {
    justify-content: center;
  }
  .sj-slab-carousel {
    max-width: 800px;
    margin: 0 auto;
  }
  .sj-simple-badge {
    justify-content: center;
  }
}

.edit-multi-user-access__form_box{
  display: flex;
  width: 310px; /*because .settings-box__input .ant-input has width 310px*/
}
.edit-multi-user-access__form{
  display: flex;
}

.edit-multi-user-access__users{
  width: 310px; /*because .settings-box__input .ant-input has width 310px*/
  margin-top: 25px;
}

.plans__box{
  overflow: auto;
}
.plans__bonus{
    margin-left: 15px;
    cursor: default;
}
.plans__switch{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.plans__payment{
    margin-top: 40px;
    margin-bottom: 40px;

    text-align: center;
}
.plans__corporate_link{
    display: inline-block;

    margin-left: 30px;
}

.plans__corporate{
    text-align: center;
}
.plans__corporate__box{
    padding: 15px 20px;

    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid var(--cloud);
    border-radius: var(--main-border-radius);
}
.plans__corporate__text{
    /*margin-right: 70px;*/

    max-width: 500px;
    text-align: left;
}
.plans__wrapper{
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--cloud);

  margin-top: 30px;

  min-width: 1230px;
}
.plans__features{
  width: 232px;
  margin-top: 172px; /*the amount of the pricing tier top box (with a price)*/
}
.plans__feature_item{
  border-top: 1px solid var(--cloud);
  padding: 16px 0 16px 16px;
}
.plans__feature_item_icon{
  margin-left: 5px;
}
.plans__feature_item_tag {
  position: absolute;
  margin-left: 10px;
  cursor: default;
}


.plans__banner_box{
    position: relative;

    width: 520px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    background-color: #2F22DC;
    border-radius: 10px;
    padding: 20px;
    border: 6px solid #ffcc4d;
}
.plans__banner_emoji{
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    width: 30px;
    margin: auto;

    box-shadow: 0 5px 12px 2px rgba(0,0,0,0.3);
    border-radius: 50%;
}
.plans__banner_title{
    display: inline-block;

    margin-bottom: 10px;

    font-size: 18px;
}
.plans__banner_text{
    max-width: 270px;
    text-align: left;
    color: #fff;
}
.plans__banner_bar{
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.plans__banner_bar .ant-progress-circle {
    box-shadow: 0 5px 12px 2px rgba(0,0,0,0.2);
    border-radius: 50%;
}
.plans__banner_bar .ant-progress-circle .ant-progress-text{
    color: #fff;
    font-size: 16px;
}
.plans__banner_text p:last-child{
    margin-bottom: 0;
}
.plans__top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.plans__feature_item:nth-child(even),
.plan__feature:nth-child(even){
  background-color: #f9f9f9;
}
.plans__feature_item:last-child,
.plan__feature:last-child{
  /*border-radius: 0 0 10px 10px;*/
}

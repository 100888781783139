$header-height: 0px;

.settings-drawer {
  .ant-drawer-body {
    padding: 0;
    height: calc(100vh - #{$header-height});
  }
  .ant-drawer-header {
    padding: 0;
    border: none;
    .ant-drawer-title {
      display: none;
    }
    .ant-drawer-close {
      font-size: 14px;
      top: 27px;
      right: 9px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      background: #fff;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
      border-radius: 999px;
    }
  }
  &.ant-drawer .ant-drawer-content-wrapper {
    box-shadow: 0px 0px 2.7px rgba(0, 0, 0, 0.073),
      0px 0px 8.9px rgba(0, 0, 0, 0.107), 0px 0px 40px rgba(0, 0, 0, 0.18);
    max-width: 1496px;
  }
  &.ant-drawer .ant-drawer-mask {
    cursor: default !important;
  }
}

.settings-drawer__sidebar {
  padding: 8px 0;
  border-right: 1px solid #e8e8e8;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  min-width: 174px;
  width: 174px;
  overflow: auto;
  height: calc(100vh - #{$header-height});
  overscroll-behavior: contain;
  .ant-typography {
    padding: 5px 12px;
    display: inline-block;
  }
  .ant-divider {
    margin: 4px 0;
    min-height: 1px;
    top: 0;
  }
}

.settings-drawer__content {
  display: flex;
}

.settings-drawer__sidebar-button {
  padding: 6px 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  line-height: 22px;
  width: 100%;
  text-align: left;
  &:hover {
    background: #e8e8e8;
  }
  &.is-active {
    background: #e8e8e8;
    font-weight: 600;
  }
  transition: background-color 0.2s;
}

.settings-drawer__menu {
  overflow: auto;
  height: calc(100vh - #{$header-height});
  flex-grow: 1;
  padding: 32px 20px 80px;
  overscroll-behavior: contain;
}

.display_bad_website_overlay{
  margin-top: 20px;
  margin-bottom: 80px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.display_bad_website_overlay__content{
  /* text-align: center; */
  /* max-width: 600px; */
}
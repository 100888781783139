.social-preview {
  box-shadow: var(--card-bordered-shadow);
  border-radius: 12px;
  overflow: hidden;
}

.social-preview__image-box {
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  width: 100%;
  height: 200px;

  &.is-loading:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(237, 237, 237, 0.7) 25%,
      rgba(255, 255, 255, 0.7) 35%,
      rgba(237, 237, 237, 0.7) 45%
    );
    background-size: 400% 100%;
    z-index: 1;
    animation: ant-skeleton-loading 1.7s ease infinite;
  }

  &.is-empty {
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover:before {
    opacity: 1;
  }
}

.social-preview__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.social-preview__text-box {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.social-preview__title {
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.social-preview__subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-preview__url {
  color: var(--text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.social-preview__delete-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0;
  transition: all 0.2s;
}
.social-preview__image-box:hover
  .social-preview__delete-button {
  opacity: 1;
}

.slate-editor__post-body {
  //position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  line-height: 1.7;
  margin-top: 10px;
  padding-bottom: 130px;
  min-height: calc(100vh - 200px); //200px is approx. height of the nav bar, ver paddings and the title box with 1 line of content

  >*:not(figure):not(iframe) {
    width: 100%;
    max-width: 680px;
  }
}

.slate-editor__toolbar-item {
  display: flex;
  align-items: center;
  margin: 3px;
}

.slate-editor__toolbar-item-btn {
  width: 50px;
  padding: 4px;
  font-size: 16px;
}

.slate-editor__toolbar-item-label {
  margin-left: 10px;
  display: none;
}

.slate-editor__invisible_drag_ang_drop {
  display: none;
}

.uploadcare--dragging .slate-editor__invisible_drag_ang_drop {
  //works OK but when d'n'd existing image it triggers .uploadcare--dragging and show the unneeded overlay
  //display: block;
}

.slate-editor__saving-status {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #505050;
  margin-top: 15px;
  margin-bottom: 15px;
}

.slate-editor__editor-toolbar {
  position: sticky;
  bottom: 20px;
  padding: 5px 5px 10px;
  display: flex;
  justify-content: center;
  //background-color: #fff;
  z-index: 1;

  opacity: 0.3;
  transform: scale(0.98) translateY(3px);

  transition: opacity .4s ease, transform .4s ease;
}

.blog-post-editor__wrapper:hover .slate-editor__editor-toolbar {
  opacity: 1;
  transform: scale(1);
}

.slate-editor__format-buttons {
  display: flex;
  justify-content: center;
  margin-right: 40px;
}

.slate-editor .uploadcare--widget {
  position: static;
}

.slate-editor .uploadcare--widget__text,
.slate-editor .uploadcare--widget__button_type_open {
  display: none;
}

.slate-editor .uploadcare--widget__dragndrop-area {
  position: fixed;
  //height: calc(100% - 260px);
  opacity: 0.9;
  font-size: 80px;
  max-width: 720px;
  right: 0;
  left: 0;
  width: 100%;
  min-width: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  min-height: auto;
  animation: blinking_frame 1s linear infinite;
  top: 80px;
  bottom: 50px;
  z-index: 50;
}

//.uploadcare--dragging .slate-editor__post-body:before{
//  animation: blinking_frame 1s linear infinite;
//  display: block;
//}


// === START OF: Post Body Typography
// manual sync with visitor styles in https://bitbucket.org/unicorn_breeders/templates/src


@keyframes blinking_frame {
  0% {
    color: #a4a2a1;
  }

  50% {
    color: #808080;
  }

  100% {
    color: #a4a2a1;
  }
}
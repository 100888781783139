.template-select {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.template-select__tags {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 10px;
}

.template-select__tags_item {
  list-style: none;
  margin-right: 10px;
  position: relative;
}
.template-select__tags_item:last-child{
  margin-right: 0;
}

.template-select__tags_item_button {
  display: flex;
  align-items: center;
  border: none;
  box-shadow: none;
}
.template-select__tags_item_button:hover{
  background-color: #eff4fd;
}
.ant-btn.template-select__tags_item_button--active{
  background-color: #E9F1FF;
  /* background-color: #DFEAFE; */
  color: var(--ant);
}

.template-select__tags_item_count {
  margin-left: 2px;
  font-size: 0.8em;
  opacity: 0.6;
  position: relative;
  bottom: 3px;
}

.template-select__list {
  /* display: flex;
  align-items: center;
  flex-wrap: wrap; */
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.template-select__item {
  display: inline-block;
  position: relative;
  margin-bottom: 60px;
  margin-left: 20px;
  margin-right: 20px;
  list-style: none;
  z-index: 1;
  max-width: 380px;
  width: 33%;
}

.template-select__item_header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.template-select__item_title {
  text-align: center;
}

.template-select__item_card {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 0;
  overflow: hidden;
  height: 200px;
  border-radius: 10px;
  text-decoration: none;
  border: 1px solid var(--gray);
}
.template-select__item_card--hover-effect{
  transition: 1s ease-in-out background-position-y, 0.2s ease box-shadow, 0.2s ease border-color, 0.2s ease transform;
}
.template-select__item_card--hover-effect:hover{
  box-shadow: var(--card-shadow);
  transform: translateY(-4px);
  background-position-y: 100%;
  transition: 5s ease-in-out background-position-y, 0.2s ease box-shadow, 0.2s ease border-color, 0.2s ease transform;
  border-color: transparent;
}

.template-select__item_card-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  /*transition: .15s;*/
  /*opacity: 0;*/
}
/*.template-select__item_card:hover .template-select__item_card-controls{*/
/*  opacity: 1;*/
/*}*/

.template-select__item_card_info {
  padding: 10px 8px 8px 8px;
}

.template-select__open-preview {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 15px;
  margin-right: 10px;
  opacity: 0;
}

.template-select__blank_icon{
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

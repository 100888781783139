body{
    --main-bg-gray: #F0F2F5;
    --concrete: #7C7C7C;
    --ocean: #40a9ff;
    --cloud: #E9E9E9;
    --blood: #D0021B;
    --orange: #FAAD14;
    --grass: #4ADDB4;
    --darkness: #303030;

    --gray: #E8E8E8;
    --piter: #D9D9D9;
    --night: #334453;

    --ant: #1890FF;
    --ant-error-color: #ff4d4f;
    --ant-error-color-hover: #ff7875;

    --main-border-radius: 4px;

    --card-shadow: 0 2px 8px 0 rgba(0,0,0,0.09);
    --card-shadow-small: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --card-bordered-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px #e8e8e8;

    --ai: #722ed1;

    --ant-success: #52c41a;

    --text-secondary: rgba(0,0,0,0.45);

    --border-radius: 6px;
}

.container{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
body .white-border{
    border: 1px solid white;
}
#confetti-holder{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    max-width: 100%;
    margin: auto;

    transition: opacity 1s linear;
}

/*Ant.design overrides*/
body .ant-input-group-addon{
    color: rgba(0, 0, 0, 0.4);
    cursor: default;
}

body .ant-progress-circle-trail{
    stroke: rgb(234, 236, 239) !important;
}

.ant-modal-mask{
    cursor: pointer;
}

.settings, .websites, .website-dashboard, .account-settings{
    padding-top: 30px;
    padding-bottom: 60px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: var(--main-bg-gray);
}
@media (max-width: 768px) {
  .settings, .websites, .website-dashboard, .account-settings { 
    padding-left: 24px;
    padding-right: 24px;
   }
}
@media (max-width: 500px) {
  .settings, .websites, .website-dashboard, .account-settings { 
    padding-left: 0;
    padding-right: 0;
   }
}
.websites, .website-pages{
    background-color: var(--main-bg-gray);
}
.settings, .account-settings{
    padding-top: 40px;
}

body #chatra.chatra--pos-right{
    right: 50px;
}

.checkered-bg{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.checkered-bg:before{
    content: "";

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    margin: auto;
    z-index: 1 ;

    /*background-image: linear-gradient(45deg, #F3F3F3 25%, transparent 25%), linear-gradient(-45deg, #F3F3F3 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #F3F3F3 75%), linear-gradient(-45deg, transparent 75%, #F3F3F3 75%);*/
    background-image: linear-gradient(45deg, #D6D6D6 25%, transparent 25%), linear-gradient(-45deg, #D6D6D6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #D6D6D6 75%), linear-gradient(-45deg, transparent 75%, #D6D6D6 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    background-color: white;

    opacity: 0.3;
    transition: opacity 0.5s ease;
}
.checkered-bg.has-opacity:before {
  opacity: 0.2;
}
.checkered-bg:not(.checkered-bg--no-hover):hover:before{
    opacity: 1;
}
.checkered-bg img{
    position: relative;
    z-index: 3; /*because we have a :before pseudo element inside the .checkered-bg */
}

/* Because the react-js version of Slick slider doesn't allow to place custom arrows in the container I need.*/
.slider-06__slider{
    padding-bottom: 0;
}
.slider-06__slider .slick-slider{
    padding-bottom: 90px;
}

.uploadcare--widget__button,
.uploadcare--panel__content .uploadcare--tab__action-button,
.uploadcare--widget__button:focus{
    /*all: unset;*/
    color: #fff;
    background-color: var(--ant);
    border-color: var(--ant);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    height: 32px;
    /*font-weight: 400;*/
    padding: 0 15px;
    /*font-size: 16px;*/
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font: inherit;
}
.uploadcare--widget__button:hover,
.uploadcare--panel__content .uploadcare--tab__action-button:hover{
    /*font-size: 16px;*/
    cursor: pointer;
    background-color: #40a9ff;
    border-color: #40a9ff;
    font: inherit;
}
.uploadcare--powered-by{
    opacity: 0.3;
    transition: opacity 0.25s ease, transform 0.25s ease;
}
.uploadcare--powered-by:hover{
    opacity: 1;
}
.uploadcare--input{
    height: 30px;
    padding: 0 15px;
    border-radius: 4px;
    font-size: 16px;
}
.uploadcare--menu__item_tab_file{
    margin-bottom: 50px;
}
.gist__title{
    display: none;
    /*import ReactEmbedGist from 'react-embed-gist'; adds additional markup such as this title*/
}
.loading__screen{
    text-align: center;
}
html div[id^=font-picker] .dropdown-button{
    background: #ffffff;
}
html div[id^=font-picker] .dropdown-button:hover,
html div[id^=font-picker] .dropdown-button:focus {
    background: #ffffff;
}
html div[id^=font-picker] ul{
    background: #ffffff;
}
html div[id^=font-picker] ul li button.active-font {
    background: #303030;
    color: white;
}
div[id^=font-picker] .dropdown-icon.finished::before{
    opacity: 0.4;
}
html div[id^=font-picker]{
    width: 220px;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid var(--piter);
}
.underlined_link,
.ant-typography .underlined_link{
    text-decoration: underline;
}
.click-to-select{
    user-select: all;
    color: var(--night);

    padding: 2px 10px;
    border-radius: 9999px;
    font-weight: 400;
    font-family: monospace;
    background-color: var(--main-bg-gray);
}

.editor__wrapper .nav-02{
    z-index: 130; /*nav has zi 1300 in templates to overp the first component which has a generated zi which can be over 1000 when there are more than 10 components. The zi are generated to be one bigger that another to allow diaforms overlap each other.*/
}

.uploadcare--widget_status_error .uploadcare--widget__text{
    color: var(--blood);
}

.uploadcare--page .ant-notification{
    z-index: 2147483648;
}

html .ant-notification {
  z-index: 1300;
}

/*.ghost_tabs applied to <Tabs> makes it look like text*/
/* .ghost_tabs .ant-tabs-bar{
    border: none;
    margin: 0;
    margin-bottom: 30px;
}
.ghost_tabs .ant-tabs-tab{
    padding: 0 !important;
}
.ghost_tabs .ant-tabs-ink-bar{
    display: none !important;
}
.ghost_tabs .tab-title{
    font-weight: 300;
} */

.ant-drawer.ant-drawer-open .ant-drawer-mask{
  opacity: 0 !important;
  background-color: transparent !important;
  cursor: default !important;
}
.ant-drawer-wrapper-body {
  overscroll-behavior: contain;
}
@media (max-width: 1496px) {
  .ant-drawer .ant-drawer-content-wrapper {
    max-width: calc(100vw - 16px) !important;
  }
}
.page-templates .ant-modal-content,
.create-new-website-modal .ant-modal-content {
  min-width: 768px;
}
.not_closable_tag{
    cursor: default !important;
}
.horizontal-form {
  display: flex;
}
.horizontal-form .horizontal-form__input { 
  /* Nesting to override default antd margins */
  margin-right: 15px;
}
.paragraph-custom:last-child {
  margin-bottom: 0;
}

/* Primary danger buttons aren't available in our version of antd, so we added them manually */
.ant-btn.ant-btn--danger-custom:not(.ant-btn[disabled]) {
  border-color: var(--ant-error-color);
  background: var(--ant-error-color);
}
.ant-btn.ant-btn--danger-custom:not(.ant-btn[disabled]):hover,
.ant-btn.ant-btn--danger-custom:not(.ant-btn[disabled]):focus {
  border-color: var(--ant-error-color-hover);
  background: var(--ant-error-color-hover);
}

.ant-spin-container.ant-spin-blur {
  overflow: visible;
}
.ant-spin-blur.ant-spin-container:after {
  z-index: 200;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  max-height: 182px;
  overflow: auto;
}

.ant-popover.no-arrow {
  padding-top: 0;
}
.ant-popover.no-arrow .ant-popover-arrow {
  display: none;
}
.ant-popover.padding-12 .ant-popover-inner-content {
  padding: 12px;
}
body.state-fixed-body_popup {
	overflow: hidden;
}

*.is-display-none {
  display: none;
}

.ant-btn.is-black-button {
  background-color: #001529;
  border-color: #001529;
}
.ant-btn.is-black-button:hover,
.ant-btn.is-black-button:focus {
  background-color: #3b4e5b;
  border-color: #3b4e5b;
}

.editor .nav-02.nav-02--hidden {
  opacity: 0.5;
  background-color: #fff;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
  linear-gradient(-45deg, #ccc 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, #ccc 75%),
  linear-gradient(-45deg, transparent 75%, #ccc 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.comps .form__input__label_box {
  padding: 0;
}

.crisp-client .cc-1brb6 {
  z-index: 210000000 !important;
}

.paddle-frame {
  z-index: 200000000 !important;
}

.cms-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &.is-no-inputs {
    gap: 12px;
  }
  .ant-select {
    width: 270px;
  }
  .ant-input {
    width: 100%;
  }
  &__dropdown-render {
    .ant-select-dropdown-menu {
      max-height: 180px;
    }
    .nav-dropdown__cta-container {
      position: static;
    }
  }
  .ant-select-selection__clear {
    color: rgba(0, 0, 0, 0.5);
    transform: scale(1.25);
    &:hover {
      color: rgba(0, 0, 0, 0.8) !important;
    }
  }
}

.color-palette-item{
    display: inline-block;

    vertical-align: top;

    margin-bottom: 60px;
    margin-right: 30px;
    width: 160px;

    text-align: center;

    cursor: pointer;
}
.color-palette-item:hover .color-palette-item__card{
    transform: translateY(-5px);
}
.color-palette-item__card{
    position: relative;
    overflow: hidden;
    z-index: 10;

    padding: 25px 15px 20px;
    margin-bottom: 20px;
    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    background-color: white;

    transition: box-shadow 0.25s ease, transform 0.25s ease;
}

.color-palette-item__card_overlay{
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    margin: auto;


    font-size: 14px;

    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;

    transition: opacity 0.4s ease;
}
.color-palette-item__card_overlay--visible,
.color-palette-item__card_overlay--spinner-visible{
    opacity: 1;
}

.color-palette-item__spinner{
    opacity: 0;
}
.color-palette-item__card_overlay--spinner-visible .color-palette-item__spinner{
    opacity: 1;
}

.color-palette-item:hover .color-palette-item__card{
    box-shadow: var(--card-shadow);
}
.color-palette-item__card__example_piece{
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.color-palette-item__card__example_piece .isvg.loaded{
    /*.isvg.loaded - classes of the SVG module which loads svg from file to inline */
    margin-right: 0;
    margin-left: 10px;
}

.color-palette-item__card__example_piece:last-child{
    margin-bottom: 0;
}
.color-palette-item__button_box{
    position: relative;
}
.color-palette-item__button_box::after{
    content: "";
    position: absolute;

    width: 1px;
    height: 30px;
    background-color: var(--piter);
    top: 0;
    transform: translateY(-100%);
    left: 0;
    right: 0;
    margin: auto;
}
.color-palette-item__button{
    display: inline-block;
    position: relative;

    padding: 4px 10px;
    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    background-color: white;
    margin-right: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

.color-palette-item--active .color-palette-item__button{
    border-color: var(--ocean);
}
.color-palette-item--active .color-palette-item__button_box::after{
    background-color: var(--ocean);
}
.color-palette-item--active .color-palette-item__card{
    border-color: var(--ocean);
}
.color-palette-item--active .color-palette-item__button{
    color: var(--ocean);
}

.form_input_picker {
}

.form_input_picker__required_button {
  padding: 0;
  width: 30px;
  line-height: 2;
  margin-left: 8px;
  margin-right: 8px;
}
.form_input_picker__field {
  display: flex;
  justify-content: center;
}

.form_input_picker__type_title {
  margin-left: 5px;
}
.form_input_picker__field_box {
  position: relative;

  margin-bottom: 10px;

  transition: 0.2s ease padding, 0.2s ease shadow;
  border-radius: 4px;
}
.form_input_picker__field_box--opened {
  box-shadow: 0px 5px 11px rgb(0 0 0 / 8%);
  padding: 15px 15px 5px;
}
.form_input_picker__field_box--opened .form_input_picker__field_controls{
  display: none;
}
.form_input_picker__field_box--opened .form_input_picker__field_box__primary_settings{
  display: block;
}
.form_input_picker__field_box__primary_settings {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
}
.form_input_picker__field_box__advanced_settings {
  margin-top: 20px;
}
.form_input_picker__field_box .content-input__control_cluster {
  /*position: absolute;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*top: 0;*/

  /*margin: auto;*/
}

.form_input_picker__field_box:hover .content-input__control_cluster,
.form_input_picker__field_box:hover .form_input_picker__field_delete {
  opacity: 1;
}
.form_input_picker__add_new_box {
  display: block;
}

.form_input_picker__field_delete {
  opacity: 0.1;

  transition: opacity 0.15s ease;
}
.form_input_picker__field_controls {
  width: 70px;
  padding-left: 10px;
  position: relative;

  display: flex;
  /*justify-content: space-between;*/
  align-items: center;

  /*position: absolute;*/
  /*right: 0;*/
  /*top: 0;*/
  /*bottom: 0;*/
}

.form_input_picker__field_controls .content-input__control_cluster {
  transform: scale(0.7);
  transition-delay: 0s;
}
.form_input_picker__field_controls
  .content-input__control_cluster--top
  + .content-input__control_cluster--bottom {
  transform: translateX(20px) scale(0.7);
}

.form_input_picker__setting_box{
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  line-height: 1;
}
.form_input_picker__setting_box .content-input__tooltip_box {
  padding-top: 4px
}

.fill-template-progress {
  padding: 8px 20px;
  background: #fff;
  box-shadow: 0px 0.987023651599884px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.05),
    0px 2.843898296356201px 5.32008171081543px 0px rgba(0, 0, 0, 0.08),
    0px 5.98975944519043px 10.017241477966309px 0px rgba(0, 0, 0, 0.09),
    0px 11.167402267456055px 17.869047164916992px 0px rgba(0, 0, 0, 0.11),
    0px 19.14726448059082px 33.422088623046875px 0px rgba(0, 0, 0, 0.14),
    0px 27px 80px 0px rgba(0, 0, 0, 0.19);

  border-radius: 999px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  width: 210px;
}

.fill-template-progress__indicator {
  height: 100%;
  width: 50%;
  left: 0;
  right: 0;
  position: absolute;
  background: #d3adf7;
  transition: width 0.5s;
}
.fill-template-progress__text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.fill-template-progress__text {
  font-weight: 500;
}
.fill-template-progress__indicator {
  background: linear-gradient(
      to right,
      #ffa39e 0%,
      #ffd591 10%,
      #fffb8f 20%,
      #b7eb8f 30%,
      #87e8de 40%,
      #91caff 50%,
      #adc6ff 60%,
      #d3adf7 70%,
      #ffadd2 80%,
      #ffbb96 90%,
      #ffa39e 100%
    )
    0 0/200% 100%;
  width: 210px;
  animation: a 4s linear infinite;
}

@keyframes a {
  to {
    background-position: -200% 0;
  }
}

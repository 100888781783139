.mockups_content_input{
    margin-top: 20px;
}

.mockups_content_input__menu_item{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 26px;
}
.mockups_content_input__device_box{
    width: 42px;
    text-align: center;
}
.mockups_content_input__device{
    height: 24px;
    margin-right: 10px;
    background-color: white;
    padding: 3px;
    border-radius: 2px;
}
.mockups_content_input__human_title{
    /*margin-right: 10px;*/
}
.mockups_content_input__active_info{

}
.mockups_content_input__resolution{
    margin-left: 10px;
    margin-right: 10px;
    opacity: 0.6;
    font-size: 12px;
}


.mockups_content_input__colors_box{
    display: flex;
    align-items: center;

    margin-left: 10px;
}
.mockups_content_input__color_item_box{
    margin-right: 6px;
}
.mockups_content_input__color_item_box:last-child{
    margin-right: 0;
}

.mockups_content_input__color_item_box--active .mockups_content_input__color_button,
.mockups_content_input__color_item_box--active .mockups_content_input__color_button:hover{
    cursor: default;
    transform: scale(0.7);
}
.mockups_content_input__color_button{
    cursor: pointer;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: var(--card-shadow);

    transition: transform 0.25s ease;
}
.mockups_content_input__color_button:hover{
    transform: scale(1.08);
}

.mockups_content_input__dropdown .ant-dropdown-menu-item{
    cursor: default;
}

.mockups_content_input__disclaimer{
    margin-top: 40px;
    opacity: 0.6;
    transition: opacity 0.2s ease;

    cursor: pointer;
}
.mockups_content_input__disclaimer:hover{
    opacity: 1;
}
/*.mockups_content_input__disclaimer .ant-popover-buttons button:first-child{*/
/*    display: none;*/
/*}*/
.mockups_content_input__dropdowns{
    display: inline-flex;
    flex-direction: column;
}
.mockups_content_input__dropdown_box{
    margin-top: 10px;
    background: #f3f3f3;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
}
.mockups_content_input__dropdown_box:first-child{
    margin-top: 0;
}

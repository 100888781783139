.favicon-updated__existing_icon{
    display: inline-block;

    /*border: 1px solid var(--piter);*/
    /*padding: 5px 20px;*/
    /**/
    /*width: 150px;*/
    /*padding-left: 10px;*/
    /*border-radius: 4px;*/
}

.favicon-updated__current_icon{
    max-width: 16px;
    max-height: 16px;
    vertical-align: middle;
}
.favicon-updated__delete_button{
    vertical-align: middle;

    margin-left: 10px;
}
.favicon-updated__deleting_text{
    vertical-align: middle;

    margin-left: 10px;

    opacity: 0.6;
    color: var(--ocean);
}
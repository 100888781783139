.create-new-website-modal .ant-modal-body {
  padding: 0;
}
.create-new-website-modal{
  width: calc(100vw - 40px) !important;
  max-width: 1600px;
  top: 20px;
}
.create-new-website-modal__form-body{
  height: calc(100vh - 120px);
  /* max-height: 1200px; */
  overflow: auto;
}
.create-new-website-modal__input_box{
}

.create-new-website-modal__button{
    /* shame centering*/
    position: relative;
    bottom: 1px;
}
.create-new-website-modal__errors{
    max-width: 400px;
    margin-top: 3px;
    padding-left: 10px;

    color: var(--blood);

    transition: opacity 0.3s ease;
}
.create-new-website-modal__input{
    margin-right: 20px;
}
.create-new-website-modal__label{
    display: block;

    margin-bottom: 10px;
}
.create-new-website-modal__form-footer {
    position: sticky;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    background-color: #fff;
    padding: 15px 24px;
    z-index: 1;
}

.create-new-website-modal__form-body {
  padding: 24px;
}
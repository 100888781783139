.application_menu__table {
  max-width: 800px;
}
.application_menu__logo {
  max-height: 24px;
  margin-right: 12px;
}
.application_menu__title--apps {
  display: flex;
  align-items: center;
}
.application_menu__confirm {
  max-width: 300px;
}
.applications_menu__empty_image {
  margin: 32px 0;
}
.applications_menu__empty_image .ant-empty-description{
  max-width: 320px;
  margin: 0 auto;
}

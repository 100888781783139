.version-info {
    padding: 5px 12px;
}

.version-info__text {
    display: block;
    opacity: 0.5;
    font-size: 12px;
}

.version-info__text_small {
    font-size: 10px;
}
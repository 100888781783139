.login-form__forgot{
    font-size: 12px;

    color: var(--concrete);
}
.login-form__bottom_button{
    /* not DRY :( */
    display: inline-block;
}
.grecaptcha-badge {
  bottom: 80px !important;
}

.settings__wrapper{
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: var(--card-shadow);
}
.settings__left{
    width: 250px;
    padding: 25px;

    border-right: 1px solid var(--cloud);
}
.settings__right{
    width: calc(100% - 250px);
}
.settings__container{
    transform: translateZ(0);
}
@media (max-width: 950px){
	.settings__left{
        display: none;
    }
    .settings__right{
        width: 100%;
    }
}

.settings__tab{
    /*text-align: center;*/
    font-weight: 500;

}
.settings__tab_hints{
    line-height: 1;
    margin-top: 3px;
    font-weight: 400;
}
.settings__small{
    font-size: 10px;
    opacity: 0.6;

    margin-right: 5px;
    margin-bottom: 5px;
}
.settings__small:last-child{
    margin-right: 0;
    margin-bottom: 0;
}
.settings__nav .ant-tabs-nav .ant-tabs-tab{
    vertical-align: top;
}
.settings__divider {
  margin: 22px 0 20px;
}

.style-menu__preview {
  .text-input,
  .button,
  .button * {
    transition: all 0.3s ease, color 0.2s ease, background-color 0.2s ease,
      border-color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease !important;
  }
}
.style-menu__text-preview {
  h1,
  h2,
  h3,
  p {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    white-space: nowrap !important;
    transition: all 0.3s ease !important;
  }
  .heading-example-h3 {
    font-size: 24px;
  }
}
.example-card {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(136,144,195,0.2);
  background: #fff;
  padding: 20px 20px 25px 25px;
  max-width: 100%;
  overflow: hidden;
  .title-text--inner {
    font-size: 20px;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    white-space: nowrap !important;
  }
  .content-text {
    margin: 0 !important;
    white-space: nowrap !important;
  }
}

.vert-space-example {
  padding-top: var(--extra-vertical-space) !important;
  padding-bottom: var(--extra-vertical-space) !important;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  max-width: 150px;
  justify-content: center;
}

.space-example-card {
  width: 50px;
  height: 60px;
  box-shadow: 0 5px 15px 0 rgba(37,44,97,0.15);
  background: #fff;
  border-radius: 10px;
}

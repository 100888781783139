.cancel-discount-modal .ant-modal-header {
  padding: 20px 24px;
  padding-top: 24px;
}
.cancel-discount-modal .ant-modal-close {
  top: 7px;
}
.cancel-discount-modal .ant-modal-body {
  padding-top: 18px;
}

.codex-editor{
    //padding-bottom: 130px;
    min-height: 100vh;
    background-color: white;
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;

    .codex-editor__redactor {
        width: 900px;
        max-width: 100%;
        margin: 0 auto;
    }
    
    // Blocks - common
    .ce-block {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    
    .ce-block__content{
        width: 100%;
        max-width: 684px;
        padding-right: 2px;
        padding-left: 2px;
    }

    .ce-block--selected {
        .ce-block__content {
            background: rgba(45, 170, 219, 0.3);
        }
    }

    .cdx-list__item {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    // Toolbar
    .ce-toolbar__actions {
        right: 100%;
        margin-right: 22px;
        top: 13px;
    }

    .ce-toolbar__plus{
        margin-left: -38px;
        top: 0;
    }

    .ce-settings {
        min-width: 120px;
    }
    .ce-settings__plugin-zone > div {
        display: flex;
        justify-content: space-around;
    }
    .ce-settings__plugin-zone:not(:empty) {
        padding: 3px;
    }
    .ce-settings__default-zone {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .ce-settings__plugin-zone:not(:empty) ~ .ce-settings__default-zone {
        border-top: 1px solid var(--gray);
    }

    // Block: CHECKBOX
    .cdx-checklist__item-checkbox {
        border-radius: 0;
        width: 18px;
        height: 18px;
        margin-top: 10px;
        border: 2px solid #37352f;
        background-color: #fff;

        &:after {
            top: 2px;
            left: 1px;
            width: 12px;
            height: 6px;
        }

        &:hover {
            border: 2px solid #37352f;
            background-color: #ddd;
        }
        
    }

    .cdx-list {
        list-style-position: inside;
        padding-left: 0;
    }

    .cdx-list__item::marker {
        text-align: end;
    }
    
    .cdx-checklist__item {
        &.cdx-checklist__item--checked {

            .cdx-checklist__item-checkbox {
                background-color: #2eaadc;
                border-color: #2eaadc;

                &:hover {
                    border: 2px solid #2a9ecc;
                    background-color: #2a9ecc;  
                }
            }
        }
    }

    .cdx-settings-button[data-tune="withBorder"] {
        display: none;
    }

    // Block: TABLE
    .tc-table {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.codex-editor__editor-toolbar {
    position: sticky;
    bottom: 20px;
    height: 0;
    display: flex;
    justify-content: center;
    //background-color: #fff;
    z-index: 1;
  
    opacity: 0.3;
    transform: scale(0.98) translateY(3px);
  
    transition: opacity .4s ease, transform .4s ease;
}

.blog-post-editor__wrapper:hover .codex-editor__editor-toolbar {
    opacity: 1;
    transform: scale(1);
}
body .cdx-notifies{
    left: auto;
    right: 20px;
}

.codex-editor__saving-status{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #505050;
}

@keyframes blinking_frame {
    0% {
      color: #a4a2a1;
    }
  
    50% {
      color: #808080;
    }
  
    100% {
      color: #a4a2a1;
    }
}
.cdx-block.image-tool .cdx-button:after {
    content: "Up to 1MB";
    margin-left: 5px;
    opacity: 0.5;
    font-size: 0.8em;
}
.codex-editor .ce-block{
    transition: background-color .2s ease;
}
.ce-block--focused{
    border-radius: 4px 4px;
    background-color: rgb(252, 252, 252);
    transition: none;
}

.codex-editor .ce-block__content,
.ce-block__content{
    max-width: 100%;
}
.codex-editor .codex-editor__redactor{
    width: 100%;
}


.cdx-block.image-tool{
    max-width: 1060px;
    margin: auto;
}
.cdx-block.image-tool--loading,
.cdx-block.image-tool--empty,
.image-tool__caption, .embed-tool__caption{
    max-width: 680px;
}
.image-tool__caption, .embed-tool__caption{
    margin-left: auto;
    margin-right: auto;
}
.cdx-input.image-tool__caption, .cdx-input.embed-tool__caption{
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 0.8em;
}
.cdx-block:not(.embed-tool):not(.image-tool),
body .ce-header{
	width: 100%;
	max-width: 680px;
    margin-left: auto;
    margin-right: auto;
}
.cdx-block.embed-tool{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 1060px;
}
body .codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
    opacity: 0.5;
}
.ce-code__textarea::placeholder {
    color: white;
    opacity: 0.3;
    transition: .2s ease opacity;
}
.ce-code__textarea:focus::placeholder {
    color: white;
    opacity: 0.7;
}
.js-codex-editor{
    .codex-editor__editor-toolbar{
        padding: 0;
        left: calc(50% - 8px);
        display: inline-block;
    }
    .codex-editor__saving-status{
        margin-top: 0;
        margin-bottom: 0;
        display: inline-block;
    }
}
.cdx-list__item{
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.codex-editor--toolbox-opened .ce-paragraph[data-placeholder]::before {
  opacity: 0 !important;
}

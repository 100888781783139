.background-color-picker{
    display: inline-block;

    padding: 10px 10px 6px 10px;
    white-space: nowrap; /* To avoid line-breaks on some devices */
  }
.page-component__box .background-color-picker{
  margin-top: 5px;
}  
.background-color-picker__item,
.background-color-picker__circle{
    transition: transform 0.25s ease;
}

.background-color-picker__item{
    position: relative;
    z-index: 10; /*we need z-index because the checkered background is a pseudo element*/

    margin-right: 20px;
    padding: 0;
    font-size: 0;

    cursor: pointer;
}
.background-color-picker__item:last-child{
    margin-right: 0;
}

.background-color-picker__item:hover{
    transform: scale(1.08);
}

.background-color-picker__circle{
    display: inline-block;
    height: 40px;
    width: 40px;

    border-radius: 9999px;

    box-shadow: 0 2px 12px 3px rgba(0,0,0,0.12);
}
.background-color-picker__item--border .background-color-picker__circle{
    border: 1px solid var(--cloud);
}

.background-color-picker__item--small .background-color-picker__circle{
    transform: scale(0.6);
}
.background-color-picker__info{
    margin-top: 40px;
    /*opacity: 0.7;*/
}
.background-color-picker__custom {
  background: radial-gradient(50% 50% at 50% 50%,#ffffff 0%,transparent 100%),
  conic-gradient(#f5222d, #fadb14, #52c41a, #1890ff, #722ed1, #eb2f96, #f5222d);
}
.background-color-picker__popconfirm .ant-popover-message-title {
  padding-left: 0;
}
.background-color-picker__popconfirm .ant-popover-inner-content {
  width: 200px;
}
.background-color-picker__tooltip-frame {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.nav-dropdown {
  display: inline-block;

  color: white;
}
.nav-dropdown__link,
.nav-dropdown__link:focus,
.nav-dropdown__link:hover {
  color: white;
  text-decoration: none;
}
.nav-dropdown__menu_item.ant-dropdown-menu-item {
  position: relative;
  padding: 0;
}
.nav-dropdown__menu_item {
  border-bottom: 1px solid #e8e8e8;
}
.nav-dropdown__menu_item:last-child {
  border-bottom: none;
}
.nav-dropdown__item_row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 5px 12px 5px 0px;
}
.ant-dropdown-menu-item a.nav-dropdown__item_link {
  margin: 0;
  padding: 4px 10px 4px 16px;
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
}
.ant-dropdown-menu-item > .nav-dropdown__item_view_box {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;

  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item a.nav-dropdown__item_view {
  margin-left: 5px;

  opacity: 0.3;
  transition: opacity 0.3s ease;
}
/*.ant-dropdown-menu-item a.nav-dropdown__item_view:last-child{*/
/*transition-delay: 0.2s;*/
/*}*/
.ant-dropdown-menu-item:hover a.nav-dropdown__item_view {
  opacity: 1;
}

.nav-dropdown__item {
  padding: 3px 6px 5px;
  transition: opacity 0.3s ease;
}
.nav-dropdown__item--transparent {
  opacity: 0.6;
}
.nav-dropdown__link {
  padding: 5px 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 100%;
  border-radius: var(--main-border-radius);
  transition: background-color 0.3s ease, opacity 0.3s ease;
}
.nav-dropdown__link:hover {
  background-color: var(--night);
}
.nav-dropdown__link--divider {
  position: relative;
}
.nav-dropdown__link--divider:after {
  content: "";

  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 999px;
  background-color: var(--night);
  height: 2px;
  width: 2px;
}

.nav-dropdown__more-button {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.ant-dropdown-menu-item:hover .nav-dropdown__more-button {
  visibility: visible;
}
.nav-dropdown__more-button:hover {
  background: #d9d9d9;
}
.nav-dropdown__navigation-item {
  padding: 3px 5px 3px 12px;
}
.nav-dropdown__create-button {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.88);
  transition: background-color 0.2s;
}
.nav-dropdown__create-button:not([disabled]) {
  cursor: pointer;
}
.nav-dropdown__create-button:not([disabled]):hover {
  background: #f0f0f0;
}
.nav-dropdown__search-box {
  padding: 0;
  cursor: default;
}
.nav-dropdown__search-box .ant-input {
  border: none;
}
.nav-dropdown__search-box .ant-input-affix-wrapper .ant-input {
  padding-left: 32px;
}
.nav-dropdown__search-box .ant-input:focus {
  box-shadow: none;
}
.nav-dropdown__search-box .ant-input-prefix {
  top: calc(50% + 1px);
}
.nav-dropdown__item-group .ant-dropdown-menu-item-group-title {
  display: none;
}
.nav-dropdown__item-group .ant-dropdown-menu-item-group-list {
  margin: 0;
}
.nav-dropdown__item-group {
  max-height: 413px;
  overflow: auto;
  overscroll-behavior: contain;
}
.nav-dropdown__dropdown .ant-dropdown-menu-item,
.nav-dropdown__dropdown .ant-dropdown-menu-submenu-title,
.force-gray {
  transition: background-color 0.2s;
}
.nav-dropdown__dropdown .ant-dropdown-menu-item-active,
.nav-dropdown__dropdown
  .ant-dropdown-menu-submenu-active
  .ant-dropdown-menu-submenu-title,
.force-gray:hover,
.nav-dropdown__dropdown
  .ant-dropdown-menu-item:not([aria-disabled="true"]):hover {
  background: #f0f0f0;
}
.nav-dropdown__dropdown .ant-dropdown-menu-item-selected,
.nav-dropdown__dropdown
  .ant-dropdown-menu-submenu-selected
  .ant-dropdown-menu-submenu-title {
  font-weight: 600;
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.65);
}
.nav-dropdown__item-name {
  max-width: 204px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nav-dropdown__dropdown .ant-dropdown-menu-item-disabled,
.nav-dropdown__dropdown
  .ant-dropdown-menu-submenu-disabled
  .ant-dropdown-menu-submenu-title {
  cursor: default;
}

.nav-dropdown__tab-button {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  padding: 5px 6px 8px;
  transition: opacity 0.2s;
}
.nav-dropdown__tab-button:not(.is-active):hover {
  opacity: 0.6;
}

.nav-dropdown__tab-button.is-active {
  font-weight: 600;
  border-bottom: 2px solid rgba(0, 0, 0, 0.66);
  padding-bottom: 6px;
}
.nav-dropdown__tab-container {
  padding: 0;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 4px;
}
.nav-dropdown__tab-wrapper {
  display: flex;
  gap: 8px;
  padding: 0 12px;
}
.nav-dropdown__dropdown:not(.nav-dropdown__context-dropdown) {
  min-width: 300px;
}
.nav-dropdown__cta-container {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  padding-top: 8px;
  margin-top: 4px;
}

.nav-dropdown__cta-container:not(.is-default-button) .ant-btn {
  background-color: #001529;
  border-color: #001529;
}
.nav-dropdown__cta-container:not(.is-default-button) .ant-btn:hover {
  background-color: #3b4e5b;
  border-color: #3b4e5b;
}
.nav-dropdown__tab-wrapper .ant-badge {
  line-height: inherit;
}

body .public-DraftStyleDefault-block{
    /*draftjs text editor kill stupid vertical margins*/
    margin-top: 0;
    margin-bottom: 0;
}
body .public-DraftStyleDefault-ltr{
    text-align: inherit;
}
/*we do not need a clickable link icon when focus a link*/
.rdw-link-decorator-icon{
    display: none;
}
.rdw-editor-main{
    padding: 0px 10px 8px 10px;
}
.rdw-editor-wrapper{
    border: 1px solid var(--piter);
    background-color: white;
    border-radius: 4px;
}
body .rdw-editor-toolbar{
    border: none;
    border-bottom: 1px solid var(--piter);
}
body .rdw-option-wrapper{
    padding: 5px;
    height: 24px;
    border-radius: 4px;
}
.rdw-option-wrapper img{
    height: 100%;
    opacity: 0.6;
}
.rdw-option-wrapper, .rdw-dropdown-wrapper{
    transition: border-color 0.2s ease;
}

body .rdw-option-wrapper:hover, body .rdw-option-active, body .rdw-dropdown-wrapper:hover,
body .rdw-dropdown-optionwrapper:hover,
body .rdw-emoji-modal{
    box-shadow: none;
}
body .rdw-emoji-icon{
    margin: 4px;
    font-size: 14px;
    border-radius: 4px;
    padding: 5px;
    height: auto;
    color: #000;
    display: inline-block;
    width: 30px;
    text-align: center;
}
body .rdw-emoji-icon:hover{
    transition: background-color 0.2s ease;
}
body .rdw-emoji-modal{
    padding: 5px;
    left: auto;
    right: 5px;
}
.rdw-emoji-icon:hover{
    background-color: var(--gray);
}
body .rdw-dropdown-wrapper{
    height: 24px;
    font-size: 12px;
    border-radius: 4px;
}
body .rdw-option-active{
    border-color: var(--ant);
}
body .rdw-dropdown-wrapper:hover, body .rdw-option-wrapper:hover{
    border-color: var(--concrete);
}
body .rdw-block-dropdown{
    width: 85px;
}
.rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose{
    display: none;
}

body .rdw-link-wrapper, body .rdw-embedded-wrapper, body .rdw-emoji-wrapper{
    position: static; /*to align popups to the tool bar - be on the left*/
}
.rdw-editor-toolbar{
    position: relative;
}

.rdw-embedded-modal-header-option{
    white-space: nowrap;
}
.rdw-embedded-modal-header-label{
    display: none;
}
.rdw-editor-toolbar{
    height: 37px;
    transition: height 0.2s ease, opacity 0.2s ease;
}
.rdw-editor-toolbar[aria-hidden="true"]{
    /*height: 0;*/
    /*opacity: 0;*/
}
.rdw-editor-toolbar[style="visibility: hidden;"]{
    height: 0;
    opacity: 0;
}
.rdw-editor-main ul li > div{
  display: inline;
}

body .rdw-embedded-modal-header,
body .rdw-embedded-modal-link-input,
body .rdw-embedded-modal-size-input,
body .rdw-link-modal-input{
    font-size: 14px;
    border-radius: 4px;
}
body .rdw-embedded-modal-size-input{
    padding-left: 10px;
}
body .rdw-embedded-modal,
body .rdw-embedded-modal-btn:hover,
body .rdw-link-modal-btn,
body .rdw-link-modal-btn:hover,
body .rdw-link-modal{
    box-shadow: none;
}
body .rdw-link-modal{
    height: 166px;
}
body .rdw-link-modal-label{
    font-size: 14px;
}
body .rdw-embedded-modal-header-option{
    display: inline;
}
body .rdw-link-modal-label:after{
    content: ':';
}
.rdw-embedded-modal-header-option:after{
    content: ' (iframe src)';
    opacity: 0.6;
}
.rdw-link-modal-target-option{
    cursor: pointer;
}
body .rdw-embedded-modal-btn,
body .rdw-link-modal-btn{
    font-size: 14px;
    border-radius: 4px;
    height: 24px;

    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
body .rdw-embedded-modal-btn[disabled],
body .rdw-link-modal-btn[disabled]{
    cursor: not-allowed;
}
body .rdw-embedded-modal-btn:hover:not([disabled]),
body .rdw-link-modal-btn:hover:not([disabled]){
    color: var(--ant);
    border-color: var(--ant);
}

body .rdw-embedded-modal-link-input,
body .rdw-link-modal-input{
    padding-left: 10px;
}
body .rdw-link-modal-input,
body .rdw-embedded-modal-link-input{
    height: 24px;
}
body .rdw-embedded-modal-size{
    font-size: 14px;
    /*display: none;*/
}

body .rdw-embedded-modal{
    height: 186px;
}
body .rdw-embedded-modal-btn-section{
    justify-content: flex-start;
}
body .rdw-link-modal-buttonsection{
    margin: 0;
}
body .rdw-link-modal-btn,
body .rdw-embedded-modal-btn{
    margin-left: 0;
    margin-right: 10px;
}

/*useless fields*/
.rdw-link-modal-label[for="linkTitle"],
.rdw-link-modal-input[name="linkTitle"]{
    display: none;
}

.rdw-image-mandatory-sign{
    display: none;
}

.rdw-embedded-modal-size:before{
    content: 'Size:';
    display: block;
    font-size: 14px;

    margin-right: 10px;
}
.rdw-embedded-modal-link-input-wrapper:before{
    content: 'Src:';
    display: block;

    font-size: 14px;
    margin-right: 10px;
}

.rdw-editor-main{
    max-height: 300px;
}
.rdw-editor-main{
    color: #5e5e5e;
}
body .public-DraftEditorPlaceholder-root{
    color: #cdcdcd;
}
.rdw-editor-revert-button{
    position: absolute;
    right: 8px;
    z-index: 10;
}


body .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0{
    list-style-type: none;
}

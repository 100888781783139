.other-01{
    padding: 50px 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    text-align: center;
}
.other-01__logo_box {
    margin-bottom: 12px;
}
.other-01__logo{
    height: 30px;
}
.uni-is-dark-bg .other-01__logo-new {
    color: #fff;
}
.other-01__logo-new {
  font-size: 28px;
}
.other-01__title{
    margin-bottom: 10px;

    font-size: 20px;
    line-height: 1.4;
}
.other-01__info{
    font-size: 14px;
    opacity: 0.5;
}

.other-01__wrapper{
    position: relative;
    height: 100px;
    overflow: hidden;

}
.other-01__wrapper:hover,
.page-component__gpt-loading-wrapper--visible .other-01__wrapper{
    overflow: auto;
}
.other-01__text{
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
}
.other-01__wrapper-has_content:hover .other-01__text,
.page-component__gpt-loading-wrapper--visible .other-01__text{
    opacity: 0;
}

.other-01__wrapper-has_content:hover .other-01__code,
.page-component__gpt-loading-wrapper--visible .other-01__code {
    opacity: 1;
    transform: none;
    transition: opacity 0.25s ease, transform 0.25s ease;
}

.other-01__code{
    transform: translateY(5px);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    margin: auto;

    opacity: 0;

    text-align: left;
    line-height: 1.6;
    font-size: 14px;
}
html .comps .other-01__code{
    font-family: monospace !important;
}

$top-height: 190px;
$gap: 12px;
$title-margin-left: 0;

.page-template {
  display: flex;
  gap: $gap;
  flex-direction: column;
  position: relative;
  
  &__top {
    height: $top-height;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--gray);
    position: relative;
    cursor: pointer;
    &:hover {
      .page-template__button {
        opacity: 1;
      }
      .page-template__thumbnail {
        object-position: bottom;
        transition: object-position 5s ease-in-out;
      }
    }
  }

  &__thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transition: object-position 1s ease-in-out;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: default;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    color: rgba(0,0,0,0.88);
    margin: 0;
    margin-left: $title-margin-left;
  }

  &__button.ant-btn {
    bottom: 12px;
    right: 12px;
    z-index: 10;
    opacity: 0;
    position: absolute;
    border-color: #fff;
    transition: all 0.15s, background-color 0.3s;

    &:hover {
      border-color: #fff;
    }
  }

  &__text-blank {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    opacity: 0.15;
  }
}

.page-template-loading {
  display: flex;
  flex-direction: column;
  gap: $gap;

  &__top, &__bottom {
    background: linear-gradient(90deg, rgba(0,0,0,0.04) 25%, rgba(255,255,255,0.4) 35%, rgba(0,0,0,0.04) 45%);
    background-size: 400% 100%;
    animation: directory-loading 1.7s ease infinite;
    border-radius: 6px;
  }

  &__top {
    height: $top-height;
  }

  &__bottom {
    height: 20px;
    width: 50%;
    margin-left: $title-margin-left;
  }
}

.page-templates__inline-container {
  .page-templates__list {
    width: 100%;
    max-width: 1000px;
  }
}
.page-templates .ant-modal-body {
  max-height: 650px;
  overflow: auto;
  padding: 24px 32px 32px;
}

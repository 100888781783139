.editor-screen-page-controls{
    list-style: none;

    display: flex;
    margin-bottom: 0;
}


.editor-screen-page-controls__item{
    margin-left: 15px;
}
.editor-screen-page-controls__item:first-child{
    margin-left: 0;
}

.cms-sidebar-drawer .ant-drawer-body {
  padding: 0;
}

.editor-screen-page-controls__item .anticon {
  position: relative;
  top: 1px;
}

.ant-btn .custom-icon-ghost-white path {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn:hover .custom-icon-ghost-white path {
  fill: #40A9FF;
}

.not-found{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;

    text-align: center;
}
.not-found__error_code{
    font-size: 100px;
    font-weight: 700;
}
.not-found__title{
    margin-bottom: 40px;
}
.not-found__bottom_button{

}
.font-option-item{
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 15px 20px;
    margin-bottom: 30px;

    width: 350px;

    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    cursor: pointer;

    text-align: left;

    transition: box-shadow 0.25s ease, transform 0.25s ease;
}
.font-option-item:hover:not(.font-option-item--active){
    box-shadow: var(--card-shadow);
    transform: translateY(-5px);
}
.font-option-item--active{
    border-color: var(--ocean);
    cursor: default;
}

.font-option-item__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.font-option-item--active .font-option-item__examples{
    color: var(--ocean);
}

.font-option-item__examples.font-lato{
    font-family: 'Lato', sans-serif !important;
}
.font-option-item__examples.font-work-sans{
    font-family: 'Work Sans', sans-serif !important;
}
.font-option-item__examples.font-source-code-pro{
    font-family: 'Source Code Pro', monospace !important;
}

.font-option-item__examples{
    padding-right: 20px;

    border-right: 1px solid var(--piter);
}
.font-option-item__title_example{
    margin-bottom: 5px;
    margin-top: 5px;

    font-size: 40px;
    color: inherit;
}
.font-option-item__text_example{
    margin-bottom: 10px;

    font-size: 20px;
}
.font-option-item__numbers_example{
    font-size: 14px;
}

.font-option-item__title_example,
.font-option-item__text_example,
.font-option-item__numbers_example{
    transition: color 0.25s ease;
}


.font-option-item__info{
    padding-left: 20px;
    font-size: 14px;
}


.font-option-item__card_overlay{
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    margin: auto;

    font-size: 14px;

    background-color: rgba(255, 255, 255, 0.85);
    opacity: 0;

    transition: opacity 0.4s ease;
}
.font-option-item__card_overlay--visible, .font-option-item__card_overlay--spinner-visible{
    opacity: 1;
}

.font-option-item__spinner{
    opacity: 0;
}
.font-option-item__card_overlay--spinner-visible .font-option-item__spinner{
    opacity: 1;
}

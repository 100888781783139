.auth-layout{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: 20px 10px 40px;

    background-color: var(--main-bg-gray);
}

.auth-layout__top{
    margin-bottom: 20px;
}
.auth-layout__home_link{
    display: inline-block;
}
.auth-layout__home_logo{
    height: 30px;
}
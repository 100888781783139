.help-button{
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 201000000; /*more than .page-component__component_controls*/
}
.help-button__info{
    display: block;
    opacity: 0.7;
    font-size: 12px;
}
.help-button__action{
    cursor: pointer;
    text-align: left;
}
/*.help-button__icons{*/
    /*text-align: center;*/
/*}*/

.hor-input-group {
  display: flex;
  &.equal-space > * {
    flex: 1 1 0;
  }
  & .content-input__input {
    margin-bottom: 0;
  }
}

.ver-input-group {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    --main-bg-gray: #F0F2F5;
    --concrete: #7C7C7C;
    --ocean: #40a9ff;
    --cloud: #E9E9E9;
    --blood: #D0021B;
    --orange: #FAAD14;
    --grass: #4ADDB4;
    --darkness: #303030;

    --gray: #E8E8E8;
    --piter: #D9D9D9;
    --night: #334453;

    --ant: #1890FF;
    --ant-error-color: #ff4d4f;
    --ant-error-color-hover: #ff7875;

    --main-border-radius: 4px;

    --card-shadow: 0 2px 8px 0 rgba(0,0,0,0.09);
    --card-shadow-small: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --card-bordered-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px #e8e8e8;

    --ai: #722ed1;

    --ant-success: #52c41a;

    --text-secondary: rgba(0,0,0,0.45);

    --border-radius: 6px;
}

.container{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
body .white-border{
    border: 1px solid white;
}
#confetti-holder{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    max-width: 100%;
    margin: auto;

    transition: opacity 1s linear;
}

/*Ant.design overrides*/
body .ant-input-group-addon{
    color: rgba(0, 0, 0, 0.4);
    cursor: default;
}

body .ant-progress-circle-trail{
    stroke: rgb(234, 236, 239) !important;
}

.ant-modal-mask{
    cursor: pointer;
}

.settings, .websites, .website-dashboard, .account-settings{
    padding-top: 30px;
    padding-bottom: 60px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: var(--main-bg-gray);
}
@media (max-width: 768px) {
  .settings, .websites, .website-dashboard, .account-settings { 
    padding-left: 24px;
    padding-right: 24px;
   }
}
@media (max-width: 500px) {
  .settings, .websites, .website-dashboard, .account-settings { 
    padding-left: 0;
    padding-right: 0;
   }
}
.websites, .website-pages{
    background-color: var(--main-bg-gray);
}
.settings, .account-settings{
    padding-top: 40px;
}

body #chatra.chatra--pos-right{
    right: 50px;
}

.checkered-bg{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.checkered-bg:before{
    content: "";

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    margin: auto;
    z-index: 1 ;

    /*background-image: linear-gradient(45deg, #F3F3F3 25%, transparent 25%), linear-gradient(-45deg, #F3F3F3 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #F3F3F3 75%), linear-gradient(-45deg, transparent 75%, #F3F3F3 75%);*/
    background-image: linear-gradient(45deg, #D6D6D6 25%, transparent 25%), linear-gradient(-45deg, #D6D6D6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #D6D6D6 75%), linear-gradient(-45deg, transparent 75%, #D6D6D6 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    background-color: white;

    opacity: 0.3;
    transition: opacity 0.5s ease;
}
.checkered-bg.has-opacity:before {
  opacity: 0.2;
}
.checkered-bg:not(.checkered-bg--no-hover):hover:before{
    opacity: 1;
}
.checkered-bg img{
    position: relative;
    z-index: 3; /*because we have a :before pseudo element inside the .checkered-bg */
}

/* Because the react-js version of Slick slider doesn't allow to place custom arrows in the container I need.*/
.slider-06__slider{
    padding-bottom: 0;
}
.slider-06__slider .slick-slider{
    padding-bottom: 90px;
}

.uploadcare--widget__button,
.uploadcare--panel__content .uploadcare--tab__action-button,
.uploadcare--widget__button:focus{
    /*all: unset;*/
    color: #fff;
    background-color: var(--ant);
    border-color: var(--ant);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    height: 32px;
    /*font-weight: 400;*/
    padding: 0 15px;
    /*font-size: 16px;*/
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font: inherit;
}
.uploadcare--widget__button:hover,
.uploadcare--panel__content .uploadcare--tab__action-button:hover{
    /*font-size: 16px;*/
    cursor: pointer;
    background-color: #40a9ff;
    border-color: #40a9ff;
    font: inherit;
}
.uploadcare--powered-by{
    opacity: 0.3;
    transition: opacity 0.25s ease, transform 0.25s ease;
}
.uploadcare--powered-by:hover{
    opacity: 1;
}
.uploadcare--input{
    height: 30px;
    padding: 0 15px;
    border-radius: 4px;
    font-size: 16px;
}
.uploadcare--menu__item_tab_file{
    margin-bottom: 50px;
}
.gist__title{
    display: none;
    /*import ReactEmbedGist from 'react-embed-gist'; adds additional markup such as this title*/
}
.loading__screen{
    text-align: center;
}
html div[id^=font-picker] .dropdown-button{
    background: #ffffff;
}
html div[id^=font-picker] .dropdown-button:hover,
html div[id^=font-picker] .dropdown-button:focus {
    background: #ffffff;
}
html div[id^=font-picker] ul{
    background: #ffffff;
}
html div[id^=font-picker] ul li button.active-font {
    background: #303030;
    color: white;
}
div[id^=font-picker] .dropdown-icon.finished::before{
    opacity: 0.4;
}
html div[id^=font-picker]{
    width: 220px;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid var(--piter);
}
.underlined_link,
.ant-typography .underlined_link{
    text-decoration: underline;
}
.click-to-select{
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
    color: var(--night);

    padding: 2px 10px;
    border-radius: 9999px;
    font-weight: 400;
    font-family: monospace;
    background-color: var(--main-bg-gray);
}

.editor__wrapper .nav-02{
    z-index: 130; /*nav has zi 1300 in templates to overp the first component which has a generated zi which can be over 1000 when there are more than 10 components. The zi are generated to be one bigger that another to allow diaforms overlap each other.*/
}

.uploadcare--widget_status_error .uploadcare--widget__text{
    color: var(--blood);
}

.uploadcare--page .ant-notification{
    z-index: 2147483648;
}

html .ant-notification {
  z-index: 1300;
}

/*.ghost_tabs applied to <Tabs> makes it look like text*/
/* .ghost_tabs .ant-tabs-bar{
    border: none;
    margin: 0;
    margin-bottom: 30px;
}
.ghost_tabs .ant-tabs-tab{
    padding: 0 !important;
}
.ghost_tabs .ant-tabs-ink-bar{
    display: none !important;
}
.ghost_tabs .tab-title{
    font-weight: 300;
} */

.ant-drawer.ant-drawer-open .ant-drawer-mask{
  opacity: 0 !important;
  background-color: transparent !important;
  cursor: default !important;
}
.ant-drawer-wrapper-body {
  overscroll-behavior: contain;
}
@media (max-width: 1496px) {
  .ant-drawer .ant-drawer-content-wrapper {
    max-width: calc(100vw - 16px) !important;
  }
}
.page-templates .ant-modal-content,
.create-new-website-modal .ant-modal-content {
  min-width: 768px;
}
.not_closable_tag{
    cursor: default !important;
}
.horizontal-form {
  display: flex;
}
.horizontal-form .horizontal-form__input { 
  /* Nesting to override default antd margins */
  margin-right: 15px;
}
.paragraph-custom:last-child {
  margin-bottom: 0;
}

/* Primary danger buttons aren't available in our version of antd, so we added them manually */
.ant-btn.ant-btn--danger-custom:not(.ant-btn[disabled]) {
  border-color: var(--ant-error-color);
  background: var(--ant-error-color);
}
.ant-btn.ant-btn--danger-custom:not(.ant-btn[disabled]):hover,
.ant-btn.ant-btn--danger-custom:not(.ant-btn[disabled]):focus {
  border-color: var(--ant-error-color-hover);
  background: var(--ant-error-color-hover);
}

.ant-spin-container.ant-spin-blur {
  overflow: visible;
}
.ant-spin-blur.ant-spin-container:after {
  z-index: 200;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  max-height: 182px;
  overflow: auto;
}

.ant-popover.no-arrow {
  padding-top: 0;
}
.ant-popover.no-arrow .ant-popover-arrow {
  display: none;
}
.ant-popover.padding-12 .ant-popover-inner-content {
  padding: 12px;
}
body.state-fixed-body_popup {
	overflow: hidden;
}

*.is-display-none {
  display: none;
}

.ant-btn.is-black-button {
  background-color: #001529;
  border-color: #001529;
}
.ant-btn.is-black-button:hover,
.ant-btn.is-black-button:focus {
  background-color: #3b4e5b;
  border-color: #3b4e5b;
}

.editor .nav-02.nav-02--hidden {
  opacity: 0.5;
  background-color: #fff;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
  linear-gradient(-45deg, #ccc 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, #ccc 75%),
  linear-gradient(-45deg, transparent 75%, #ccc 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.comps .form__input__label_box {
  padding: 0;
}

.crisp-client .cc-1brb6 {
  z-index: 210000000 !important;
}

.paddle-frame {
  z-index: 200000000 !important;
}

.primary-layout .editor .comps .mockup_v2--iphone_15_pro .mockup_v2__area {
  width: 92.5%;
  top: 1.3%;
  left: 0;
  height: 97.8%;
  overflow: hidden;
  border-radius: 20px;
}

.login-form__forgot{
    font-size: 12px;

    color: var(--concrete);
}
.login-form__bottom_button{
    /* not DRY :( */
    display: inline-block;
}
.grecaptcha-badge {
  bottom: 80px !important;
}

.auth-form{
    width: 100%;

    text-align: center;

}
.auth-form form {
    max-width: 344px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 1px 20px;

    background-color: white;
    border-radius: var(--main-border-radius);
    box-shadow: var(--card-shadow);

    margin-bottom: 30px;
}

.auth-form-header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
}
.auth-form-header__left{
    line-height: 31px;
}
.auth-form-header__title{
    display: inline-block;

    margin-right: 7px;
    margin-bottom: 0;
}
.auth-form-header__subtitle{
    font-size: 10px;
    opacity: 0.8;
}
.auth-form-header__right{

}
.join-form__bottom_button{
    /* not DRY :( */
    display: inline-block;
}
.join-form__agreements{
    max-width: 350px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}
form.login-form__form{
    padding-bottom: 20px;
}
.login-form .ant-form-item-label{
    line-height: 1;
}
.login-form .ant-form-item{
    text-align: left;
    margin-bottom: 10px;

}
.login-form .ant-form-item label{
    font-size: 12px;
}

.login-form .ant-form-item:last-child{
    text-align: center;
}
.login-form__button{
    margin-top: 16px;
}
.restore-pass-form__bottom_button{
    /* not DRY :( */
    display: inline-block;
}
.auth-layout{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: 20px 10px 40px;

    background-color: var(--main-bg-gray);
}

.auth-layout__top{
    margin-bottom: 20px;
}
.auth-layout__home_link{
    display: inline-block;
}
.auth-layout__home_logo{
    height: 30px;
}
.set-new-pass-form__bottom_button{
    /* not DRY :( */
    display: inline-block;
}
.primary-layout{
    min-height: 100vh;
    background: #fff;
    min-width: 1000px;
}
.primary-layout__home_link{
    position: relative;
    display: inline-block;

    text-decoration: none;
    color: white;

    transition: color 0.3s ease;
    cursor: pointer;
}
.primary-layout__xmas{
    position: absolute;
    left: -10px;
    height: 20px;
    top: 6px;
}
.primary-layout__home_link:focus{
    text-decoration: none;
}
.primary-layout__home_link:hover{
    /*opacity: 0.85;*/
    color: white;
}
.primary-layout__home_link_text{
    margin-left: 10px;

    border-radius: var(--main-border-radius);
    padding: 3px 6px 5px;
    transition: background-color 0.3s ease, opacity 0.3s ease;

    color: inherit;
}
.primary-layout__nav_divider{
    margin-left: 10px;
    margin-right: 10px;

    color: var(--night);
}

.primary-layout__home_link_text:hover{
    background-color: var(--night);
}

.primary-layout__icon_placeholder{
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.primary-layout__nav_container {
  max-width: 1280px;
  margin: 0 auto;
}
.primary-layout .ant-layout-header{
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 990;
  border-bottom: 1px solid #595959;
}
.footer__editor {
  transition: background-color 0.25s ease;
}

.primary-layout__notification .ant-notification-notice-description,
.primary-layout__notification .ant-notification-notice-description .paragraph-custom {
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-dropdown {
  display: inline-block;
  color: white; }

.nav-dropdown__link,
.nav-dropdown__link:focus,
.nav-dropdown__link:hover {
  color: white;
  text-decoration: none; }

.nav-dropdown__menu_item.ant-dropdown-menu-item {
  position: relative;
  padding: 0; }

.nav-dropdown__menu_item {
  border-bottom: 1px solid #e8e8e8; }

.nav-dropdown__menu_item:last-child {
  border-bottom: none; }

.nav-dropdown__item_row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 5px 12px 5px 0px; }

.ant-dropdown-menu-item a.nav-dropdown__item_link {
  margin: 0;
  padding: 4px 10px 4px 16px;
  color: rgba(0, 0, 0, 0.65);
  width: 100%; }

.ant-dropdown-menu-item > .nav-dropdown__item_view_box {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center; }

.ant-dropdown-menu-item a.nav-dropdown__item_view {
  margin-left: 5px;
  opacity: 0.3;
  transition: opacity 0.3s ease; }

/*.ant-dropdown-menu-item a.nav-dropdown__item_view:last-child{*/
/*transition-delay: 0.2s;*/
/*}*/
.ant-dropdown-menu-item:hover a.nav-dropdown__item_view {
  opacity: 1; }

.nav-dropdown__item {
  padding: 3px 6px 5px;
  transition: opacity 0.3s ease; }

.nav-dropdown__item--transparent {
  opacity: 0.6; }

.nav-dropdown__link {
  padding: 5px 6px;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  line-height: 100%;
  border-radius: var(--main-border-radius);
  transition: background-color 0.3s ease, opacity 0.3s ease; }

.nav-dropdown__link:hover {
  background-color: var(--night); }

.nav-dropdown__link--divider {
  position: relative; }

.nav-dropdown__link--divider:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 999px;
  background-color: var(--night);
  height: 2px;
  width: 2px; }

.nav-dropdown__more-button {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s; }

.ant-dropdown-menu-item:hover .nav-dropdown__more-button {
  visibility: visible; }

.nav-dropdown__more-button:hover {
  background: #d9d9d9; }

.nav-dropdown__navigation-item {
  padding: 3px 5px 3px 12px; }

.nav-dropdown__create-button {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.88);
  transition: background-color 0.2s; }

.nav-dropdown__create-button:not([disabled]) {
  cursor: pointer; }

.nav-dropdown__create-button:not([disabled]):hover {
  background: #f0f0f0; }

.nav-dropdown__search-box {
  padding: 0;
  cursor: default; }

.nav-dropdown__search-box .ant-input {
  border: none; }

.nav-dropdown__search-box .ant-input-affix-wrapper .ant-input {
  padding-left: 32px; }

.nav-dropdown__search-box .ant-input:focus {
  box-shadow: none; }

.nav-dropdown__search-box .ant-input-prefix {
  top: calc(50% + 1px); }

.nav-dropdown__item-group .ant-dropdown-menu-item-group-title {
  display: none; }

.nav-dropdown__item-group .ant-dropdown-menu-item-group-list {
  margin: 0; }

.nav-dropdown__item-group {
  max-height: 413px;
  overflow: auto;
  overscroll-behavior: contain; }

.nav-dropdown__dropdown .ant-dropdown-menu-item,
.nav-dropdown__dropdown .ant-dropdown-menu-submenu-title,
.force-gray {
  transition: background-color 0.2s; }

.nav-dropdown__dropdown .ant-dropdown-menu-item-active,
.nav-dropdown__dropdown
.ant-dropdown-menu-submenu-active
.ant-dropdown-menu-submenu-title,
.force-gray:hover,
.nav-dropdown__dropdown
.ant-dropdown-menu-item:not([aria-disabled="true"]):hover {
  background: #f0f0f0; }

.nav-dropdown__dropdown .ant-dropdown-menu-item-selected,
.nav-dropdown__dropdown
.ant-dropdown-menu-submenu-selected
.ant-dropdown-menu-submenu-title {
  font-weight: 600;
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.65); }

.nav-dropdown__item-name {
  max-width: 204px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.nav-dropdown__dropdown .ant-dropdown-menu-item-disabled,
.nav-dropdown__dropdown
.ant-dropdown-menu-submenu-disabled
.ant-dropdown-menu-submenu-title {
  cursor: default; }

.nav-dropdown__tab-button {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  padding: 5px 6px 8px;
  transition: opacity 0.2s; }

.nav-dropdown__tab-button:not(.is-active):hover {
  opacity: 0.6; }

.nav-dropdown__tab-button.is-active {
  font-weight: 600;
  border-bottom: 2px solid rgba(0, 0, 0, 0.66);
  padding-bottom: 6px; }

.nav-dropdown__tab-container {
  padding: 0;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 4px; }

.nav-dropdown__tab-wrapper {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  padding: 0 12px; }

.nav-dropdown__dropdown:not(.nav-dropdown__context-dropdown) {
  min-width: 300px; }

.nav-dropdown__cta-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  padding-top: 8px;
  margin-top: 4px; }

.nav-dropdown__cta-container:not(.is-default-button) .ant-btn {
  background-color: #001529;
  border-color: #001529; }

.nav-dropdown__cta-container:not(.is-default-button) .ant-btn:hover {
  background-color: #3b4e5b;
  border-color: #3b4e5b; }

.nav-dropdown__tab-wrapper .ant-badge {
  line-height: inherit; }

.page-template {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  flex-direction: column;
  position: relative; }
  .page-template__top {
    height: 190px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--gray);
    position: relative;
    cursor: pointer; }
    .page-template__top:hover .page-template__button {
      opacity: 1; }
    .page-template__top:hover .page-template__thumbnail {
      object-position: bottom;
      transition: object-position 5s ease-in-out; }
  .page-template__thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transition: object-position 1s ease-in-out; }
  .page-template__bottom {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    cursor: default; }
  .page-template__title {
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.88);
    margin: 0;
    margin-left: 0; }
  .page-template__button.ant-btn {
    bottom: 12px;
    right: 12px;
    z-index: 10;
    opacity: 0;
    position: absolute;
    border-color: #fff;
    transition: all 0.15s, background-color 0.3s; }
    .page-template__button.ant-btn:hover {
      border-color: #fff; }
  .page-template__text-blank {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    opacity: 0.15; }

.page-template-loading {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px; }
  .page-template-loading__top, .page-template-loading__bottom {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.04) 25%, rgba(255, 255, 255, 0.4) 35%, rgba(0, 0, 0, 0.04) 45%);
    background-size: 400% 100%;
    -webkit-animation: directory-loading 1.7s ease infinite;
            animation: directory-loading 1.7s ease infinite;
    border-radius: 6px; }
  .page-template-loading__top {
    height: 190px; }
  .page-template-loading__bottom {
    height: 20px;
    width: 50%;
    margin-left: 0; }

.page-templates__inline-container .page-templates__list {
  width: 100%;
  max-width: 1000px; }

.page-templates .ant-modal-body {
  max-height: 650px;
  overflow: auto;
  padding: 24px 32px 32px; }

.create-new-website-modal .ant-modal-body {
  padding: 0;
}
.create-new-website-modal{
  width: calc(100vw - 40px) !important;
  max-width: 1600px;
  top: 20px;
}
.create-new-website-modal__form-body{
  height: calc(100vh - 120px);
  /* max-height: 1200px; */
  overflow: auto;
}
.create-new-website-modal__input_box{
}

.create-new-website-modal__button{
    /* shame centering*/
    position: relative;
    bottom: 1px;
}
.create-new-website-modal__errors{
    max-width: 400px;
    margin-top: 3px;
    padding-left: 10px;

    color: var(--blood);

    transition: opacity 0.3s ease;
}
.create-new-website-modal__input{
    margin-right: 20px;
}
.create-new-website-modal__label{
    display: block;

    margin-bottom: 10px;
}
.create-new-website-modal__form-footer {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    background-color: #fff;
    padding: 15px 24px;
    z-index: 1;
}

.create-new-website-modal__form-body {
  padding: 24px;
}
.spinner_box{
    padding: 50px 20px;
    text-align: center;
}
.spinner_box__text{
    margin-top: 10px;
}
.template-select {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.template-select__tags {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 10px;
}

.template-select__tags_item {
  list-style: none;
  margin-right: 10px;
  position: relative;
}
.template-select__tags_item:last-child{
  margin-right: 0;
}

.template-select__tags_item_button {
  display: flex;
  align-items: center;
  border: none;
  box-shadow: none;
}
.template-select__tags_item_button:hover{
  background-color: #eff4fd;
}
.ant-btn.template-select__tags_item_button--active{
  background-color: #E9F1FF;
  /* background-color: #DFEAFE; */
  color: var(--ant);
}

.template-select__tags_item_count {
  margin-left: 2px;
  font-size: 0.8em;
  opacity: 0.6;
  position: relative;
  bottom: 3px;
}

.template-select__list {
  /* display: flex;
  align-items: center;
  flex-wrap: wrap; */
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.template-select__item {
  display: inline-block;
  position: relative;
  margin-bottom: 60px;
  margin-left: 20px;
  margin-right: 20px;
  list-style: none;
  z-index: 1;
  max-width: 380px;
  width: 33%;
}

.template-select__item_header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.template-select__item_title {
  text-align: center;
}

.template-select__item_card {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 0;
  overflow: hidden;
  height: 200px;
  border-radius: 10px;
  text-decoration: none;
  border: 1px solid var(--gray);
}
.template-select__item_card--hover-effect{
  transition: 1s ease-in-out background-position-y, 0.2s ease box-shadow, 0.2s ease border-color, 0.2s ease transform;
}
.template-select__item_card--hover-effect:hover{
  box-shadow: var(--card-shadow);
  transform: translateY(-4px);
  background-position-y: 100%;
  transition: 5s ease-in-out background-position-y, 0.2s ease box-shadow, 0.2s ease border-color, 0.2s ease transform;
  border-color: transparent;
}

.template-select__item_card-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  /*transition: .15s;*/
  /*opacity: 0;*/
}
/*.template-select__item_card:hover .template-select__item_card-controls{*/
/*  opacity: 1;*/
/*}*/

.template-select__item_card_info {
  padding: 10px 8px 8px 8px;
}

.template-select__open-preview {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 15px;
  margin-right: 10px;
  opacity: 0;
}

.template-select__blank_icon{
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

.nav-dropdown-items{
    display: inline;
}
.nav-dropdown-items__nav_divider{
    margin-left: 10px;
    margin-right: 10px;

    color: white;
    opacity: 0.4;
}

@-webkit-keyframes skeleton-loader-animation {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes skeleton-loader-animation {
  0% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

.skeleton-loader {
  display: inline-block;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.2) 35%, rgba(255, 255, 255, 0.1) 45%);
  background-size: 400% 100%;
  -webkit-animation: directory-loading 0.9s ease infinite;
          animation: directory-loading 0.9s ease infinite;
  border-radius: 10px; }

.editor-screen-page-controls{
    list-style: none;

    display: flex;
    margin-bottom: 0;
}


.editor-screen-page-controls__item{
    margin-left: 15px;
}
.editor-screen-page-controls__item:first-child{
    margin-left: 0;
}

.cms-sidebar-drawer .ant-drawer-body {
  padding: 0;
}

.editor-screen-page-controls__item .anticon {
  position: relative;
  top: 1px;
}

.ant-btn .custom-icon-ghost-white path {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn:hover .custom-icon-ghost-white path {
  fill: #40A9FF;
}

.settings-drawer .ant-drawer-body {
  padding: 0;
  height: calc(100vh - 0px); }

.settings-drawer .ant-drawer-header {
  padding: 0;
  border: none; }
  .settings-drawer .ant-drawer-header .ant-drawer-title {
    display: none; }
  .settings-drawer .ant-drawer-header .ant-drawer-close {
    font-size: 14px;
    top: 27px;
    right: 9px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 999px; }

.settings-drawer.ant-drawer .ant-drawer-content-wrapper {
  box-shadow: 0px 0px 2.7px rgba(0, 0, 0, 0.073), 0px 0px 8.9px rgba(0, 0, 0, 0.107), 0px 0px 40px rgba(0, 0, 0, 0.18);
  max-width: 1496px; }

.settings-drawer.ant-drawer .ant-drawer-mask {
  cursor: default !important; }

.settings-drawer__sidebar {
  padding: 8px 0;
  border-right: 1px solid #e8e8e8;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  min-width: 174px;
  width: 174px;
  overflow: auto;
  height: calc(100vh - 0px);
  overscroll-behavior: contain; }
  .settings-drawer__sidebar .ant-typography {
    padding: 5px 12px;
    display: inline-block; }
  .settings-drawer__sidebar .ant-divider {
    margin: 4px 0;
    min-height: 1px;
    top: 0; }

.settings-drawer__content {
  display: flex; }

.settings-drawer__sidebar-button {
  padding: 6px 14px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
  line-height: 22px;
  width: 100%;
  text-align: left;
  transition: background-color 0.2s; }
  .settings-drawer__sidebar-button:hover {
    background: #e8e8e8; }
  .settings-drawer__sidebar-button.is-active {
    background: #e8e8e8;
    font-weight: 600; }

.settings-drawer__menu {
  overflow: auto;
  height: calc(100vh - 0px);
  flex-grow: 1;
  padding: 32px 20px 80px;
  overscroll-behavior: contain; }


.settings-box__title{
    margin: 0;
    font-size: 16px;
    padding-bottom: 2px;
}
.settings-box__input{
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
}
.settings-box__input:last-child{
    margin-bottom: 0;
}
.settings-box__input_label{
    display: flex;
    align-items: center;

    margin-bottom: 8px;
}
.settings-box__input_info{
    position: relative;
    top: 9px;
    margin-right: 8px;
}
.settings-box__input_info_text{
    margin-left: 8px;
    margin-right: 12px;

    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
}
.settings-box__input_info_subtext{
    margin-left: 9px;
    opacity: 0.6;

    font-size: 12px;
    font-weight: 300;
}

.settings-box__input .ant-input{
    width: 256px;
}
.settings-box__input .full-width-input .ant-input{
    width: 100%;
}
.settings-box__input_box.full-width {
    width: 100%;
}
.settings-box__input .settings-box__input_box.full-width .ant-input,
.settings-box__input .settings-box__input_box.full-width .ant-input-number {
  width: 100%;
}
.settings-box__input_box--large .ant-input{
    width: 100%;
}
.settings-box__form{
    display: flex;
    align-items: flex-start;
}
.settings-box__form_save{
    /*Shameful centering*/
    position: relative;
    bottom: 1px;
    flex-shrink: 0;

    /*margin-left: 15px;*/
}
.settings-box__input_box{
    /*because save button on the right*/
    margin-right: 15px;
}
.settings-box__input_box--large{
    width: 100%;
}
.settings-box__error_message{
    max-width: 400px;
    margin-top: 3px;

    color: var(--blood);

    transition: opacity 0.3s ease;
}

.settings-box__palettes{
}
.settings-box__features_list{
  list-style: none;
}
.settings-box__features_list_item{
  margin-bottom: 5px;
}
.settings-box__features_list_item:last-child{
  margin-bottom: 0;
}
.settings-box__features_list .anticon{
  margin-right: 5px;
}
.settings-box__shared_website_restriction_box{
  border-radius: 4px;
  padding: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--main-bg-gray);
}
.settings-box__extra-button {
    margin-top: 15px;
}

.subdomain-select .ant-select-selection {
  border-radius: 0 4px 4px 0;
  border-left: none;
}

.color-palette-item{
    display: inline-block;

    vertical-align: top;

    margin-bottom: 60px;
    margin-right: 30px;
    width: 160px;

    text-align: center;

    cursor: pointer;
}
.color-palette-item:hover .color-palette-item__card{
    transform: translateY(-5px);
}
.color-palette-item__card{
    position: relative;
    overflow: hidden;
    z-index: 10;

    padding: 25px 15px 20px;
    margin-bottom: 20px;
    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    background-color: white;

    transition: box-shadow 0.25s ease, transform 0.25s ease;
}

.color-palette-item__card_overlay{
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    margin: auto;


    font-size: 14px;

    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;

    transition: opacity 0.4s ease;
}
.color-palette-item__card_overlay--visible,
.color-palette-item__card_overlay--spinner-visible{
    opacity: 1;
}

.color-palette-item__spinner{
    opacity: 0;
}
.color-palette-item__card_overlay--spinner-visible .color-palette-item__spinner{
    opacity: 1;
}

.color-palette-item:hover .color-palette-item__card{
    box-shadow: var(--card-shadow);
}
.color-palette-item__card__example_piece{
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.color-palette-item__card__example_piece .isvg.loaded{
    /*.isvg.loaded - classes of the SVG module which loads svg from file to inline */
    margin-right: 0;
    margin-left: 10px;
}

.color-palette-item__card__example_piece:last-child{
    margin-bottom: 0;
}
.color-palette-item__button_box{
    position: relative;
}
.color-palette-item__button_box::after{
    content: "";
    position: absolute;

    width: 1px;
    height: 30px;
    background-color: var(--piter);
    top: 0;
    transform: translateY(-100%);
    left: 0;
    right: 0;
    margin: auto;
}
.color-palette-item__button{
    display: inline-block;
    position: relative;

    padding: 4px 10px;
    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    background-color: white;
    margin-right: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

.color-palette-item--active .color-palette-item__button{
    border-color: var(--ocean);
}
.color-palette-item--active .color-palette-item__button_box::after{
    background-color: var(--ocean);
}
.color-palette-item--active .color-palette-item__card{
    border-color: var(--ocean);
}
.color-palette-item--active .color-palette-item__button{
    color: var(--ocean);
}

.font-option-item{
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 15px 20px;
    margin-bottom: 30px;

    width: 350px;

    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    cursor: pointer;

    text-align: left;

    transition: box-shadow 0.25s ease, transform 0.25s ease;
}
.font-option-item:hover:not(.font-option-item--active){
    box-shadow: var(--card-shadow);
    transform: translateY(-5px);
}
.font-option-item--active{
    border-color: var(--ocean);
    cursor: default;
}

.font-option-item__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.font-option-item--active .font-option-item__examples{
    color: var(--ocean);
}

.font-option-item__examples.font-lato{
    font-family: 'Lato', sans-serif !important;
}
.font-option-item__examples.font-work-sans{
    font-family: 'Work Sans', sans-serif !important;
}
.font-option-item__examples.font-source-code-pro{
    font-family: 'Source Code Pro', monospace !important;
}

.font-option-item__examples{
    padding-right: 20px;

    border-right: 1px solid var(--piter);
}
.font-option-item__title_example{
    margin-bottom: 5px;
    margin-top: 5px;

    font-size: 40px;
    color: inherit;
}
.font-option-item__text_example{
    margin-bottom: 10px;

    font-size: 20px;
}
.font-option-item__numbers_example{
    font-size: 14px;
}

.font-option-item__title_example,
.font-option-item__text_example,
.font-option-item__numbers_example{
    transition: color 0.25s ease;
}


.font-option-item__info{
    padding-left: 20px;
    font-size: 14px;
}


.font-option-item__card_overlay{
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    margin: auto;

    font-size: 14px;

    background-color: rgba(255, 255, 255, 0.85);
    opacity: 0;

    transition: opacity 0.4s ease;
}
.font-option-item__card_overlay--visible, .font-option-item__card_overlay--spinner-visible{
    opacity: 1;
}

.font-option-item__spinner{
    opacity: 0;
}
.font-option-item__card_overlay--spinner-visible .font-option-item__spinner{
    opacity: 1;
}

.favicon-updated__existing_icon{
    display: inline-block;

    /*border: 1px solid var(--piter);*/
    /*padding: 5px 20px;*/
    /**/
    /*width: 150px;*/
    /*padding-left: 10px;*/
    /*border-radius: 4px;*/
}

.favicon-updated__current_icon{
    max-width: 16px;
    max-height: 16px;
    vertical-align: middle;
}
.favicon-updated__delete_button{
    vertical-align: middle;

    margin-left: 10px;
}
.favicon-updated__deleting_text{
    vertical-align: middle;

    margin-left: 10px;

    opacity: 0.6;
    color: var(--ocean);
}
.image-picker{
    display: inline-block;
    position: relative;

    padding: 20px 5px 10px;
    border: 1px solid var(--main-bg-gray);
    border-radius: 2px;

    min-width: 200px;
    text-align: center;
    border-radius: 8px;
}
.image-picker__img{
    max-width: 100%;
    max-height: 200px;

    transition: opacity 0.25s ease, transform 0.4s ease;
}

.image-picker:hover .image-picker__img{
    opacity: 0.7;
    transform: scale(0.97);
}

.image-picker__controls_box{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255,255,255, 0.85);

    /*transform: scale(0.95);*/
    opacity: 0;
    transition: opacity 0.25s ease, transform 0.25s ease;
}

.image-picker:hover .image-picker__controls_box{
    transform: none;
    opacity: 1;
}

.image-picker__controls{
    transform: translateY(-36px); /*because when an image is small the alt text input overlaps the controls*/
}
.image-picker__controls--small{
    transform: translateY(-17px); /*because when an image is small the alt text input overlaps the controls*/
}
.image-picker__button{
    margin: 0 5px;
}

.image-picker__alt_box{
    margin-top: 15px;
    width: 100%;
    position: relative;
    z-index: 100;
}

.image-picker__filepond .filepond--wrapper {
  width: 100%;
}
.image-picker__filepond .filepond--root {
  margin-bottom: 0;
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  transition: all 0.3s ease;
}
.image-picker__filepond .filepond--drop-label {
  background-color: #FBFBFB !important;
  font-size: 14px;
  color: #595959;
}

.image-picker__filepond .filepond--root:hover {
  cursor: pointer;
  border-color: #40A9FF;
}

.image-picker__filepond .filepond--drop-label.filepond--drop-label label {
  cursor: pointer;
  background-color: #1890FF;
  border-radius: 6px;
  color: #fff;
  padding: 5px 12px;
  transition: background-color 0.3s ease;
}
.image-picker__filepond .filepond--root:hover .filepond--drop-label.filepond--drop-label label {
  background-color: #40A9FF;
}

.image-picker__filepond .filepond--panel-root {
  background-color: #FBFBFB !important;
  border-radius: 8px;
}

.image-picker__filepond .filepond--root {
  border-radius: 8px;
  overflow: hidden;
}

.image-picker__filepond .filepond--root,
.image-picker__filepond .filepond--root .filepond--drop-label {
  min-height: 100px;
}

.image-picker__filepond .filepond--item[data-filepond-item-state="load-invalid"] {
  top: 11px;
}

.opengraph__existing_icon{
    display: inline-block;

    /*border: 1px solid var(--piter);*/
    /*padding: 5px 20px;*/
    /**/
    /*width: 150px;*/
    /*padding-left: 10px;*/
    /*border-radius: 4px;*/
}

.opengraph__current_icon_box{
    position: relative;
    max-width: 500px;
    vertical-align: middle;

    box-shadow: var(--card-shadow);
}
.opengraph__mockup{
    width: 100%;
    height: auto;
}
.opengraph__current_icon{
    position: absolute;
    top: 95px;
    left: 27px;
    width: 372px;
    height: 217px;

    border: 2px solid #ececec;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}
.opengraph__current_overlay{
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255,255,255,0.8);
    transition: opacity 0.2s ease;
}
.opengraph__current_icon:hover .opengraph__current_overlay,
.opengraph__current_overlay--visible{
    opacity: 1;
}

/*opengraph__current_overlay*/
.opengraph__deleting_text{
    vertical-align: middle;

    margin-left: 10px;

    /*opacity: 0.6;*/
    color: var(--ocean);
}

.social-preview {
  box-shadow: var(--card-bordered-shadow);
  border-radius: 12px;
  overflow: hidden; }

.social-preview__image-box {
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  width: 100%;
  height: 200px; }
  .social-preview__image-box.is-loading:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(237, 237, 237, 0.7) 25%, rgba(255, 255, 255, 0.7) 35%, rgba(237, 237, 237, 0.7) 45%);
    background-size: 400% 100%;
    z-index: 1;
    -webkit-animation: ant-skeleton-loading 1.7s ease infinite;
            animation: ant-skeleton-loading 1.7s ease infinite; }
  .social-preview__image-box.is-empty {
    display: none; }
  .social-preview__image-box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s; }
  .social-preview__image-box:hover:before {
    opacity: 1; }

.social-preview__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top; }

.social-preview__text-box {
  padding: 12px;
  display: flex;
  flex-direction: column;
  grid-gap: 2px;
  gap: 2px; }

.social-preview__title {
  margin: 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; }

.social-preview__subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.social-preview__url {
  color: var(--text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.social-preview__delete-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0;
  transition: all 0.2s; }

.social-preview__image-box:hover
.social-preview__delete-button {
  opacity: 1; }

.html-export{

}
.html-export__buttons_list{
    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: column;
}

.html-export__button_item{
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 10px;
    width: auto;
    grid-gap: 2px;
    gap: 2px;
}
.html-export__button_item > .ant-btn {
    flex-shrink: 0;
}
.html-export__page_title_text{
    position: relative;
    padding-right: 10px;
}

.html-export__button_item:last-child{
    margin-bottom: 0;
}
.html-export__link{
    position: absolute;
    opacity: 0;
    right: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translateX(5px);

    transition: opacity 0.25s ease, transform 0.25s ease;
}
.html-export__button_item:hover .html-export__link{
    opacity: 1;
    transform: translateX(0);
}

.html-export__page_title{
    margin-left: 10px;
}
.html-export__page_title_prefix{
    font-size: 14px;
}
.html-export__big_title{
    position: relative;
}
.html-export__big_title:after{
    content: "";

    position: absolute;
    background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    right: -2px;
    height: 17px;
    width: 40px;
}
.html-export__assets{
}
.html-export__step{
    margin-bottom: 40px;
}
.html-export__step:last-child {
  margin-bottom: 0;
}
.html-export__step_info{
    display: block;
    margin-bottom: 15px;
}
.html-export__step_image{
    float: right;
    width: 205px;
}

.custom-color-palette{
    display: inline-block;

    vertical-align: top;

    margin-bottom: 60px;

    text-align: center;
}
.custom-color-palette:not(.custom-color-palette--active){
    margin-right: 30px;
}
/*.custom-color-palette:hover .custom-color-palette__card{*/
    /*transform: translateY(-5px);*/
/*}*/
.custom-color-palette__card{
    position: relative;
    overflow: hidden;
    z-index: 10;

    min-height: 206px;
    padding: 20px 20px 20px 15px;
    margin-bottom: 20px;
    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    background-color: white;

    text-align: left;

    /*transition: box-shadow 0.25s ease, transform 0.25s ease;*/
}

.custom-color-palette__button_box{
    position: relative;
}
.custom-color-palette__button_box::after{
    content: "";
    position: absolute;

    width: 1px;
    height: 30px;
    background-color: var(--piter);
    top: 0;
    transform: translateY(-100%);
    left: 0;
    right: 0;
    margin: auto;
}
.custom-color-palette__button{
    display: inline-block;
    position: relative;

    padding: 4px 10px;
    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);
    background-color: white;
    margin-right: 0;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

.custom-color-palette--active .custom-color-palette__button{
    border-color: var(--ocean);
}
.custom-color-palette--active .custom-color-palette__button_box::after{
    background-color: var(--ocean);
}
.custom-color-palette--active .custom-color-palette__card{
    border-color: var(--ocean);
}
.custom-color-palette--active .custom-color-palette__button{
    color: var(--ocean);
}
.custom-color-palette__inputs_label{
    display: inline-block;
    width: 170px;
    margin-left: 7px;

    font-size: 14px;

    transition: opacity 0.4s ease;
}
.settings-box__input .custom-color-palette__input{
    width: 80px;
}
.custom-color-palette--disabled .custom-color-palette__inputs_label{
    opacity: 0.5;
}

.custom-color-palette__activator{
    margin-bottom: 20px;
}

.custom-color-palette--disabled .custom-color-palette__inputs_row{
    cursor: not-allowed;
}

.custom-color-palette__inputs_row{
    margin-bottom: 20px;
}
.custom-color-palette__inputs_row:last-child{
    margin-bottom: 0;
}

/*.custom-color-palette__inputs_box{*/
    /*width: 290px;*/
/*}*/


.custom-color-palette__wrapper{
    display: flex;
    justify-content: space-between;
}


.custom-color-palette__example_box{
    width: 180px;
    margin-left: 10px;
}
@media (max-width: 1100px){
	.custom-color-palette__example_box{
        display: none;
    }
}

.custom-color-palette__example{
    height: 160px;
}
.custom-color-palette__example_body{
    /*background-color: red;*/
    border: 1px solid var(--piter);
    position: relative !important; /*because page-component__bg_overlay_box is pos:absolute*/
    height: 100%;

    display: flex;
    align-items: center;

    transition: background-color 0.2s ease;
}
.custom-color-palette__example_info{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    transform: translateY(-50%);
    text-align: center;
}
.custom-color-palette__example_info_text{
    display: inline-block;
    text-transform: uppercase;
    border-radius: 99px;
    border: 1px solid var(--piter);
    background-color: white;
    font-size: 10px;
    padding: 3px 8px;
}
.custom-color-palette__example_center{
    height: 80px;
    width: 100%;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-color-palette__example_buttons{
    display: flex;
    justify-content: center;
    align-items: center;

    transform: scale(0.6);
    transform-origin: center;
}
.custom-color-palette__example_button{
    margin-left: 9px;
    margin-right: 9px;

    /*height: 24px;*/
    /*width: 70px;*/

    /*border-radius: 3px;*/


}
.custom-color-palette__example_button--bg{

}
.custom-color-palette__example_button--alt-outline{

}

.custom-fonts{
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 15px 20px;
    margin-bottom: 30px;

    width: 350px;
    min-height: 155px;

    border: 1px solid var(--piter);
    border-radius: var(--main-border-radius);

    transition: border-color 0.25s ease;
    z-index: 20; /*so custom fonts dropdown can overlap colors*/
}
.custom-fonts--active{
    border-color: var(--ocean);
}
.custom-fonts__button_wrapper{
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-fonts__divider{
    border-top: 1px solid var(--piter);
    border-bottom: none;
    width: 100%;
    margin: 15px 0;
}
.custom-fonts__items_box{
    height: 123px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-flow: column;
}
.custom-fonts__title_box{

}
.custom-fonts__title{
    font-size: 22px;
    margin-right: 15px;
    vertical-align: middle;
    font-weight: 700;
}
.custom-fonts__text_box{

}
.custom-fonts__text{
    margin-right: 15px;
    display: inline-block;
    vertical-align: middle;
}
.custom-fonts__text_item{

}
.custom-fonts__button_wrapper{
    height: 123px;
}
.custom-fonts__picker{
    display: inline-block;
    vertical-align: middle;
}
.custom-fonts__save_button_box{
    text-align: center;
    margin-top: 10px;
}

.website-seo-settings{

}

.website-seo-settings__url{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.website-seo-settings__url_text{
    transition: opacity 0.2s ease;
}
.website-seo-settings__url_text--opacity{
    opacity: 0.4;
}
.website-seo-settings__disallow{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.website-redirects{

}
.website-redirects__item{
    margin-bottom: 15px;
}
.website-redirects__item:last-child{
    margin-bottom: 0;
}
.website-redirects__items{
    margin-bottom: 15px;
}

.website-redirects__form{
    display: flex;

    align-items: center;
}
.website-redirects__inputs{
    position: relative; /*for del button*/
    display: flex;

    width: 100%;
    max-width: 650px;

    align-items: center;
    justify-content: flex-start;

}
.website-redirects__input{
}
.website-redirects__input .ant-input{
    width: 100%;
}
.website-redirects__input--from{
    /*margin-right: 20px;*/
}
.website-redirects__inputs_divider{
    margin-left: 10px;
    margin-right: 10px;
    opacity: 0.4;
}
.website-redirects__input--to{
    margin-right: 15px;
}

.website-redirects__item:hover .website-redirects__age{
    opacity: 0.6;
}
.website-redirects__age{
    margin-top: 3px;

    font-size: 10px;
    opacity: 0;
    transition: 0.2s ease opacity;
}
.website-redirects__item:hover .website-redirects__delete{
    opacity: 1;
}
.website-redirects__delete{
    z-index: 10;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%) scale(0.7);

    opacity: 0;
    transition: 0.2s ease opacity;
}

.my-button{
    /*we need to keep the shape of the box even when the button is hidden*/
    height: 32px;
    width: 32px;
    position: relative;
}
.my-button_tick{
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    text-align: center;

    margin: auto;

    z-index: 100;

    font-size: 30px;

    -webkit-animation: move_up_and_disappear 1s ease-out forwards;

            animation: move_up_and_disappear 1s ease-out forwards;
}

@-webkit-keyframes move_up_and_disappear {
    0%{

    }
    50%{
        opacity: 1;
    }
    90%{
        transform: translateY(-30px);
    }
    100%{
        opacity: 0;
    }
}

@keyframes move_up_and_disappear {
    0%{

    }
    50%{
        opacity: 1;
    }
    90%{
        transform: translateY(-30px);
    }
    100%{
        opacity: 0;
    }
}
.website-languages__items_box{
    /*width: 760px;*/
    display: flex;
    justify-content: space-between;
}
.website-languages__title{
    /*white-space: nowrap;*/
    display: block;
    /*margin-right: 20px;*/
    margin-bottom: 5px;
    color: var(--ant);
}
.website-languages__box{
    /*display: flex;*/
    align-items: flex-start;
    justify-content: space-between;
    width: 455px;
}
.website-languages__items_box{
  flex-direction: column;
  grid-row-gap: 20px;
  row-gap: 20px;
}
.website-languages__version{
    position: relative;
    display: flex;
    margin-bottom: 15px;
}

.website-languages__language_dropdown{
    margin-right: 5px;
}
.website-languages__current_website_input{

}
.website-languages__select_website{

}
.website-languages__version_form{
    position: relative;
}
.website-languages__version:hover .website-languages__delete{
    opacity: 1;
}
.website-languages__delete{
    z-index: 10;
    position: relative;
    right: 11px;
    top: 5px;
    transform: scale(0.7);

    opacity: 0;
    transition: 0.2s ease opacity;
}
.website-languages__age{
    margin-top: 3px;

    font-size: 10px;
    opacity: 0;
    transition: 0.2s ease opacity;
}
.website-languages__version_form:hover .website-languages__age{
    opacity: 0.6;
}

.edit-multi-user-access__form_box{
  display: flex;
  width: 310px; /*because .settings-box__input .ant-input has width 310px*/
}
.edit-multi-user-access__form{
  display: flex;
}

.edit-multi-user-access__users{
  width: 310px; /*because .settings-box__input .ant-input has width 310px*/
  margin-top: 25px;
}

.multi_user_access_item{
  display: flex;
  margin-bottom: 15px;
}


.website_widgets__about {
    padding-left: 20px;
}

.website_widgets__info {
    margin-top: 10px;
}

.website_widgets__preview {
    height: 100%;
}

.website_widgets__preview_box {
    position: relative;
    height: 250px;
    background-position: center;
    background-size: cover;

    box-shadow: var(--card-shadow);
    border-radius: 10px;
    overflow: hidden;
}

.website_widgets__preview_link {
    text-align: center;
    margin-top: 20px;
}

.website_widgets__preview_box .simple-cookie-widget {
    position: absolute;
    transform: scale(0.9);
}

.website_widgets__preview_box .lang-widget {
    position: absolute;
}

.website_widgets__redo_button {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 999;
    bottom: 0;
    margin: auto;
    transform: translateX(-50%);
}

.website_widgets__title {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-sitemap-url-settings__item {
  display: flex;
  grid-gap: 15px;
  gap: 15px; }

.custom-sitemap-url-settings__item:hover .custom-sitemap-url-settings__delete {
  opacity: 1; }

.ant-btn.custom-sitemap-url-settings__delete {
  z-index: 10;
  position: absolute;
  right: -11px;
  top: 4px;
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.2s; }

.custom-sitemap-url-settings__item .ant-input {
  width: 100%; }

.custom-sitemap-url-settings__loader .ant-skeleton-paragraph {
  margin: 0; }

.custom-sitemap-url-settings__loader .ant-skeleton-content li {
  height: 30px;
  border-radius: 4px; }

.custom-sitemap-url-settings__loader .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 15px; }

.style-menu__preview .text-input,
.style-menu__preview .button,
.style-menu__preview .button * {
  transition: all 0.3s ease, color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease !important; }

.style-menu__text-preview h1,
.style-menu__text-preview h2,
.style-menu__text-preview h3,
.style-menu__text-preview p {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  white-space: nowrap !important;
  transition: all 0.3s ease !important; }

.style-menu__text-preview .heading-example-h3 {
  font-size: 24px; }

.example-card {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2);
  background: #fff;
  padding: 20px 20px 25px 25px;
  max-width: 100%;
  overflow: hidden; }
  .example-card .title-text--inner {
    font-size: 20px;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    white-space: nowrap !important; }
  .example-card .content-text {
    margin: 0 !important;
    white-space: nowrap !important; }

.vert-space-example {
  padding-top: var(--extra-vertical-space) !important;
  padding-bottom: var(--extra-vertical-space) !important;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  flex-wrap: wrap;
  max-width: 150px;
  justify-content: center; }

.space-example-card {
  width: 50px;
  height: 60px;
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15);
  background: #fff;
  border-radius: 10px; }

.custom-domain-settings {
  max-width: 600px;
}
.custom-domain-settings__steps-content {
  margin-top: 30px;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 30px;
}
.custom-domain-settings__steps-action {
  margin-top: 30px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.custom-domain-settings__title {
  margin-top: 0;
  display: flex;
  align-items: center;
}
.custom-domain-settings__content {
  margin-top: 10px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.custom-domain-settings__buttons-box {
  margin-top: 20px;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-end
}
.custom-domain-settings__subtitle {
  margin: 0;
}
.custom-domain-settings__title-icon {
  font-size: 14px;
}
.settings-box__input .custom-domain-settings__content .ant-input {
  width: 100%;
}
.custom-domain-settings__content .ant-alert-message {
  margin-bottom: 0.5em;
}

.custom-domain-settings .settings-box__form {
  flex-grow: 1;
}

.dns-table td {
  max-width: 150px; }

.transfer-domain-menu p {
  margin: 0; }

.transfer-domain-menu .ant-steps {
  display: none; }

.google-preview {
  padding: 12px 16px;
  border-radius: 12px;
  font-family: arial, sans-serif;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px #e8e8e8; }

.google-preview__title {
  color: #1b0fab;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
  word-wrap: break-word; }

.google-preview__url {
  font-size: 12px;
  max-width: 88%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.google-preview__description {
  word-wrap: break-word; }

.update-api-source-form__validation-message-container {
  margin-top: 10px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; }
  .update-api-source-form__validation-message-container div.ant-typography, .update-api-source-form__validation-message-container .ant-typography p {
    margin-bottom: 10px; }
  .update-api-source-form__validation-message-container h4 {
    margin: 0 0 6px 0;
    font-size: 14px;
    line-height: 22px; }
  .update-api-source-form__validation-message-container img {
    max-width: 100%;
    border-radius: 4px; }

.cms-menu {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px; }
  .cms-menu.is-no-inputs {
    grid-gap: 12px;
    gap: 12px; }
  .cms-menu .ant-select {
    width: 270px; }
  .cms-menu .ant-input {
    width: 100%; }
  .cms-menu__dropdown-render .ant-select-dropdown-menu {
    max-height: 180px; }
  .cms-menu__dropdown-render .nav-dropdown__cta-container {
    position: static; }
  .cms-menu .ant-select-selection__clear {
    color: rgba(0, 0, 0, 0.5);
    transform: scale(1.25); }
    .cms-menu .ant-select-selection__clear:hover {
      color: rgba(0, 0, 0, 0.8) !important; }

.account-settings{
}
.account-settings__menu_box{
    text-align: center;
}
.account-settings__wrapper{
    padding: 0px 20px 60px;
    background-color: white;
    border-radius: 10px;
    box-shadow: var(--card-shadow);
}

.account-settings__item{
    margin-bottom: 60px;
}
.account-settings__user_email{
    font-weight: 400;
    border-radius: 999px;
    background-color: var(--main-bg-gray);
    padding: 5px 15px;
    font-size: 14px;
}
.account-settings__header{
  margin-bottom: 40px;
}
.plans__box{
  overflow: auto;
}
.plans__bonus{
    margin-left: 15px;
    cursor: default;
}
.plans__switch{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.plans__payment{
    margin-top: 40px;
    margin-bottom: 40px;

    text-align: center;
}
.plans__corporate_link{
    display: inline-block;

    margin-left: 30px;
}

.plans__corporate{
    text-align: center;
}
.plans__corporate__box{
    padding: 15px 20px;

    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    border: 1px solid var(--cloud);
    border-radius: var(--main-border-radius);
}
.plans__corporate__text{
    /*margin-right: 70px;*/

    max-width: 500px;
    text-align: left;
}
.plans__wrapper{
  display: flex;
  border-radius: 10px;
  border: 1px solid var(--cloud);

  margin-top: 30px;

  min-width: 1230px;
}
.plans__features{
  width: 232px;
  margin-top: 172px; /*the amount of the pricing tier top box (with a price)*/
}
.plans__feature_item{
  border-top: 1px solid var(--cloud);
  padding: 16px 0 16px 16px;
}
.plans__feature_item_icon{
  margin-left: 5px;
}
.plans__feature_item_tag {
  position: absolute;
  margin-left: 10px;
  cursor: default;
}


.plans__banner_box{
    position: relative;

    width: 520px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    background-color: #2F22DC;
    border-radius: 10px;
    padding: 20px;
    border: 6px solid #ffcc4d;
}
.plans__banner_emoji{
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    width: 30px;
    margin: auto;

    box-shadow: 0 5px 12px 2px rgba(0,0,0,0.3);
    border-radius: 50%;
}
.plans__banner_title{
    display: inline-block;

    margin-bottom: 10px;

    font-size: 18px;
}
.plans__banner_text{
    max-width: 270px;
    text-align: left;
    color: #fff;
}
.plans__banner_bar{
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.plans__banner_bar .ant-progress-circle {
    box-shadow: 0 5px 12px 2px rgba(0,0,0,0.2);
    border-radius: 50%;
}
.plans__banner_bar .ant-progress-circle .ant-progress-text{
    color: #fff;
    font-size: 16px;
}
.plans__banner_text p:last-child{
    margin-bottom: 0;
}
.plans__top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.plans__feature_item:nth-child(even),
.plan__feature:nth-child(even){
  background-color: #f9f9f9;
}
.plans__feature_item:last-child,
.plan__feature:last-child{
  /*border-radius: 0 0 10px 10px;*/
}

.plan {
  width: 150px;

  text-align: center;
}
.plan__card {
  position: relative;
}
.plan__card_users{
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;
}
.plan__card_users_avatars{
  vertical-align: top;
  transform: translateY(-50%);
  text-align: center;
}
.plan__card_users_avatar{
  border: 2px solid var(--ant);
  background-color: var(--ant);
  border-radius: 50%;
  position: relative;
}
.plan__card_users_avatar:last-child{
  right: 5px;
}
.plan__card_users_avatar:first-child{
  left: 5px;
}
.plan__card_users_avatar--mid{
  z-index: 10;
}
.plan__card--focus .plan__top{
background-color: var(--ant);
border-radius: 10px 10px 0 0;
}
.plan__card--focus .plan__top .plan__card_info,
.plan__card--focus .plan__top .plan__price,
.plan__card--focus .plan__top .plan__title{
  color: white;
}
.plan__top {
  height: 173px;
  border-bottom: 1px solid var(--cloud);
}
.plan__price_wrapper {
}
.plan__title {
  font-weight: 500;
  margin-top: 24px;
  display: inline-block;
}
.plan__price_box {
  position: relative;
}
.plan__description{
  position: relative;
}
.plan__period{
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 105px;
  display: none;
}
.plan__feature{
  padding: 16px;
  border-bottom: 1px solid var(--cloud);
}
.plan__feature_button{
    border: none;
    height: auto;
    box-shadow: none;
}
.plan__feature:last-child{
  border: none;
}
.plan__card--focus .plan__feature{
  border-left: 1px solid var(--ant);
  border-right: 1px solid var(--ant);
}
.plan__card--focus .plan__feature:last-child{
  position: relative;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 1px var(--ant);
}
.plan__feature:first-child{
  font-weight: 700;
}
.plan:hover .plan__period{
  display: block;
}
.plan:hover .plan__description_info{
  visibility: hidden;
}
.plan__card_info{
  color: var(--concrete);
  font-size: 12px;
}
.plan__price {
  position: relative;
  font-size: 32px;
  color: var(--darkness);
  font-weight: 700;
}
.plan__price:not(.plan__price--no-info)::before,
.plan__price:not(.plan__price--no-info)::after {
  position: absolute;
  font-size: 12px;
  font-weight: 400;
}
.plan__price:not(.plan__price--no-info)::before{
  content: '$';
  transform: translateX(-10px) translateY(10px);
}
.plan__price:not(.plan__price--no-info)::after {
  content: "/mo";
  transform: translateX(5px) translateY(20px);
}
.plan__features_list {
}
.plan__feature {
}
.plan__feature:nth-child(3),
.plan__feature:nth-child(4),
.plan__feature:nth-child(7) {
}
.plan__feature:last-child {
}
.plan__current {
}

.plan__cta_box {
  margin-top: 7px;
}
.plan__loader {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 300px;
}
.plan__feature_icon {
}


.plan__card_users_faces_box{
  display: flex;
  justify-content: flex-start;
  padding: 5px 0 15px;
  border-bottom: 1px solid #E5E5E5;
}
.plan__card_users_face{
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
}
.plan__card_users_face--1{
  
}
.plan__card_users_face--2{
  transform: translateX(-5px);
}
.plan__card_users_face--3{
  transform: translateX(-10px);
}
.plan__card_users_face--4{
  transform: translateX(-15px);
}
.plan__card_users_face--5{
  transform: translateX(-20px);
}


.plan__card_users_faces_plus{
  background-color: #303030;
  color: white;
  padding: 5px 14px 5px;
  border-radius: 9999px;
  border: 2px solid white;
  margin-right: -25px;
  transform: translateX(-25px);
}
.plan__card_users_info{
  padding: 15px 0 0;
}
.plan__button-group {
  display: flex;
}
.plan__notification-button {
  margin-right: 12px;
}
.plan__notification-button:last-child {
  margin-right: 0;
}
.deals_modal__items_box {
    /*text-align: center;*/
}

.deals_modal__item {
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 40px;
    width: 285px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--card-shadow);
    vertical-align: top;
    text-align: left;
}

.deals_modal__item_top {
    background-color: white;
    padding: 24px;
}

.deals_modal__item_logo {
    max-height: 34px;
}

.deals_modal__item_body {
    padding: 14px 24px 20px;
}

.deals_modal__item_info {

}

.deals_modal__item_title {
    font-size: 20px;
    color: inherit;
}

.deals_modal__item_description {
    color: inherit;
}

.deals_modal__item_action {

}

.deals_modal__item_code_box {
    margin-bottom: 20px;
}

.deals_modal__item_code {
    -webkit-user-select: all;
        -ms-user-select: all;
            user-select: all;
}

.deals_modal__hidden {
    -webkit-filter: blur(5px);
            filter: blur(5px);
}

.deals_modal__item_project_url {
    border: 1px solid white;
}
.cancellation-modal .ant-modal-body {
  padding: 0;
  line-height: 0;
}
.cancellation-modal__form {
  position: relative;
  z-index: 1;
}
.cancellation-modal .spinner_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.5;
}

.cancel-discount-modal .ant-modal-header {
  padding: 20px 24px;
  padding-top: 24px; }

.cancel-discount-modal .ant-modal-close {
  top: 7px; }

.cancel-discount-modal .ant-modal-body {
  padding-top: 18px; }

.payment_info__empty_box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 200px;
  text-align: center;

  margin: auto;
}
.payment_info__empty_text{

}
.payment_info__empty_image{
  height: 70px;
  margin-bottom: 20px;
}

.payment_info__billing{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  padding: 20px 30px;

  border: 1px solid var(--gray);
  border-radius: 4px;

}
.payment_info__method_card_blur{
  -webkit-filter: blur(5px);
          filter: blur(5px);
  opacity: 0.5;
  margin-left: 20px;
  margin-right: 6px;
}
.payment_info__method_card_number{
  font-weight: 700;
}

.payment_info__list_box{
  margin-top: 40px;
  max-width: 800px;
}
.payment_info__skeleton_box{
  /* max-width: 800px; */
  /* padding-top: 20px; */
}
.payment_info__skeleton_box .ant-skeleton-paragraph li:last-child{
  display: none;
}
.payment_info__skeleton_box .ant-skeleton-content .ant-skeleton-paragraph > li{
  height: 56px
}
.payment_info__skeleton_box .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 1px;
}

.account_fields__rows{
  margin-top: 20px;
}
.account_fields__row{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.account_fields__action{
  flex-grow: 1;
  text-align: right;
}
.account_fields__value .anticon {
  color: var(--ant);
}
.account_fields__value_wrapper {
  display: inline-block;
}
.account_fields__icon {
  margin-left: 5px;
}
.account_fields__icon.account_fields__icon--warning {
  color: var(--orange);
}
.account_fields__warning_text {
  max-width: 500px;
  margin-bottom: 20px;
}
.account_fields__second_warning_text {
  margin-bottom: 24px;
}
.account_fields__value {
  color: rgba(0,0,0,0.45);
  font-size: 12px;
  margin-top: 2px;
  max-width: 325px;
}

.application_menu__table {
  max-width: 800px;
}
.application_menu__logo {
  max-height: 24px;
  margin-right: 12px;
}
.application_menu__title--apps {
  display: flex;
  align-items: center;
}
.application_menu__confirm {
  max-width: 300px;
}
.applications_menu__empty_image {
  margin: 32px 0;
}
.applications_menu__empty_image .ant-empty-description{
  max-width: 320px;
  margin: 0 auto;
}

.settings__wrapper{
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: var(--card-shadow);
}
.settings__left{
    width: 250px;
    padding: 25px;

    border-right: 1px solid var(--cloud);
}
.settings__right{
    width: calc(100% - 250px);
}
.settings__container{
    transform: translateZ(0);
}
@media (max-width: 950px){
	.settings__left{
        display: none;
    }
    .settings__right{
        width: 100%;
    }
}

.settings__tab{
    /*text-align: center;*/
    font-weight: 500;

}
.settings__tab_hints{
    line-height: 1;
    margin-top: 3px;
    font-weight: 400;
}
.settings__small{
    font-size: 10px;
    opacity: 0.6;

    margin-right: 5px;
    margin-bottom: 5px;
}
.settings__small:last-child{
    margin-right: 0;
    margin-bottom: 0;
}
.settings__nav .ant-tabs-nav .ant-tabs-tab{
    vertical-align: top;
}
.settings__divider {
  margin: 22px 0 20px;
}

.publish-page-form {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 400px; }

.publish-page-form__inputs {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; }

.publish-page-form__input-box {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row; }

.publish-page-form__label-box {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  flex-shrink: 0;
  margin-top: 4px; }

.publish-page-form__input-container {
  width: 270px; }

.cms-sidebar iframe {
  border: none;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1; }

.cms-sidebar {
  height: 100vh; }

.blog-editor-post-controls{
    list-style: none;

    display: flex;
    margin-bottom: 0;
}


.blog-editor-post-controls__item{
    margin-left: 15px;
}
.blog-editor-post-controls__item:first-child{
    margin-left: 0;
}

.blog-editor-post-controls__input_label{
    display: flex;
    align-items: center;

    margin-bottom: 15px;
}

.blog-editor-post-controls__modal-item{ 
    margin-bottom: 45px;
}

.blog-editor-post-controls__modal .ant-modal-footer{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 999;
    background-color: #fff;
}

.add-page-component .ant-anchor-link-title {
    text-decoration: none !important;
}
.add-page-component .ant-anchor-wrapper {
  padding-bottom: 80px;
}

.add-page-component__menu {
    height: 100%;
    overflow: auto;
}

.add-page-component .ant-anchor-link:nth-of-type(4n) {
    margin-bottom: 20px;
}

.add-page-component .ant-modal-body {
    padding: 0;
}

.add-page-component .ant-layout-sider,
.add-page-component .ant-layout-content {
    height: calc(100vh - 100px);
}

.add-page-component .ant-layout-sider {
    /* DELETE WHEN ADD THE SIDER*/
    /*display: none;*/
    /* DELETE WHEN ADD THE SIDER*/


    background-color: #FAFAFA;
    border-radius: 0 0 0 4px; /*I do not want to apply overflow: hidden; to the whole ant.design modal becuase it may lead to unpredictable bugs*/
}

.add-page-component .ant-anchor-wrapper {
    background-color: transparent;
}

.add-page-component .ant-menu-root {
    padding-top: 10px;
    height: 100%;
}

.add-page-component .ant-layout-content {

    background-color: white;
}

.add-page-component__search-bar-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
  background: #fff;
  margin-left: 200px;
}

@media (max-width: 1200px) {
    /*no sider on smart laptops*/
    .add-page-component .ant-layout-sider {
        display: none;
    }

    .ant-layout-content .add-page-component__container {
        margin-left: 0;
    }

    .ant-layout-content .add-page-component__search-bar-container {
        margin-left: 0;
    }
}

.add-page-component__container {
  padding: 0 20px;
  margin-left: 200px;
}

.add-page-component__menu {
    padding: 0 10px 0px 20px;
    overflow: hidden;
}

.add-page-component .ant-layout-content::-webkit-scrollbar, .add-page-component .ant-anchor-wrapper::-webkit-scrollbar {
    width: 4px;
}

.add-page-component .ant-layout-content::-webkit-scrollbar-track, .add-page-component .ant-anchor-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.add-page-component .ant-layout-content::-webkit-scrollbar-thumb, .add-page-component .ant-anchor-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}



.add-page-component__categories {
    list-style: none;
    padding: 0;
}

.add-page-component__category {

}


.add-page-component__category_title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.add-page-component__info {
    margin-left: 5px;

    font-size: 12px;
    font-weight: 400;

    opacity: 0.6;
}

.add-page-component__components_list {
    list-style: none;
}

.add-page-component__component_item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(33% - 20px);
    margin-right: 16px;
    margin-bottom: 40px;
    font-size: 0;
}

.add-page-component__component_item:nth-child(3n) {
    margin-right: 0;
}

.add-page-component__lock {
    border-radius: 999px;
    background-color: white;
    border: 2px solid var(--night);
    font-size: 12px;
    line-height: 12px;
    padding: 3px 10px;
    width: 50px;
    text-transform: uppercase;
    font-weight: 700;
}

.add-page-component__lock, .add-page-component__premium_mark {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: 10;
}

.add-page-component__premium_mark {
    font-size: 14px;
}

.add-page-component__button {
    position: relative;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px var(--gray);
    padding: 0;
    border-radius: var(--main-border-radius);
    overflow: hidden;
    outline: none;
    z-index: 0;

    transition: box-shadow 0.15s ease, transform 0.15s ease, z-index 0.15s ease;
}
.add-page-component__button:not(.is-loading) {
  cursor: pointer;
}

.add-page-component__thumb {
    width: 100%;
}

.add-page-component__button:not(.is-loading):hover {
    transform: scale(1.25);
    z-index: 10;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}


@media (max-width: 1200px) {
    .add-page-component__button:not(.is-loading):hover {
        transform: translateY(-4px);
        box-shadow: var(--card-shadow), 0 0 0 1px var(--gray);
        z-index: 0;
    }
}

.add-page-component__label {
    position: absolute;
    left: 7px;
    bottom: 7px;
    padding: 1px 4px;
    border-radius: 2px;

    background-color: white;
    font-size: 10px;

    opacity: 0.2;

    transition: opacity 0.25s ease;
}

.add-page-component__description {
    position: absolute;
    left: 0px;
    bottom: 0px;
    font-size: 12px;
    width: 100%;
    color: #fff;
    opacity: 0;
    display: flex;
    align-items: flex-end;
    padding: 12px 12px 12px;
    text-align: left;

    transform: translateY(8px);
    transition: opacity 0.25s ease, transform 0.15s ease;
}
.add-page-component__description::after {
  content: '';
  background: rgba(0,0,0,0.9);
  -webkit-filter: blur(24px);
          filter: blur(24px);
  position: absolute;
  left: -50%;
  bottom: -32px;
  width: 200%;
  height: calc(100% + 32px);
  z-index: -1;
  will-change: filter;
}

.add-page-component__button:not(.is-loading):hover .add-page-component__label {
    opacity: 0.9;
}
.add-page-component__button:not(.is-loading):hover .add-page-component__description {
    opacity: 1;
    transform: translateY(0);
}

.add-page-component__loading-overlay {
  position: absolute;
  left: 0px;
  bottom: 0px;
  font-size: 32px;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7);
  color: var(--ant);
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-height: 900px) {

    .add-page-component .ant-anchor-link {
        padding-bottom: 5px;
        padding-top: 5px;

        /*font-size: 12px;*/
    }
}

@media (max-height: 800px) {
    .add-page-component .ant-anchor-link:nth-of-type(4n) {
        margin-bottom: 10px;
    }
}

@media (max-height: 700px) {
    .add-page-component .ant-anchor-link {
        font-size: 12px;
    }
}

.add-page-component .ant-anchor {
    padding-top: 10px;
}

.add-page-component .ant-anchor-ink::before {
    width: 1px;
    background-color: #e8e8e8;
}

.add-page-component__paste_component_button {
    position: absolute;
    bottom: 20px;
    right: 50px;
    z-index: 11;
}

.add-page-component__search-bar-container .add-page-component__search-bar-input.ant-input-affix-wrapper .ant-input {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgb(232, 232, 232);
  border-color: rgb(232, 232, 232);
  height: 48px;
  padding-left: 40px;
}
.add-page-component__search-bar-input .ant-input:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) inset;
}
.add-page-component__search-bar-input .ant-input-prefix {
  left: 20px; 
}

.editor{
    padding: 50px 20px 250px;
    min-height: 100vh;
}
.editor__wrapper{
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    /* box-shadow: var(--card-shadow); */
    /*overflow: hidden;*/ /*it looks slightly better with overflow: hidden but we can not use position:sticky for a fixed nav*/
    background-color: white;
    box-shadow: 0px -1.46302px 7.24861px 0px rgba(0, 0, 0, 0.07), 0px -4.91399px 24.34658px 0px rgba(0, 0, 0, 0.04), 0px -22px 109px 0px rgba(0, 0, 0, 0.03);
}

.editor__add_button{
    height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.editor__add_button_box{
    text-align: center;
}
.editor__add_button_info{
    margin-top: 10px;
    max-width: 300px;
}
.editor__components{
    padding: 0;
    margin: 0;

    background-color: white;
}
.editor__component{
    list-style: none;
}
.editor__save_button_box{
    position: fixed;
    z-index: 1020;
    bottom: 28px;
    left: 50%;
    transform: translateX(-50%);

    border-radius: 2px;

}
.editor__save_button_box--accent{
    box-shadow: 0 0 0 rgba(204, 99, 91, 0.8);
    -webkit-animation: pulsation 2s infinite;
            animation: pulsation 2s infinite;
}
@-webkit-keyframes pulsation {
    0% {
        box-shadow: 0 0 0 0 rgba(204, 99, 91, 0.8);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204, 99, 91, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204, 99, 91, 0);
    }
}
@keyframes pulsation {
    0% {
        box-shadow: 0 0 0 0 rgba(204, 99, 91, 0.8);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204, 99, 91, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204, 99, 91, 0);
    }
}


.editor__templates_nav{
    position: absolute;
    width: 100%;
    z-index: 200;
}
.editor__templates_nav--static{
    background-color: #fff;
    position: static;
}
.editor__templates_nav--sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
}

.editor__templates_nav--static .nav-02{
    /* Nav is static when the first component is not header. If header - it is position:absolute */
    position: relative;
}
.comps .custom_fonts .editor__nav_edit_control * {
  font-family: -apple-system, "system-ui", "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.editor__nav_edit_control, .editor__footer_edit_control{
    position: absolute;
    opacity: 0;
    right: 20px;
    top: 0;
    z-index: 500;
    transition: opacity .15s ease;

    /*padding: 15px 30px;*/
}

.editor__templates_nav:hover .editor__nav_edit_control,
.editor__templates_nav.editor__templates_nav--force-visible-controls .editor__nav_edit_control {
    opacity: 1;
}
.editor__templates_footer{
    position: relative;
}
.editor__templates_footer:hover .editor__footer_edit_control,
.editor__templates_footer.editor__templates_footer--force-visible-controls .editor__footer_edit_control {
    opacity: 1;
}

.editor__designed_with{
    margin-top: 50px;
    text-align: center;
    opacity: 0.3;
    text-shadow: 0 1px 1px #fff;

    font-size: 12px;
}



.editor__save_button_tick{
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    text-align: center;

    margin: auto;

    z-index: 100;

    font-size: 30px;

    -webkit-animation: move_up_and_hide 1s ease-out forwards;

            animation: move_up_and_hide 1s ease-out forwards;
}

@-webkit-keyframes move_up_and_hide {
    0%{

    }
    50%{
        opacity: 1;
    }
    90%{
        transform: translateY(-30px);
    }
    100%{
        opacity: 0;
    }
}

@keyframes move_up_and_hide {
    0%{

    }
    50%{
        opacity: 1;
    }
    90%{
        transform: translateY(-30px);
    }
    100%{
        opacity: 0;
    }
}

.page-component{
    position: relative;
}
.page-component:hover .page-component__add_button_box,
.page-component:hover .page-component__component_controls,
.page-component--visible-controls .page-component__add_button_box,
.page-component--visible-controls .page-component__component_controls,
.page-component.page-component--force-visible-controls .page-component__component_controls {
    opacity: 1;
}
.page-component__add_button_box{
    position: absolute;
    z-index: 500;
    left: 0;
    right: 0;
    margin: auto;
    height: 0;
    opacity: 0;
    transform: translateY(-1px);
    text-align: center;
    transition: opacity .1s ease;
}
.page-component__add_button{
    transform: translateY(-50%);
    line-height: 14px;
}
.page-component__add_button.has-bg {
  background-color: #fff !important;
}
.page-component__add_button .anticon {
  line-height: 0;
}
.page-component__component_control--ai {
  background-color: #722ed1;
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}
.page-component__component_control--ai path {
  fill: #fff !important;
}
.page-component__component_control--ai:hover,
.page-component__component_control--ai:focus {
  background-color: #9254de; 
  border-color: #9254de;
}
.page-component__component_control--ai:active {
  background-color: #531dab; 
  border-color: #531dab;
}

.page-component__component_visibility{
  left: 20px;
  top: 20px;
  position: absolute;
  z-index: 500;
}
.page-component__component_controls{
    opacity: 0;
    right: 0px;
    padding: 0px 20px;
    z-index: 500;
    transition: opacity .1s ease;
    text-align: right;
    position: absolute;
    width: 100%;
    height: calc(100% - 64px);
    pointer-events: none !important;
}
.page-component__component_controls * {
  pointer-events: auto !important;
}
.page-component__component_controls_list{
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}
.page-component__component_controls .page-component__component_controls_list{
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
}
.page-component__component_controls_item{
    list-style: none;

    margin-right: 10px;
}
.page-component__component_control {
  line-height: 0;
  min-width: 32px;
}
.page-component__component_control .anticon {
  line-height: 0;
}
.page-component__editor_fields_list{
    list-style: none;
}



.page-component__box{
    margin-bottom: 50px;
}
.page-component__box:last-child{
    margin-bottom: 0;
}
.page-component__title{
    margin-bottom: 25px;
    line-height: 1.4;
}

.page-component__integrations_list{
    list-style: none;
}
.page-component__integration_item{
    margin-bottom: 15px;
}
.page-component__integration_item:last-child{
    margin-bottom: 0;
}
.page-component__integration_not_ready{
    margin-top: 30px;
    padding: 20px;
    background-color: var(--main-bg-gray);
    border-radius: 10px;

    text-align: center;
}
.page-component__img_box{
    display: inline-block;
    width: 26px;
    text-align: center;
    margin-right: 5px;
}

.page-component__bg_overlay_box{
    transition: background-color 0.25s ease; /*we want the color to change in a fancy way in the editor*/
}

.page-component__component_id{

}
.page-component__padding_icon{
  vertical-align: middle;
  position: relative;
  bottom: 1px;
  margin-right: 6px;
}

.page-component__margin_tuner{
  text-align: center;
}
.page-component__margin_tuner_item{
  margin-bottom: 10px;
}
.page-component__margin_tuner_item:last-child{
  margin-bottom: 0;
}
.page-component__margin_tuner_label{
  display: inline-block;
  width: 130px;
}

.page-component__copy_blink_animation{
  transition: 0.2s ease-in-out all;
}
.page-component__margin_tuner_box{
  padding: 5px 15px;
}
.comps .editor__component .page-component__gpt-loading {
  max-height: none;
}

.page-component__gpt-loading-wrapper .ant-spin-dot>i {
  background-color: #722ed1;
}

.highlighted-on-focus:after{
  content: '';
  box-shadow: 0 -2px 0 0 var(--ant), 2px -2px 0 0 var(--ant), -2px -2px 0 0 var(--ant);
  width: 100%;
  display: none;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none !important;
}
.highlighted-on-focus:before{
  content: '';
  box-shadow: 0 0 0 2px var(--ant);
  width: 100%;
  display: none;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

.highlighted-on-focus:hover:after{
  display: block;
  z-index: 600;
}
.highlighted-on-focus:hover:before{
  display: block;
  z-index: 450;
}

.highlighted-on-focus.is-being-edited:after {
  display: block;
  z-index: 600;
}
.highlighted-on-focus.is-being-edited:before{
  display: block;
  z-index: 450;
}

.page-component__margin_tuner_submenu .ant-dropdown-menu-submenu-arrow {
  right: 4px;
}

.content-input{
    position: relative; /*we want the active content type radio buttons to be positioned absolutely*/
    margin-bottom: 30px;
    font-size: 14px;
}
.content-input__active_type{
    position: absolute;
    top: -6px;
    left: calc(100% - 0px);
    transform: translateX(-100%);
    text-align: right;
    display: flex;

    /*opacity: 0.2;*/
    /*transition: opacity 0.15s ease;*/
}
/*.content-input:hover .content-input__active_type{*/
    /*opacity: 1;*/
/*}*/
.content-input__label_box{
    display: flex;
    justify-content: flex-start;

    margin-bottom: 6px;
    line-height: 1.2;
}
.content-input__label_box.no-margin{
    margin-bottom: 0;
}
.content-input__label_box--space-between{
  justify-content: space-between;
}
.content-input__label_box--vertical-flex-start{
  align-items: flex-start;
}
.content-input__title{
    /*margin-top: 40px;*/
    /*margin-bottom: 20px;*/
    line-height: 0.9;
}
.content-input__title.has-tooltip{
    display: flex;
}
.content-input__title_vertical {
  display: flex;
  flex-direction: column;
}
.content-input__title_vertical .content-input__title_info {
  margin-left: 0;
  margin-top: 10px;
  position: static;
}
.content-input:first-child .content-input__title{
    /*margin-top: 0;*/
}
.content-input__title_info{
    margin-left: 9px;
    opacity: 0.6;

    font-size: 12px;
    font-weight: 300;
}
.content-input__tooltip_box{

}
.content-input__tooltip_icon{
    margin-left: 5px;
    font-size: 12px;
}
.ant-input-group-addon .content-input__tooltip_icon {
  margin-left: 0;
  margin-right: 5px;
}

.content-input__group{
    margin-bottom: 30px;
}
.content-input__group:last-child{
    margin-bottom: 0;
}
.content-input__input{
    margin-bottom: 10px;
}
.content-input__input--no-bottom-margin{
    margin-bottom: 0;
}
.content-input__input--monospace{
    font-family: monospace;
}
.content-input__input:last-child{
    margin-bottom: 0;
}

.content-input__cluster{
    position: relative;
    margin-bottom: 20px;
    padding: 16px 16px 16px 12px;

    border: 2px solid transparent;

    background-color: var(--main-bg-gray);
    border-radius: 8px;

    transition: background-color 0.25s ease, opacity 0.15s ease;

    /*box-shadow: 0 1px 10px 0 rgba(0,0,0,0.07);*/
}
.content-input__cluster:last-child{
    margin-bottom: 0;
}
.content-input__cluster .content-input__cluster{
    background-color: #f5f7fa;
    /*border: 1px solid #cfd1d3;*/
}
.content-input__cluster:not(.no-hover):hover{
    background-color: #e9edf3;
}
.content-input__cluster:hover .content-input__cluster:hover{
    background-color: #eff3f9;
}
.content-input__cluster--accent{
    border-color: var(--ocean);
}
.content-input__cluster--with-ghost:last-child{
    /* --with-ghost means this cluster if content inputs has the last so called ghost item.
     The ghost item is used to add new items.
     If the maxItems is reached, the ghost item is not needed anymore, so this class (.content-input__cluster--with-ghost) is not added to .content-input__cluster.
     */
    position: relative;
    opacity: 0.5;
}
.content-input__cluster--with-ghost:last-child:before,
.content-input__cluster--with-title:last-child:before{
    content: attr(data-add-new) " "; /*see https://developer.mozilla.org/en-US/docs/Web/CSS/attr*/
    /*content: "Add new ⤵";*/
    position: absolute;
    top: -10px;
    left: 10px;

    color: var(--ocean);
    font-size: 12px;
}
.content-input__cluster--with-ghost:only-child:before{
    content: "Fill in to add an item \2935";
}
.content-input__cluster--with-ghost:only-child{
    margin-top: 20px;
}

.content-input__cluster:hover{
    opacity: 1;
}
.content-input__control_cluster{
    position: absolute;
    z-index: 100;
    opacity: 0.1;
    top: 50%;
    transform: translateY(-50%) translateX(50%) scale(0.8);
    right: 0;

    /*transition: opacity 0.15s ease;*/
}
.content-input__control_cluster--bottom{
    top: auto;
    bottom: 0;
    transform: translateY(-10%) translateX(50%) scale(0.7);
}
.content-input__control_cluster--left{
    right: auto;
    left: 0;
    transform: translateY(-50%) translateX(-50%);
}
.content-input__control_cluster--top{
    transform: translateY(10%) translateX(50%) scale(0.7);
    top: 0;
}
.content-input__cluster--small .content-input__control_cluster--top{
    /*transform: translateY(-5px) translateX(50%);*/
}
.content-input__cluster--small .content-input__control_cluster--bottom{
    /*transform: translateY(5px) translateX(50%);*/
}
.content-input__cluster--hide-move-buttons:first-child .content-input__control_cluster--move-up,
.content-input__cluster--hide-move-buttons.content-input__cluster--hide-move-buttons--no-add-btn:nth-last-child(2) .content-input__control_cluster--move-down, /*to disable moving down below ghost items, not needed when we have "add new" button*/
.content-input__cluster--hide-move-buttons:last-child .content-input__control_cluster--move-down{
    display: none;
}
.content-input__cluster:hover > .content-input__control_cluster,
.content-input__cluster:hover > div > .content-input__control_cluster{
    /* .content-input__cluster:hover > .content-input__control_cluster - because move up/down controls are placed inside an empty div because React only returns one DOM element*/
    opacity: 1;
}
.content-input__cluster--with-ghost:last-child .content-input__control_cluster{
    /* The last child in always an empty list item. It is there to let users to add a new item. No necessary to delete it.*/
    display: none;
}

.content-input .ant-collapse-borderless > .ant-collapse-item{
    border: none;
}

.content-input__integrations_radio_group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    /*margin-bottom: 20px;*/
}
.content-input__integrations_radio{
    position: relative;
    display: inline-flex;
    align-items: center;

    padding: 0px 5px 0px 10px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: 12px;

    border-radius: var(--main-border-radius);
    border: 1px solid var(--gray);

    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;

    transition: border-color 0.20s ease, background-color 0.20s ease;
}
.content-input__integrations_radio:not(.content-input__integrations_radio--small){
    min-width: 200px;
    max-width: 380px;
}
.content-input__integrations_radio.content-input__integrations_radio--small{
    min-width: 150px;
}
.content-input__integrations_radio.ant-radio-wrapper-checked{
    border-color: var(--ant) !important;
    color: var(--ant);
}
.content-input__integrations_radio.ant-radio-wrapper-checked{
    cursor: pointer; /*fallback*/
}
.content-input__integrations_radio.ant-radio-wrapper-checked:hover{
    border-color: var(--gray);
}


.content-input__integrations_radio_button{
    display: inline-block;

    /*margin-right: 10px;*/
}
.content-input__integrations_radio_button--activate{

}
.content-input__integrations_radio_button--disabled{
    display: none;
}

.content-input__integrations_radio_checked-icon{
    display: none;

    color: #fff;
}
.content-input__integrations_radio__del{
    position: absolute;
    right: -15px;

    opacity: 0;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    transition: all .2s ease;
}
.content-input__integrations_radio__del.is-popup {
  right: 0;
}
.content-input__integrations_radio:hover .content-input__integrations_radio__del{
    opacity: 1;
}
.content-input__integrations_radio__del_button{
    transform: scale(0.7);
}
.content-input__integrations_radio__rename_button {
  margin-left: 5px;
}

.content-input__integrations_radio.ant-radio-wrapper-checked .content-input__integrations_radio_button--activate{
    display: none;
}
.content-input__integrations_radio.ant-radio-wrapper-checked .content-input__integrations_radio_button--disabled{
    display: inline-block;
}
.content-input__integrations_radio.ant-radio-wrapper-checked .content-input__integrations_radio_button--disabled *,
.content-input__integrations_radio.ant-radio-wrapper-checked .ant-radio-input,
.content-input__integrations_radio.ant-radio-wrapper-checked .ant-radio-checked{
    cursor: default; /*fallback*/
    cursor: not-allowed;
}

.content-input__integrations_radio--small .content-input__integrations_radio_button{
    display: none !important;
}
.content-input__integrations_radio--small{
    height: 26px;
    font-size: 10px;
}


.content-input__integrations_radio:hover{
    border-color: var(--ocean);
}
.content-input__integrations_radio_icon{
    height: 14px;
    margin-right: 7px;
    vertical-align: text-bottom;
    width: 24px;
    object-fit: contain;
    object-position: top center;
}
.content-input__integrations_empty{
    background-color: var(--main-bg-gray);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}


.content-input__integrations_radio__title{
    position: relative;
    padding-right: 10px;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
}
.content-input__integrations_radio__title_text{
    color: inherit;
    transition: opacity 0.2s ease;
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
.content-input__integrations_radio__created{
    position: absolute;
    left: 0;
    top: -1px;
    color: inherit;
    opacity: 0;
    transition: opacity 0.2s ease;
    /*text-transform: capitalize;*/
    vertical-align: middle;
    pointer-events: none;
}
/* .content-input__integrations_radio:hover .content-input__integrations_radio__title_text{
    opacity: 0;
}
.content-input__integrations_radio:hover .content-input__integrations_radio__created{
    opacity: 1;
}

.content-input__integrations_radio:hover .content-input__integrations_radio_icon{
    opacity: 0;
} */
.content-input__integrations_radio_icon{
    transition: opacity 0.2s ease;
}

.content-input__integrations_radio__created_text--small{
    font-size: 0.7em;
    position: relative;
    top: -1px;
}


.content-input__edit_button_opener{
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
}
.comps .content-input__edit_button_opener .button{
    /*50% smaller*/

    padding: 6px 10px 6px;
    min-width: 60px;
    min-height: 22px;
    border-radius: 2px;
    font-size: 9px;
    border-width: 1px;

    vertical-align: middle;
}
.comps .content-input__edit_button_opener .button .button__text{
    font-size: inherit;
}
.comps .content-input__edit_button_opener .store-button{
    height: 24px;
    border-radius: 3px;
    width: 75px;
    min-width: 60px;
}
.comps .content-input__edit_button_opener .store-button .store-button__img svg{
    height: 75%;
    width: 100%;
}
.comps .content-input__edit_button_opener .pill-link{
    font-size: 8px;
    padding: 3px 7px 3px 4px;

    margin-bottom: 4px;
}
.comps .content-input__edit_button_opener .pill-link__pill{
    height: 12px;
    line-height: 12px;
    padding: 0 3px;
    font-size: 5px;
}
.comps .content-input__edit_button_opener .pill-link__text{
    margin-left: 5px;
}
.comps .content-input__edit_button_opener .pill-link__icon{
    margin-left: 3px;
    transform: scale(0.8);
}

.comps .content-input__edit_button_opener .button:hover,
.comps .content-input__edit_button_opener .button:focus,
.comps .content-input__edit_button_opener .button:active{
    transform: none;
}

.content-input__button_style_options{
    margin-top: 20px;
    padding: 10px 10px 0 10px;
}
.content-input__button_style_option{
    position: relative;
    z-index: 10;

    margin-right: 10px;
    margin-bottom: 15px;

    padding: 3px;
    line-height: 0;
    border-radius: 4px;
}
.content-input__button_style_option .pill-link .icon{
    /*don't know why the icon acts weirdly*/
    position: relative;
    top: 3px;
}
.content-input__edit_button_example{
    margin-left: 15px;
}
.content-input__button_style_option--gray,
.content-input__edit_button_example--gray{
    background-color: #e2e2e2;
    box-shadow: 0 0 9px 4px #e2e2e2;
}
.content-input__edit_img_opener{
    width: 150px;
    height: 32px;
    overflow: hidden;

    margin-right: 10px;

    background-image: linear-gradient(45deg, #D6D6D6 25%, transparent 25%), linear-gradient(-45deg, #D6D6D6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #D6D6D6 75%), linear-gradient(-45deg, transparent 75%, #D6D6D6 75%);
}
.content-input__edit_img_opener, .content-input__edit_img_opener:hover, .content-input__edit_img_opener:active, .content-input__edit_img_opener:focus{
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    background-color: var(--gray);
}
.content-input__edit_img_opener:hover{
    background-image: none;
}
.content-input__edit_img_opener svg{
    height: 24px;
    width: 24px;
    vertical-align: middle;
}
.content-input__edit_img_opener img{
    height: 24px;
    vertical-align: middle;
}
.content-input__edit_img_opener .primary-layout__icon_placeholder{
    height: 20px;
    width: 20px;
    font-size: 12px;

    transform: scale(0.7);
    transform-origin: center;
}
.content-input__edit_img_opener_box{
    display: flex;
    align-items: center;

    margin-bottom: 10px;
}

.content-input__img_remove_button{
    opacity: 0.1;
}
.content-input__img_preview{
    margin-right: 10px;
}
.content-input__edit_img_opener_box:hover .content-input__img_remove_button{
    opacity: 1;
}

/*.content-input__edit_img_opener_wrapper{*/
    /*margin-bottom: 40px;*/
/*}*/
.content-input__edit_img_opener_wrapper .image-picker{
    background-color: #e8e8e8;
}

.content-input__abstract_icon_items{
    padding-left: 10px;
}
.content-input__abstract_icon_item,
.content-input__emoji_button{
    border: none;
    height: auto;

    padding: 15px;
    box-shadow: none;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 0;
}
.content-input__abstract_icon_item:hover,
.content-input__emoji_button:hover{
    background-color: var(--main-bg-gray);
}

.content-input__emoji_group{
    margin-bottom: 50px;
}
.content-input__emoji_image{
    height: 34px;
}
.content-input__input__detail_icon{
    max-width: 16px;
}

.content-input__image_picker_box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.content-input__edit_logo_help{
    margin-left: 20px;
    vertical-align: middle;
    opacity: 0.5;

    transition: opacity 0.2s ease;
}
.content-input__edit_logo_icon{
    transform: scale(0.8) translateY(2px);

    transition: transform 0.2s ease;
}
.content-input__image_picker_box:hover .content-input__edit_logo_icon{
    transform: scale(0.8) translateY(2px) translateX(-5px);
}

.content-input__image_picker_box:hover .content-input__edit_logo_help{
    opacity: 0.8;
}

.content-input .ant-collapse-content-active{
    overflow: visible; /*to make sure draftjs popups visible*/
}
.content-input__fixed_nav_box{
    margin-top: 20px;
    padding: 5px;
}
.content-input__switch_box{
  margin-bottom: 10px;
}
.content-input__switch_box:last-child{
  margin-bottom: 0;
}
.content-input__switch_box_label{
  margin-left: 10px;
}
.content-input__input--removable{
    position: relative;
}
.content-input__input--removable:hover .content-input__remove_input_button{
    opacity: 1;
}
.content-input__remove_input_button{
    z-index: 100;
    transform: scale(0.7) translateX(15px);
    position: absolute;
    top: 4px;
    right: 0;
    opacity: 0.1;
}
.uploadcare--widget_status_started {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.content-input__move_place-left {
    left: 0;
    transform: translateX(-50%) scale(0.7);
}

.content-input__tooltip-content p:last-child {
    margin-bottom: 0;
}

.content-input__toltip-value {
  background: rgba(255,255,255, 0.1);
  border-radius: 10px;
  padding: 7px 10px;
  margin: 10px 0;
}

.content-input__info-box {
  margin-top: 20px;
}
.content-input__info-item {
  margin-bottom: 20px;
}
.content-input__info-item:last-child {
  margin-bottom: 0;
}
.content-input__info-item p:last-child {
  margin-bottom: 0;
}
.content-input__tooltip-link {
  color: #fff;
  text-decoration: underline;
}
.content-input__tooltip-link:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.content-input__tooltip-link-icon {
  margin-left: 3px;
}
.content-input__tooltip-link-icon--loading {
  margin-left: 8px;
}
.content-input__tooltip-link--disabled {
  text-decoration: none;
  cursor: default !important;
}
.content-input__period-group {
  margin-bottom: 10px;
}
.content-input__period-group .content-input__period-input {
  width: 50%;
}
.content-input__input .content-input__cluster--single {
  margin-bottom: 20px;
}
.content-input__dropdown .ant-dropdown-menu {
  max-height: 213px;
  overflow-y: auto;
}

.content-input .ant-select {
  flex-grow: 1;
}
.content-input .ant-select-selection, .ant-select.is-text-cursor .ant-select-selection {
  cursor: text;
}
.content-input .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.content-input .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .anticon-check {
  display: none;
}
.content-input .ant-select-selection--multiple .ant-select-selection__choice {
  max-width: 300px;
}

.content-input__select-dropdown-with-tooltips .ant-select-dropdown-menu-item {
  padding: 0;
}
.content-input__select-dropdown-with-tooltips .ant-select-dropdown-menu-item .content-input__select-option-wrapper {
  padding: 5px 12px;
}
.content-input__select-option-wrapper {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-component-drawer .ant-drawer-content .ant-drawer-body {
  padding: 0 16px;
}
.edit-component-drawer.is-main .ant-drawer-content .ant-drawer-body {
  padding: 0 8px;
}

.edit-component-drawer input.ant-input, 
.edit-component-drawer textarea.ant-input,
.edit-component-drawer .ant-select-selection,
.edit-component-drawer .ant-input-number
{
  border-radius: var(--border-radius);
}
.edit-component-drawer .ant-input-number-handler-wrap {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.edit-component-drawer .content-input__input_box .rdw-editor-wrapper {
  border-radius: var(--border-radius);
}
.edit-component-drawer .content-input__input_box .rdw-editor-toolbar {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.edit-component-drawer .ant-tabs.ant-tabs-top .ant-tabs-small-bar .ant-tabs-tab {
  padding: 12px 0;
}

.edit-component-drawer .ant-drawer-content .ant-drawer-header {
  padding: 10px 16px;
}

.edit-component-drawer .ant-input-group > .ant-input:last-child, .edit-component-drawer .ant-input-group-addon:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.edit-component-drawer .ant-input-group > .ant-input:first-child, .edit-component-drawer .ant-input-group-addon:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.edit-component-drawer div .ant-input-group.ant-input-group-compact > *:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.edit-component-drawer div .ant-input-group.ant-input-group-compact > *:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
.edit-component-drawer div .ant-input-group.ant-input-group-compact > *:only-child {
  border-radius: var(--border-radius);
}

.edit-component-drawer button.ant-btn:not(.ant-btn-circle):not(.ant-input-search-button) {
  border-radius: var(--border-radius);
}
.edit-component-drawer button.ant-btn.ant-input-search-button {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
.edit-component-drawer div .ant-radio-button-wrapper:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.edit-component-drawer div .ant-radio-button-wrapper:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

/* .edit-component-drawer .content-input:first-child{
  margin-top: 15px;
} */
.edit-component-drawer .content-input__title {
font-size: 14px;
font-weight: 600;
color: #1F1F1F;
margin-bottom: 17px;
}
.edit-component-drawer .content-input__title .content-input__tooltip_icon {
  color: rgba(0, 0, 0, 0.65);
}
.edit-component-drawer .content-input__label {
font-size: 12px;
color: #595959;
}

.edit-component-drawer .content-input:last-child{
  margin-bottom: 100px;
}

.edition-drawer-title {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.content-input__integrations_radio span.ant-radio-inner {
  margin-right: 8px;
}
.content-input__integrations_radio .ant-radio-checked::after {
  width: 16px !important;
}

.content-input__select .ant-select-selection {
  cursor: text;
}

.plugins_drawer{
}

.plugins_drawer__option{
    position: relative;

    border-radius: 4px;
    border: 1px solid transparent;
}
.plugins_drawer__option_header{
    padding: 20px 20px 20px 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.plugins_drawer__option_title{
    margin: 0;
}

.plugins_drawer__option--stripe{
    border-color: #32325d;
    background-color: #32325d;
}
.plugins_drawer__option--utm{
    border-color: #ef9233;
    background-color: #ef9233;
}

.plugins_drawer__option_title_logo{
    height: 40px;
    vertical-align: middle;
}

.stripe_logo_fill{
    transition: fill 0.25s ease;
}
.plugins_drawer__option--stripe .stripe_logo_fill{
    fill:  #ffffff;
}

.plugins_drawer__option_title_word{
    /*text-transform: uppercase;*/
    font-weight: 400;
    font-size: 14px;
    vertical-align: middle;

    display: inline-flex;
    align-items: center;
    color: #fff;
}

.plugins_drawer__option_title_word_text{
    /*vertical-align: middle;*/
    margin-right: 7px;
}
.plugins_drawer__option_title_word_logo{
    height: 1em;
    /*vertical-align: middle;*/
}

/*.plugins_drawer__option_before, .plugins_drawer__option_after{*/
    /*display: flex;*/
    /*align-items: center;*/
    /**/
    /*position: absolute;*/
    /*top: 14px;*/
    /*right: 20px;*/
    /**/
    /*height: 32px;*/
/*}*/



.plugins_drawer__option_body{
    padding: 20px 20px 40px 20px;
    background-color: white;
}


.plugins_drawer__stripe_edit_product_row{
    margin-bottom: 20px;
}
.plugins_drawer__stripe_edit_product_row:last-child{
    margin-bottom: 0;
}


.plugins_drawer__option_body_products_items{
    margin-top: 30px;
    margin-bottom: 30px;
}
.plugins_drawer__stripe_product{
    position: relative;
    padding: 20px;
    border-radius: 4px;
    /*border: 1px solid var(--);*/

    box-shadow: var(--card-shadow);
    margin-bottom: 30px;
}
.plugins_drawer__stripe_product:last-child{
    margin-bottom: 0;
}

.plugins_drawer__stripe_product_side{
    display: flex;
    flex-direction: column;

    position: absolute;
    z-index: 100;
    opacity: 0.1;
    top: 50%;
    transform: translateY(-50%) translateX(50%);
    right: 0;

    transition: opacity 0.2s ease;
}
.plugins_drawer__stripe_product:hover .plugins_drawer__stripe_product_side{
    opacity: 1;
}


.plugins_drawer__stripe_product_control{
    margin: 5px 0;
}

.plugins_drawer__stripe_product_body{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.plugins_drawer__stripe_product_info{
    display: flex;
    align-items: center;
}
.plugins_drawer__stripe_product_image{
    max-height: 50px;
    max-width: 50px;

    margin-right: 15px;
}
.plugins_drawer__stripe_product_title{
    margin-right: 7px;
}
.plugins_drawer__stripe_product_currency{
    font-size: 10px;
    opacity: 0.6;
    margin-left: 3px;
}

.plugins_drawer__option_body_add_product_button_box{
    display: flex;
    justify-content: center;
}
.plugins_drawer__fields{
    width: 100%;
}
.plugins_drawer__test_url{
    margin-left: 13px;
}

.popup-menu__content > * {
    margin-bottom: 10px;
}
.popup-menu__content > *:last-child {
    margin-bottom: 0;
}
.popup-menu__items-wrapper > * {
    margin-bottom: 10px;
}
.popup-menu__items-wrapper > *:last-child {
    margin-bottom: 0;
}
.popup-menu__item {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--piter);
    border-radius: 4px;
    padding: 8px 5px 8px 8px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.popup-menu__item.ant-radio-wrapper-checked:not(.popup-menu__item_disabled) {
    border-color: var(--ant);
    color: var(--ant);
}
.popup-menu__item:hover {
    color: var(--ant);
}
.popup-menu__item:hover .content-input__integrations_radio__del {
    opacity: 1;
}
.popup-menu__edit {
    right: -28px;
}
.popup-menu__edit-button {
    transform: scale(1);
}
.popup-menu__item_disabled.ant-radio-wrapper {
    cursor: not-allowed;
}

body .public-DraftStyleDefault-block{
    /*draftjs text editor kill stupid vertical margins*/
    margin-top: 0;
    margin-bottom: 0;
}
body .public-DraftStyleDefault-ltr{
    text-align: inherit;
}
/*we do not need a clickable link icon when focus a link*/
.rdw-link-decorator-icon{
    display: none;
}
.rdw-editor-main{
    padding: 0px 10px 8px 10px;
}
.rdw-editor-wrapper{
    border: 1px solid var(--piter);
    background-color: white;
    border-radius: 4px;
}
body .rdw-editor-toolbar{
    border: none;
    border-bottom: 1px solid var(--piter);
}
body .rdw-option-wrapper{
    padding: 5px;
    height: 24px;
    border-radius: 4px;
}
.rdw-option-wrapper img{
    height: 100%;
    opacity: 0.6;
}
.rdw-option-wrapper, .rdw-dropdown-wrapper{
    transition: border-color 0.2s ease;
}

body .rdw-option-wrapper:hover, body .rdw-option-active, body .rdw-dropdown-wrapper:hover,
body .rdw-dropdown-optionwrapper:hover,
body .rdw-emoji-modal{
    box-shadow: none;
}
body .rdw-emoji-icon{
    margin: 4px;
    font-size: 14px;
    border-radius: 4px;
    padding: 5px;
    height: auto;
    color: #000;
    display: inline-block;
    width: 30px;
    text-align: center;
}
body .rdw-emoji-icon:hover{
    transition: background-color 0.2s ease;
}
body .rdw-emoji-modal{
    padding: 5px;
    left: auto;
    right: 5px;
}
.rdw-emoji-icon:hover{
    background-color: var(--gray);
}
body .rdw-dropdown-wrapper{
    height: 24px;
    font-size: 12px;
    border-radius: 4px;
}
body .rdw-option-active{
    border-color: var(--ant);
}
body .rdw-dropdown-wrapper:hover, body .rdw-option-wrapper:hover{
    border-color: var(--concrete);
}
body .rdw-block-dropdown{
    width: 85px;
}
.rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose{
    display: none;
}

body .rdw-link-wrapper, body .rdw-embedded-wrapper, body .rdw-emoji-wrapper{
    position: static; /*to align popups to the tool bar - be on the left*/
}
.rdw-editor-toolbar{
    position: relative;
}

.rdw-embedded-modal-header-option{
    white-space: nowrap;
}
.rdw-embedded-modal-header-label{
    display: none;
}
.rdw-editor-toolbar{
    height: 37px;
    transition: height 0.2s ease, opacity 0.2s ease;
}
.rdw-editor-toolbar[aria-hidden="true"]{
    /*height: 0;*/
    /*opacity: 0;*/
}
.rdw-editor-toolbar[style="visibility: hidden;"]{
    height: 0;
    opacity: 0;
}
.rdw-editor-main ul li > div{
  display: inline;
}

body .rdw-embedded-modal-header,
body .rdw-embedded-modal-link-input,
body .rdw-embedded-modal-size-input,
body .rdw-link-modal-input{
    font-size: 14px;
    border-radius: 4px;
}
body .rdw-embedded-modal-size-input{
    padding-left: 10px;
}
body .rdw-embedded-modal,
body .rdw-embedded-modal-btn:hover,
body .rdw-link-modal-btn,
body .rdw-link-modal-btn:hover,
body .rdw-link-modal{
    box-shadow: none;
}
body .rdw-link-modal{
    height: 166px;
}
body .rdw-link-modal-label{
    font-size: 14px;
}
body .rdw-embedded-modal-header-option{
    display: inline;
}
body .rdw-link-modal-label:after{
    content: ':';
}
.rdw-embedded-modal-header-option:after{
    content: ' (iframe src)';
    opacity: 0.6;
}
.rdw-link-modal-target-option{
    cursor: pointer;
}
body .rdw-embedded-modal-btn,
body .rdw-link-modal-btn{
    font-size: 14px;
    border-radius: 4px;
    height: 24px;

    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
body .rdw-embedded-modal-btn[disabled],
body .rdw-link-modal-btn[disabled]{
    cursor: not-allowed;
}
body .rdw-embedded-modal-btn:hover:not([disabled]),
body .rdw-link-modal-btn:hover:not([disabled]){
    color: var(--ant);
    border-color: var(--ant);
}

body .rdw-embedded-modal-link-input,
body .rdw-link-modal-input{
    padding-left: 10px;
}
body .rdw-link-modal-input,
body .rdw-embedded-modal-link-input{
    height: 24px;
}
body .rdw-embedded-modal-size{
    font-size: 14px;
    /*display: none;*/
}

body .rdw-embedded-modal{
    height: 186px;
}
body .rdw-embedded-modal-btn-section{
    justify-content: flex-start;
}
body .rdw-link-modal-buttonsection{
    margin: 0;
}
body .rdw-link-modal-btn,
body .rdw-embedded-modal-btn{
    margin-left: 0;
    margin-right: 10px;
}

/*useless fields*/
.rdw-link-modal-label[for="linkTitle"],
.rdw-link-modal-input[name="linkTitle"]{
    display: none;
}

.rdw-image-mandatory-sign{
    display: none;
}

.rdw-embedded-modal-size:before{
    content: 'Size:';
    display: block;
    font-size: 14px;

    margin-right: 10px;
}
.rdw-embedded-modal-link-input-wrapper:before{
    content: 'Src:';
    display: block;

    font-size: 14px;
    margin-right: 10px;
}

.rdw-editor-main{
    max-height: 300px;
}
.rdw-editor-main{
    color: #5e5e5e;
}
body .public-DraftEditorPlaceholder-root{
    color: #cdcdcd;
}
.rdw-editor-revert-button{
    position: absolute;
    right: 8px;
    z-index: 10;
}


body .public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0{
    list-style-type: none;
}

.hor-input-group {
  display: flex; }
  .hor-input-group.equal-space > * {
    flex: 1 1; }
  .hor-input-group .content-input__input {
    margin-bottom: 0; }

.ver-input-group {
  display: flex;
  flex-direction: column; }

.mockups_content_input{
    margin-top: 20px;
}

.mockups_content_input__menu_item{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 26px;
}
.mockups_content_input__device_box{
    width: 42px;
    text-align: center;
}
.mockups_content_input__device{
    height: 24px;
    margin-right: 10px;
    background-color: white;
    padding: 3px;
    border-radius: 2px;
}
.mockups_content_input__human_title{
    /*margin-right: 10px;*/
}
.mockups_content_input__active_info{

}
.mockups_content_input__resolution{
    margin-left: 10px;
    margin-right: 10px;
    opacity: 0.6;
    font-size: 12px;
}


.mockups_content_input__colors_box{
    display: flex;
    align-items: center;

    margin-left: 10px;
}
.mockups_content_input__color_item_box{
    margin-right: 6px;
}
.mockups_content_input__color_item_box:last-child{
    margin-right: 0;
}

.mockups_content_input__color_item_box--active .mockups_content_input__color_button,
.mockups_content_input__color_item_box--active .mockups_content_input__color_button:hover{
    cursor: default;
    transform: scale(0.7);
}
.mockups_content_input__color_button{
    cursor: pointer;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: var(--card-shadow);

    transition: transform 0.25s ease;
}
.mockups_content_input__color_button:hover{
    transform: scale(1.08);
}

.mockups_content_input__dropdown .ant-dropdown-menu-item{
    cursor: default;
}

.mockups_content_input__disclaimer{
    margin-top: 40px;
    opacity: 0.6;
    transition: opacity 0.2s ease;

    cursor: pointer;
}
.mockups_content_input__disclaimer:hover{
    opacity: 1;
}
/*.mockups_content_input__disclaimer .ant-popover-buttons button:first-child{*/
/*    display: none;*/
/*}*/
.mockups_content_input__dropdowns{
    display: inline-flex;
    flex-direction: column;
}
.mockups_content_input__dropdown_box{
    margin-top: 10px;
    background: #f3f3f3;
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
}
.mockups_content_input__dropdown_box:first-child{
    margin-top: 0;
}

.form_input_picker {
}

.form_input_picker__required_button {
  padding: 0;
  width: 30px;
  line-height: 2;
  margin-left: 8px;
  margin-right: 8px;
}
.form_input_picker__field {
  display: flex;
  justify-content: center;
}

.form_input_picker__type_title {
  margin-left: 5px;
}
.form_input_picker__field_box {
  position: relative;

  margin-bottom: 10px;

  transition: 0.2s ease padding, 0.2s ease shadow;
  border-radius: 4px;
}
.form_input_picker__field_box--opened {
  box-shadow: 0px 5px 11px rgb(0 0 0 / 8%);
  padding: 15px 15px 5px;
}
.form_input_picker__field_box--opened .form_input_picker__field_controls{
  display: none;
}
.form_input_picker__field_box--opened .form_input_picker__field_box__primary_settings{
  display: block;
}
.form_input_picker__field_box__primary_settings {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
}
.form_input_picker__field_box__advanced_settings {
  margin-top: 20px;
}
.form_input_picker__field_box .content-input__control_cluster {
  /*position: absolute;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*top: 0;*/

  /*margin: auto;*/
}

.form_input_picker__field_box:hover .content-input__control_cluster,
.form_input_picker__field_box:hover .form_input_picker__field_delete {
  opacity: 1;
}
.form_input_picker__add_new_box {
  display: block;
}

.form_input_picker__field_delete {
  opacity: 0.1;

  transition: opacity 0.15s ease;
}
.form_input_picker__field_controls {
  width: 70px;
  padding-left: 10px;
  position: relative;

  display: flex;
  /*justify-content: space-between;*/
  align-items: center;

  /*position: absolute;*/
  /*right: 0;*/
  /*top: 0;*/
  /*bottom: 0;*/
}

.form_input_picker__field_controls .content-input__control_cluster {
  transform: scale(0.7);
  transition-delay: 0s;
}
.form_input_picker__field_controls
  .content-input__control_cluster--top
  + .content-input__control_cluster--bottom {
  transform: translateX(20px) scale(0.7);
}

.form_input_picker__setting_box{
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  line-height: 1;
}
.form_input_picker__setting_box .content-input__tooltip_box {
  padding-top: 4px
}

.after_form_submission_config__action_box{
    margin-bottom: 30px;
}
.after_form_submission_config__action_box:last-child{
    margin-bottom: 0;
}

.after_form_submission_config__tag{
    cursor: default;
    margin-bottom: 5px;
}

.captcha-drawer>* {
  margin-bottom: 30px;
}
.captcha-drawer>*:last-child {
  margin-bottom: 0;
}
.captcha-drawer__section:last-child {
    margin-bottom: 0;
}
.captcha-drawer__input {
    width: 100%;
}
.captcha-drawer__group .captcha-drawer__section {
    margin-bottom: 0;
}
.captcha-drawer__alert p:last-child {
    margin-bottom: 0;
}

.captcha-config__active {
    display: flex;
    align-items: center;
}
.captcha-config__tag {
    cursor: default;
    margin-left: 12px;
}
#rapidforms-integration-container > .fixed {
  z-index: 99999; }

.marketsy-iframe {
  z-index: 99999; }

.content-input-box__footer_button{
    position: relative;
    display: block;
    height: auto;
    padding: 0;

    margin-bottom: 20px;
    overflow: hidden;
}
.content-input-box__footer_button:last-child{
    margin-bottom: 0;
}
.content-input-box__footer_button--active{
   border-color: var(--ocean);
}
.content-input-box__footer_buttons_box{
    margin-top: 30px;
}
.content-input-box__footer_button_icon{
    position: absolute;
    left: 7px;
    top: 3px;

    font-size: 20px;

    transition: opacity 0.2s ease, transform 0.2s ease;

    opacity: 0;
}
.content-input-box__footer_button--active .content-input-box__footer_button_icon{
    opacity: 1;
    transform: none;
}
.content-input-box__footer_button_thumb{
    width: 100%;
}
.content-input__icon {
    font-size: 12px;
    margin-right: 6px;
}
.content-input__button-text {
    display: flex;
    align-items: center;
}
.content-input__inline-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-input__inline-input .content-input__label_box {
  margin-bottom: 0;
}
.content-input__inline-input .ant-input {
  width: 320px;
}

.switch-component {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
}
.switch-component__button {
  position: relative;
  width: calc(50% - 10px);
  box-shadow: var(--card-shadow-small);
  cursor: pointer;
  border-radius: 8px;
  min-height: 32px;
  transition: transform .2s, box-shadow .2s;
}
.switch-component__button.is-full-width {
  width: 100%;
}
.switch-component__button:not(.is-selected):not(.is-loading):hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.switch-component__icon {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 20px;
  z-index: 2;
}
.switch-component__button.is-selected:before,
.switch-component__button.is-loading:before {
  content: '';
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none !important;
  border-radius: 8px;
  background: rgba(255,255,255,0.5);
}
.switch-component__button.is-selected:after {
  content: '';
  z-index: 1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  position: absolute;
  left: -2px;
  top: -2px;
  box-shadow: 0 0 0 2px var(--ant);
  border-radius: 8px;
  pointer-events: none !important;
}
.switch-component .add-page-component__thumb {
  border-radius: 8px;
}

.switch-component__button.is-loading:after {
  display: none;
}
.switch-component__button.is-loading .switch-component__icon {
  display: none;
}

.switch-component__loading-icon {
  position: absolute;
  top: 50%;
  right: 50%;
  font-size: 20px;
  z-index: 2;
  color: var(--ant);
  transform: translate(50%, -50%);
}

.background-color-picker{
    display: inline-block;

    padding: 10px 10px 6px 10px;
    white-space: nowrap; /* To avoid line-breaks on some devices */
  }
.page-component__box .background-color-picker{
  margin-top: 5px;
}  
.background-color-picker__item,
.background-color-picker__circle{
    transition: transform 0.25s ease;
}

.background-color-picker__item{
    position: relative;
    z-index: 10; /*we need z-index because the checkered background is a pseudo element*/

    margin-right: 20px;
    padding: 0;
    font-size: 0;

    cursor: pointer;
}
.background-color-picker__item:last-child{
    margin-right: 0;
}

.background-color-picker__item:hover{
    transform: scale(1.08);
}

.background-color-picker__circle{
    display: inline-block;
    height: 40px;
    width: 40px;

    border-radius: 9999px;

    box-shadow: 0 2px 12px 3px rgba(0,0,0,0.12);
}
.background-color-picker__item--border .background-color-picker__circle{
    border: 1px solid var(--cloud);
}

.background-color-picker__item--small .background-color-picker__circle{
    transform: scale(0.6);
}
.background-color-picker__info{
    margin-top: 40px;
    /*opacity: 0.7;*/
}
.background-color-picker__custom {
  background: radial-gradient(50% 50% at 50% 50%,#ffffff 0%,transparent 100%),
  conic-gradient(#f5222d, #fadb14, #52c41a, #1890ff, #722ed1, #eb2f96, #f5222d);
}
.background-color-picker__popconfirm .ant-popover-message-title {
  padding-left: 0;
}
.background-color-picker__popconfirm .ant-popover-inner-content {
  width: 200px;
}
.background-color-picker__tooltip-frame {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.background_image_picker__transparency_box{
  margin-top: 27px;
}
.background_image_picker__transparency_slider{
  padding: 0 7px;
}

.other-01{
    padding: 50px 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    text-align: center;
}
.other-01__logo_box {
    margin-bottom: 12px;
}
.other-01__logo{
    height: 30px;
}
.uni-is-dark-bg .other-01__logo-new {
    color: #fff;
}
.other-01__logo-new {
  font-size: 28px;
}
.other-01__title{
    margin-bottom: 10px;

    font-size: 20px;
    line-height: 1.4;
}
.other-01__info{
    font-size: 14px;
    opacity: 0.5;
}

.other-01__wrapper{
    position: relative;
    height: 100px;
    overflow: hidden;

}
.other-01__wrapper:hover,
.page-component__gpt-loading-wrapper--visible .other-01__wrapper{
    overflow: auto;
}
.other-01__text{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
}
.other-01__wrapper-has_content:hover .other-01__text,
.page-component__gpt-loading-wrapper--visible .other-01__text{
    opacity: 0;
}

.other-01__wrapper-has_content:hover .other-01__code,
.page-component__gpt-loading-wrapper--visible .other-01__code {
    opacity: 1;
    transform: none;
    transition: opacity 0.25s ease, transform 0.25s ease;
}

.other-01__code{
    transform: translateY(5px);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    margin: auto;

    opacity: 0;

    text-align: left;
    line-height: 1.6;
    font-size: 14px;
}
html .comps .other-01__code{
    font-family: monospace !important;
}

.other-02-parent .page-component__wrapper {
  z-index: auto !important;
  z-index: initial !important; }

.editor .senja-embed > .fixed {
  z-index: 950 !important; }

.other-02 .other-02__widget-container .sj-powered-by {
  justify-content: center; }

.other-02 .sj-avatars {
  justify-content: center; }

.other-02 .sj-card-carousel {
  padding: 0 16px; }

.other-02 .sj-bubble-card-list {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 16px; }

.other-02 .sj-card-wall {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 16px; }

.other-02 .sj-lindoy {
  padding: 0 16px;
  max-width: 1080px;
  margin: 0 auto; }

.other-02 .sj-avatars-pro > .flex-col {
  overflow: auto;
  padding: 12px 8px; }
  .other-02 .sj-avatars-pro > .flex-col > .flex-col {
    align-items: center; }
    .other-02 .sj-avatars-pro > .flex-col > .flex-col > .flex {
      width: auto; }

.other-02 .sj-bricks + .mt-4.flex.justify-end {
  justify-content: center; }

.other-02 .sj-slab-carousel {
  max-width: 800px;
  margin: 0 auto; }

.other-02 .sj-simple-badge {
  justify-content: center; }

.other-03__widget-container {
  max-width: 640px;
  padding: 0 16px;
  margin: 0 auto; }

.other-03__spinner {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border: 3px solid #303030;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  -webkit-animation: other03rotation 1s linear infinite;
          animation: other03rotation 1s linear infinite; }

.uni-is-dark-bg .other-03__spinner {
  border-color: #fff;
  border-bottom-color: transparent; }

@-webkit-keyframes other03rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes other03rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.other-04__spinner {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border: 3px solid #303030;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  -webkit-animation: other04rotation 1s linear infinite;
          animation: other04rotation 1s linear infinite; }

.uni-is-dark-bg .other-04__spinner {
  border-color: #fff;
  border-bottom-color: transparent; }

@-webkit-keyframes other04rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes other04rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.other-04__widget-container.is-loading {
  padding: 40px 30px; }

.other-04__widget-container {
  padding: 0 30px;
  max-width: 1440px;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .other-04__widget-container {
      padding: 0 16px; } }
  .other-04__widget-container iframe {
    border-radius: 12px; }

.gpt-assistant {
  width: 360px;
}
.gpt-assistant__button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gpt-assistant__chat-button {
  position: fixed;
  right: 20px;
  bottom: 70px;
  z-index: 550;
}

.gpt-assistant__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 0 16px 16px;
}
.gpt-assistant__popover {
  position: fixed;
}
.gpt-assistant__message-history {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-end;
}
.gpt-assistant__message-history-item {
  padding: 6px 16px;
  border-radius: 16px;
  max-width: 224px;
  overflow-wrap: break-word;
}
.gpt-assistant__assistant-message-wrapper {
  align-self: flex-start;
  display: flex;
  align-items: flex-end;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.gpt-assistant__message-history-item--user-prompt {
  background: #722ed1;
  color: #fff;
}
.gpt-assistant__message-history-item--assistant-response {
  background: #f0f0f0;
}
.gpt-assistant__message-history-item--completion-progress {
  color: inherit;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
  background: #fff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.gpt-assistant__message-history-item--completion-progress:hover {
  color: inherit;
  box-shadow: 0px 4px 8px -2px rgba(24, 39, 75, 0.1),
    0px 6px 8px -2px rgba(24, 39, 75, 0.06);
  transform: translateY(-3px);
}

.gpt-assistant__button:hover,
.gpt-assistant__button:focus {
  color: #722ed1;
  background-color: #fff;
  border-color: #722ed1;
}
.gpt-assistant__icon path {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.gpt-assistant__button:hover .gpt-assistant__icon path,
.gpt-assistant__button:focus .gpt-assistant__icon path {
  fill: #722ed1;
}
.gpt-assistant__input-button {
  position: absolute;
  line-height: 0;
  padding: 8px 10px;
  cursor: pointer;
}
.gpt-assistant__input-button--hidden {
  pointer-events: none;
}
.gpt-assistant__send-button {
  top: calc(50% - 23px);
  right: 15px;
}
.gpt-assistant__presets-button .anticon {
  font-size: 16px;
}
.gpt-assistant__presets-button:hover,
.gpt-assistant__presets-button--active {
  color: #722ed1;
}
.ant-input.gpt-assistant__input {
  resize: none;
  margin-bottom: 0;
  padding-right: 48px;
  max-height: 128px;
  border-radius: 16px;
}
.gpt-assistant__popover .ant-popover-inner-content {
  background: #fafafa;
  padding: 0;
  border-radius: 0 0 4px 4px;
}
.gpt-assistant__input:hover:not([disabled]) {
  border-color: #9254de;
}
.gpt-assistant__input:focus:not([disabled]) {
  border-color: #9254de;
  box-shadow: 0 0 0 2px rgb(114 46 209 / 20%);
}
.gpt-assistant__message-history-wrapper {
  height: 363px;
  overflow: auto;
  padding: 16px;
  width: 100%;
}
.gpt-assistant__message-icon {
  font-size: 12px;
}
.gpt-assistant__message-icon--loading {
  color: #722ed1;
}
.gpt-assistant__avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.gpt-assistant__avatar--hidden {
  visibility: hidden;
}

.gpt-assistant__typing-indicator {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  color: #bfbfbf;
}

.gpt-assistant__typing-indicator-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 1px;
  background-color: currentColor;
  border-radius: 50%;
  -webkit-animation: typing 1s infinite;
          animation: typing 1s infinite;
}
.gpt-assistant__typing-indicator-dot:first-child {
  margin-left: 0;
}
.gpt-assistant__typing-indicator-dot:last-child {
  margin-right: 0;
}

.gpt-assistant__typing-indicator-dot:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.gpt-assistant__typing-indicator-dot:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

@-webkit-keyframes typing {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes typing {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.gpt-assistant__tune-buttons-container {
  display: flex;
  margin-bottom: 3px;
}
.gpt-assistant__tune-button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gpt-assistant__tune-button:hover:not(.gpt-assistant__tune-button--disabled) {
  background: #efdbff;
  color: #722ed1;
}
.gpt-assistant__tune-button--disabled {
  color: #bfbfbf;
  cursor: not-allowed;
}
.gpt-assistant__message-text-box {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}
.gpt-assistant__message-history-item-text {
  white-space: nowrap;
  display: block;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gpt-assistant__button-tooltip {
  position: fixed;
}
.gpt-assistant__presets-dropdown.ant-dropdown::before {
  display: none;
}
.gpt-assistant__presets-dropdown-item {
  min-width: 136px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gpt-assistant__presets-dropdown-item:not(.gpt-assistant__presets-dropdown-group-title):hover {
  background-color: #f9f0ff;
}
.gpt-assistant__presets-dropdown-submenu-item:hover
  .ant-dropdown-menu-submenu-title {
  background-color: #f9f0ff;
}
.gpt-assistant__presets-dropdown-group-title:hover,
.gpt-assistant__presets-dropdown-group-title {
  cursor: default;
  color: #00000073;
}
.gpt-assistant__presets-dropdown-group-title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #00000073;
}
.gpt-assistant__presets-dropdown {
  max-height: 242px;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.gpt-assistant__popover .ant-popover-title {
  padding: 10px;
  text-align: center;
  font-size: 16px;
}

.gpt-assistant__mode
  .ant-tabs-nav
  .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
  color: #722ed1;
}
.gpt-assistant__mode .ant-tabs-nav .ant-tabs-tab-active {
  color: #722ed1;
}
.gpt-assistant__mode .ant-tabs-ink-bar {
  background-color: #722ed1;
}
.gpt-assistant__mode .ant-tabs-bar {
  background-color: #fff;
  margin: 0;
}
.gpt-assistant__mode .ant-tabs-tab {
  width: 50%;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.gpt-assistant__mode .ant-tabs-nav {
  width: 100%;
}

.gpt-assistant__form--contextual-input {
  padding: 0;
  width: 300px;
}
.gpt-assistant__send-button--contextual-input {
  top: calc(50% - 15px);
  right: -1px;
}
.ant-input.gpt-assistant__input--contextual-input {
  border-radius: 4px;
}
.ant-input.gpt-assistant__input--contextual-input--has-reasoning {
  border-radius: 4px 4px 0 0;
}
.gpt-assistant__presets-dropdown-menu--hidden {
  display: none;
}
.gpt-assistant__input--contextual-input--loading {
  border-color: #d9d9d9;
}
.gpt-assistant__input--contextual-input--loading:hover {
  border-color: #d9d9d9;
}
.gpt-assistant__reasoning-field {
  width: 100%;
  max-width: 300px;
  padding: 4px 11px;
  background: #fafafa;
  font-size: 12px;
  color: #898989;
  border-radius: 0 0 4px 4px;
  border: 1px solid #d9d9d9;
  border-top: none;
}
.gpt-assistant__form-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.gpt-assistant__loading-indicator {
  bottom: 2px;
  position: relative;
}
.gpt-assistant__loading-indicator-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding-left: 11px;
  position: absolute;
  top: 5px;
}
.gpt-assistant__loading-indicator-text {
  color: #722ed1;
  font-weight: 500;
}
.gpt-assistant__loading-indicator-dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 1px;
  background-color: #722ed1;
  border-radius: 50%;
  -webkit-animation: loading 1s infinite;
          animation: loading 1s infinite;
}
.gpt-assistant__loading-indicator-dot:first-child {
  margin-left: 0;
}
.gpt-assistant__loading-indicator-dot:last-child {
  margin-right: 0;
}

.gpt-assistant__loading-indicator-dot:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.gpt-assistant__loading-indicator-dot:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

@-webkit-keyframes loading {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-3px);
    opacity: 0.3;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-3px);
    opacity: 0.3;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.gpt-assistant__stop-button {
  top: calc(50% - 16.5px);
  right: 1px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
  line-height: 16px;
}
.gpt-assistant__stop-button:hover {
  opacity: 0.65;
}
.gpt-assistant__presets-dropdown-item--with-tooltip {
  padding: 0;
}
.gpt-assistant__presets-dropdown-tooltip-wrapper {
  padding: 5px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gpt-assistant__presets-dropdown-item--with-tooltip .anticon {
  margin-right: 8px;
}
.gpt-assistant__try-again-tooltip-subtitle {
  color: rgba(255, 255, 255, 0.65);
}
.gpt-assistant__form-wrapper {
  text-align: left;
}

.display_bad_website_overlay{
  margin-top: 20px;
  margin-bottom: 80px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.display_bad_website_overlay__content{
  /* text-align: center; */
  /* max-width: 600px; */
}
.fill-template-modal {
  display: flex;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}
.fill-template-modal__item {
  width: 300px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  padding: 12px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  cursor: pointer;
}
.fill-template-modal__title,
.fill-template-modal__subtitle {
  display: block;
}
.fill-template-modal__title {
  line-height: 22px;
}
.fill-template-modal__subtitle {
  font-size: 12px;
  line-height: 20px;
}
.fill-template-modal__icon {
  font-size: 20px;
}
.fill-template-modal__text-wrapper {
  text-align: left;
}

.fill-template-modal__item:hover {
  background-color: #f5f5f5;
}

.fill-template-modal__item,
.fill-template-modal__title,
.fill-template-modal__subtitle,
.fill-template-modal__icon,
.fill-template-modal__item--ai path {
  transition: all 0.2s;
}

.fill-template-modal__generate-button {
  display: inline-flex;
  align-items: center;
  line-height: 0;
  background-color: var(--ai);
  border-color: var(--ai);
}
.fill-template-modal__generate-button:not(.ant-btn-loading) {
  grid-gap: 8px;
  gap: 8px;
}
.fill-template-modal__generate-button-icon path {
  fill: #fff;
}
.fill-template-modal__generate-button:hover,
.fill-template-modal__generate-button:focus {
  background-color: #9254de;
  border-color: #9254de;
}
.fill-template-modal__generate-button:active {
  background-color: #531dab;
  border-color: #531dab;
}

form textarea.fill-template-modal__textarea {
  margin-bottom: 0;
}
.fill-template-modal__textarea:hover:not([disabled]) {
  border-color: #9254de;
}
.fill-template-modal__textarea:focus:not([disabled]) {
  border-color: #9254de;
  box-shadow: 0 0 0 2px rgb(114 46 209 / 20%);
}
.fill-template-modal__form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 4px;
  gap: 4px;
}
.fill-template-modal__character-counter {
  color: #00000073;
}

.fill-template-progress {
  padding: 8px 20px;
  background: #fff;
  box-shadow: 0px 0.987023651599884px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.05),
    0px 2.843898296356201px 5.32008171081543px 0px rgba(0, 0, 0, 0.08),
    0px 5.98975944519043px 10.017241477966309px 0px rgba(0, 0, 0, 0.09),
    0px 11.167402267456055px 17.869047164916992px 0px rgba(0, 0, 0, 0.11),
    0px 19.14726448059082px 33.422088623046875px 0px rgba(0, 0, 0, 0.14),
    0px 27px 80px 0px rgba(0, 0, 0, 0.19);

  border-radius: 999px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  justify-content: center;
  width: 210px;
}

.fill-template-progress__indicator {
  height: 100%;
  width: 50%;
  left: 0;
  right: 0;
  position: absolute;
  background: #d3adf7;
  transition: width 0.5s;
}
.fill-template-progress__text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.fill-template-progress__text {
  font-weight: 500;
}
.fill-template-progress__indicator {
  background: linear-gradient(
      to right,
      #ffa39e 0%,
      #ffd591 10%,
      #fffb8f 20%,
      #b7eb8f 30%,
      #87e8de 40%,
      #91caff 50%,
      #adc6ff 60%,
      #d3adf7 70%,
      #ffadd2 80%,
      #ffbb96 90%,
      #ffa39e 100%
    )
    0 0/200% 100%;
  width: 210px;
  -webkit-animation: a 4s linear infinite;
          animation: a 4s linear infinite;
}

@-webkit-keyframes a {
  to {
    background-position: -200% 0;
  }
}

@keyframes a {
  to {
    background-position: -200% 0;
  }
}

.blog-post-editor {
  padding: 50px 20px 100px;
  min-height: 100vh; }

.blog-post-editor__wrapper {
  position: relative;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  /*overflow: hidden;*/
  /*it looks slightly better with overflow: hidden but we can not use position:sticky for a fixed nav*/
  background-color: white;
  box-shadow: 0px -1.46302px 7.24861px 0px rgba(0, 0, 0, 0.07), 0px -4.91399px 24.34658px 0px rgba(0, 0, 0, 0.04), 0px -22px 109px 0px rgba(0, 0, 0, 0.03); }

.blog-post-editor__wrapper .editor__templates_nav:not(.editor__templates_nav--sticky) {
  position: relative; }

.blog-post-editor__wrapper .nav-02:not(.nav-02--sticky) {
  position: relative;
  z-index: 400; }

.blog-post-editor__main-title {
  margin: 0 auto;
  width: 100%;
  max-width: 680px;
  padding-top: 30px;
  margin-bottom: 15px; }

.blog-post-editor__main-title_input {
  width: 100%;
  height: 100%;
  min-height: 30px;
  padding: 0;
  box-shadow: none;
  border: none;
  resize: none;
  font-weight: 700;
  color: #37352f;
  caret-color: #37352f;
  line-height: 1.2;
  font-size: 50px;
  overflow: hidden; }
  .blog-post-editor__main-title_input::-webkit-input-placeholder {
    color: #cccccc; }
  .blog-post-editor__main-title_input::-ms-input-placeholder {
    color: #cccccc; }
  .blog-post-editor__main-title_input::placeholder {
    color: #cccccc; }
  .blog-post-editor__main-title_input:focus {
    box-shadow: none; }

.custom_fonts .custom-google-fonts-enabled .blog-post-editor__main-title_input {
  font-family: inherit; }

.blog-post-editor__bottom-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%; }

.blog-post-editor__save_button_tick {
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;
  text-align: center;
  margin: auto;
  z-index: 100;
  font-size: 30px;
  -webkit-animation: move_up_and_hide 1s ease-out forwards;
          animation: move_up_and_hide 1s ease-out forwards; }

.blog-post-editor__designed_with {
  margin-top: 50px;
  text-align: center;
  opacity: 0.3;
  text-shadow: 0 1px 1px #fff;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 12px; }

.blog-post-editor__save_button_box {
  position: fixed;
  z-index: 1020;
  bottom: 28px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px; }

.blog-post-editor__save_button_box--accent {
  box-shadow: 0 0 0 rgba(204, 99, 91, 0.8);
  -webkit-animation: pulsation 2s infinite;
          animation: pulsation 2s infinite; }

.slate-editor__post-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  line-height: 1.7;
  margin-top: 10px;
  padding-bottom: 130px;
  min-height: calc(100vh - 200px); }
  .slate-editor__post-body > *:not(figure):not(iframe) {
    width: 100%;
    max-width: 680px; }

.slate-editor__toolbar-item {
  display: flex;
  align-items: center;
  margin: 3px; }

.slate-editor__toolbar-item-btn {
  width: 50px;
  padding: 4px;
  font-size: 16px; }

.slate-editor__toolbar-item-label {
  margin-left: 10px;
  display: none; }

.slate-editor__invisible_drag_ang_drop {
  display: none; }

.slate-editor__saving-status {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #505050;
  margin-top: 15px;
  margin-bottom: 15px; }

.slate-editor__editor-toolbar {
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  padding: 5px 5px 10px;
  display: flex;
  justify-content: center;
  z-index: 1;
  opacity: 0.3;
  transform: scale(0.98) translateY(3px);
  transition: opacity .4s ease, transform .4s ease; }

.blog-post-editor__wrapper:hover .slate-editor__editor-toolbar {
  opacity: 1;
  transform: scale(1); }

.slate-editor__format-buttons {
  display: flex;
  justify-content: center;
  margin-right: 40px; }

.slate-editor .uploadcare--widget {
  position: static; }

.slate-editor .uploadcare--widget__text,
.slate-editor .uploadcare--widget__button_type_open {
  display: none; }

.slate-editor .uploadcare--widget__dragndrop-area {
  position: fixed;
  opacity: 0.9;
  font-size: 80px;
  max-width: 720px;
  right: 0;
  left: 0;
  width: 100%;
  min-width: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  min-height: auto;
  -webkit-animation: blinking_frame 1s linear infinite;
          animation: blinking_frame 1s linear infinite;
  top: 80px;
  bottom: 50px;
  z-index: 50; }

@-webkit-keyframes blinking_frame {
  0% {
    color: #a4a2a1; }
  50% {
    color: #808080; }
  100% {
    color: #a4a2a1; } }

@keyframes blinking_frame {
  0% {
    color: #a4a2a1; }
  50% {
    color: #808080; }
  100% {
    color: #a4a2a1; } }

.codex-editor {
  min-height: 100vh;
  background-color: white;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px; }
  .codex-editor .codex-editor__redactor {
    width: 900px;
    max-width: 100%;
    margin: 0 auto; }
  .codex-editor .ce-block {
    padding-top: 2px;
    padding-bottom: 2px; }
  .codex-editor .ce-block__content {
    width: 100%;
    max-width: 684px;
    padding-right: 2px;
    padding-left: 2px; }
  .codex-editor .ce-block--selected .ce-block__content {
    background: rgba(45, 170, 219, 0.3); }
  .codex-editor .cdx-list__item {
    padding-top: 1px;
    padding-bottom: 1px; }
  .codex-editor .ce-toolbar__actions {
    right: 100%;
    margin-right: 22px;
    top: 13px; }
  .codex-editor .ce-toolbar__plus {
    margin-left: -38px;
    top: 0; }
  .codex-editor .ce-settings {
    min-width: 120px; }
  .codex-editor .ce-settings__plugin-zone > div {
    display: flex;
    justify-content: space-around; }
  .codex-editor .ce-settings__plugin-zone:not(:empty) {
    padding: 3px; }
  .codex-editor .ce-settings__default-zone {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  .codex-editor .ce-settings__plugin-zone:not(:empty) ~ .ce-settings__default-zone {
    border-top: 1px solid var(--gray); }
  .codex-editor .cdx-checklist__item-checkbox {
    border-radius: 0;
    width: 18px;
    height: 18px;
    margin-top: 10px;
    border: 2px solid #37352f;
    background-color: #fff; }
    .codex-editor .cdx-checklist__item-checkbox:after {
      top: 2px;
      left: 1px;
      width: 12px;
      height: 6px; }
    .codex-editor .cdx-checklist__item-checkbox:hover {
      border: 2px solid #37352f;
      background-color: #ddd; }
  .codex-editor .cdx-list {
    list-style-position: inside;
    padding-left: 0; }
  .codex-editor .cdx-list__item::marker {
    text-align: end; }
  .codex-editor .cdx-checklist__item.cdx-checklist__item--checked .cdx-checklist__item-checkbox {
    background-color: #2eaadc;
    border-color: #2eaadc; }
    .codex-editor .cdx-checklist__item.cdx-checklist__item--checked .cdx-checklist__item-checkbox:hover {
      border: 2px solid #2a9ecc;
      background-color: #2a9ecc; }
  .codex-editor .cdx-settings-button[data-tune="withBorder"] {
    display: none; }
  .codex-editor .tc-table {
    margin-top: 0;
    margin-bottom: 0; }

.codex-editor__editor-toolbar {
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  height: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  opacity: 0.3;
  transform: scale(0.98) translateY(3px);
  transition: opacity .4s ease, transform .4s ease; }

.blog-post-editor__wrapper:hover .codex-editor__editor-toolbar {
  opacity: 1;
  transform: scale(1); }

body .cdx-notifies {
  left: auto;
  right: 20px; }

.codex-editor__saving-status {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #505050; }

@-webkit-keyframes blinking_frame {
  0% {
    color: #a4a2a1; }
  50% {
    color: #808080; }
  100% {
    color: #a4a2a1; } }

@keyframes blinking_frame {
  0% {
    color: #a4a2a1; }
  50% {
    color: #808080; }
  100% {
    color: #a4a2a1; } }

.cdx-block.image-tool .cdx-button:after {
  content: "Up to 1MB";
  margin-left: 5px;
  opacity: 0.5;
  font-size: 0.8em; }

.codex-editor .ce-block {
  transition: background-color .2s ease; }

.ce-block--focused {
  border-radius: 4px 4px;
  background-color: #fcfcfc;
  transition: none; }

.codex-editor .ce-block__content,
.ce-block__content {
  max-width: 100%; }

.codex-editor .codex-editor__redactor {
  width: 100%; }

.cdx-block.image-tool {
  max-width: 1060px;
  margin: auto; }

.cdx-block.image-tool--loading,
.cdx-block.image-tool--empty,
.image-tool__caption, .embed-tool__caption {
  max-width: 680px; }

.image-tool__caption, .embed-tool__caption {
  margin-left: auto;
  margin-right: auto; }

.cdx-input.image-tool__caption, .cdx-input.embed-tool__caption {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 0.8em; }

.cdx-block:not(.embed-tool):not(.image-tool),
body .ce-header {
  width: 100%;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto; }

.cdx-block.embed-tool {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1060px; }

body .codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  opacity: 0.5; }

.ce-code__textarea::-webkit-input-placeholder {
  color: white;
  opacity: 0.3;
  -webkit-transition: .2s ease opacity;
  transition: .2s ease opacity; }

.ce-code__textarea::-ms-input-placeholder {
  color: white;
  opacity: 0.3;
  -ms-transition: .2s ease opacity;
  transition: .2s ease opacity; }

.ce-code__textarea::placeholder {
  color: white;
  opacity: 0.3;
  transition: .2s ease opacity; }

.ce-code__textarea:focus::-webkit-input-placeholder {
  color: white;
  opacity: 0.7; }

.ce-code__textarea:focus::-ms-input-placeholder {
  color: white;
  opacity: 0.7; }

.ce-code__textarea:focus::placeholder {
  color: white;
  opacity: 0.7; }

.js-codex-editor .codex-editor__editor-toolbar {
  padding: 0;
  left: calc(50% - 8px);
  display: inline-block; }

.js-codex-editor .codex-editor__saving-status {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block; }

.cdx-list__item {
  margin-top: 0.1em;
  margin-bottom: 0.1em; }

.codex-editor--toolbox-opened .ce-paragraph[data-placeholder]::before {
  opacity: 0 !important; }

.plain-html__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.plain-html__saving-status {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #505050;
  margin-top: 15px;
  margin-bottom: 15px; }

.illustrated_spinner_box{
  padding: 50px 20px;
  text-align: center;
  height: 100vh;
  position: relative;
}
.illustrated_spinner_box__content{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
.illustrated_spinner_box__text{
  margin-top: 10px;
}
.illustrated_spinner_box__image{
  max-width: 120px;
  -webkit-animation: levitate 5s ease-out infinite;
          animation: levitate 5s ease-out infinite;
}

@-webkit-keyframes levitate{
  0%{
    transform: translateY(0);
  }
  70%{
    transform: translateY(20%);
  }
  100%{
    transform: translateY(0);
  }
}

@keyframes levitate{
  0%{
    transform: translateY(0);
  }
  70%{
    transform: translateY(20%);
  }
  100%{
    transform: translateY(0);
  }
}
.changelog {
    --modal-width: 1000px;
    --modal-height: 500px;
    --frame-offset: -352px;

    padding: 20px 32px;
    width: 100%;
    max-width: var(--modal-width);
}
.changelog__content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0 0 4px 4px;
    position: absolute;
    height: calc(var(--modal-height) - var(--frame-offset));
    top: var(--frame-offset);
}
.changelog .ant-modal-body {
    padding: 0;
    position: relative;
    overflow: hidden;
    height: var(--modal-height);
}
.changelog__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.ant-modal-mask {
    z-index: 1200;
}
.ant-modal-wrap {
    z-index: 1210;
}

.not-found{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;

    text-align: center;
}
.not-found__error_code{
    font-size: 100px;
    font-weight: 700;
}
.not-found__title{
    margin-bottom: 40px;
}
.not-found__bottom_button{

}
.help-button{
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 201000000; /*more than .page-component__component_controls*/
}
.help-button__info{
    display: block;
    opacity: 0.7;
    font-size: 12px;
}
.help-button__action{
    cursor: pointer;
    text-align: left;
}
/*.help-button__icons{*/
    /*text-align: center;*/
/*}*/

.version-info {
    padding: 5px 12px;
}

.version-info__text {
    display: block;
    opacity: 0.5;
    font-size: 12px;
}

.version-info__text_small {
    font-size: 10px;
}
.permissions {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-bg-gray);
}
.permissions__info .ant-empty-image {
  height: 50px;
}
.permissions__buttons > * {
  margin-right: 10px;
}
.permissions__buttons > *:last-child {
  margin-right: 0;
}
.permissions__title {
  font-size: 2em;
  display: block;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  font-weight: 700;
  line-height: 1.3;
  color: #303030;
}
.permissions__text {
  display: block;
  max-width: 550px;
}
.permissions__paragraph {
  margin-bottom: 1em;
  display: block;
}
.permissions__paragraph:last-child {
  margin-bottom: 0;
}


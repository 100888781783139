.auth-form{
    width: 100%;

    text-align: center;

}
.auth-form form {
    max-width: 344px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 1px 20px;

    background-color: white;
    border-radius: var(--main-border-radius);
    box-shadow: var(--card-shadow);

    margin-bottom: 30px;
}

.update-api-source-form__validation-message-container {
  margin-top: 10px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  div.ant-typography, .ant-typography p  {
    margin-bottom: 10px;
  }

  h4 {
    margin: 0 0 6px 0;
    font-size: 14px;
    line-height: 22px;
  }

  img {
    max-width: 100%;
    border-radius: 4px;
  }
}

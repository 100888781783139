.gpt-assistant {
  width: 360px;
}
.gpt-assistant__button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gpt-assistant__chat-button {
  position: fixed;
  right: 20px;
  bottom: 70px;
  z-index: 550;
}

.gpt-assistant__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 0 16px 16px;
}
.gpt-assistant__popover {
  position: fixed;
}
.gpt-assistant__message-history {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-end;
}
.gpt-assistant__message-history-item {
  padding: 6px 16px;
  border-radius: 16px;
  max-width: 224px;
  overflow-wrap: break-word;
}
.gpt-assistant__assistant-message-wrapper {
  align-self: flex-start;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
}

.gpt-assistant__message-history-item--user-prompt {
  background: #722ed1;
  color: #fff;
}
.gpt-assistant__message-history-item--assistant-response {
  background: #f0f0f0;
}
.gpt-assistant__message-history-item--completion-progress {
  color: inherit;
  text-decoration: none !important;
  cursor: pointer;
  display: block;
  background: #fff;
  box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
    0px 4px 4px -2px rgba(24, 39, 75, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.gpt-assistant__message-history-item--completion-progress:hover {
  color: inherit;
  box-shadow: 0px 4px 8px -2px rgba(24, 39, 75, 0.1),
    0px 6px 8px -2px rgba(24, 39, 75, 0.06);
  transform: translateY(-3px);
}

.gpt-assistant__button:hover,
.gpt-assistant__button:focus {
  color: #722ed1;
  background-color: #fff;
  border-color: #722ed1;
}
.gpt-assistant__icon path {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.gpt-assistant__button:hover .gpt-assistant__icon path,
.gpt-assistant__button:focus .gpt-assistant__icon path {
  fill: #722ed1;
}
.gpt-assistant__input-button {
  position: absolute;
  line-height: 0;
  padding: 8px 10px;
  cursor: pointer;
}
.gpt-assistant__input-button--hidden {
  pointer-events: none;
}
.gpt-assistant__send-button {
  top: calc(50% - 23px);
  right: 15px;
}
.gpt-assistant__presets-button .anticon {
  font-size: 16px;
}
.gpt-assistant__presets-button:hover,
.gpt-assistant__presets-button--active {
  color: #722ed1;
}
.ant-input.gpt-assistant__input {
  resize: none;
  margin-bottom: 0;
  padding-right: 48px;
  max-height: 128px;
  border-radius: 16px;
}
.gpt-assistant__popover .ant-popover-inner-content {
  background: #fafafa;
  padding: 0;
  border-radius: 0 0 4px 4px;
}
.gpt-assistant__input:hover:not([disabled]) {
  border-color: #9254de;
}
.gpt-assistant__input:focus:not([disabled]) {
  border-color: #9254de;
  box-shadow: 0 0 0 2px rgb(114 46 209 / 20%);
}
.gpt-assistant__message-history-wrapper {
  height: 363px;
  overflow: auto;
  padding: 16px;
  width: 100%;
}
.gpt-assistant__message-icon {
  font-size: 12px;
}
.gpt-assistant__message-icon--loading {
  color: #722ed1;
}
.gpt-assistant__avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.gpt-assistant__avatar--hidden {
  visibility: hidden;
}

.gpt-assistant__typing-indicator {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  color: #bfbfbf;
}

.gpt-assistant__typing-indicator-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 1px;
  background-color: currentColor;
  border-radius: 50%;
  animation: typing 1s infinite;
}
.gpt-assistant__typing-indicator-dot:first-child {
  margin-left: 0;
}
.gpt-assistant__typing-indicator-dot:last-child {
  margin-right: 0;
}

.gpt-assistant__typing-indicator-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.gpt-assistant__typing-indicator-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-4px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.gpt-assistant__tune-buttons-container {
  display: flex;
  margin-bottom: 3px;
}
.gpt-assistant__tune-button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gpt-assistant__tune-button:hover:not(.gpt-assistant__tune-button--disabled) {
  background: #efdbff;
  color: #722ed1;
}
.gpt-assistant__tune-button--disabled {
  color: #bfbfbf;
  cursor: not-allowed;
}
.gpt-assistant__message-text-box {
  display: flex;
  align-items: center;
  gap: 6px;
}
.gpt-assistant__message-history-item-text {
  white-space: nowrap;
  display: block;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gpt-assistant__button-tooltip {
  position: fixed;
}
.gpt-assistant__presets-dropdown.ant-dropdown::before {
  display: none;
}
.gpt-assistant__presets-dropdown-item {
  min-width: 136px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gpt-assistant__presets-dropdown-item:not(.gpt-assistant__presets-dropdown-group-title):hover {
  background-color: #f9f0ff;
}
.gpt-assistant__presets-dropdown-submenu-item:hover
  .ant-dropdown-menu-submenu-title {
  background-color: #f9f0ff;
}
.gpt-assistant__presets-dropdown-group-title:hover,
.gpt-assistant__presets-dropdown-group-title {
  cursor: default;
  color: #00000073;
}
.gpt-assistant__presets-dropdown-group-title {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #00000073;
}
.gpt-assistant__presets-dropdown {
  max-height: 242px;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.gpt-assistant__popover .ant-popover-title {
  padding: 10px;
  text-align: center;
  font-size: 16px;
}

.gpt-assistant__mode
  .ant-tabs-nav
  .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
  color: #722ed1;
}
.gpt-assistant__mode .ant-tabs-nav .ant-tabs-tab-active {
  color: #722ed1;
}
.gpt-assistant__mode .ant-tabs-ink-bar {
  background-color: #722ed1;
}
.gpt-assistant__mode .ant-tabs-bar {
  background-color: #fff;
  margin: 0;
}
.gpt-assistant__mode .ant-tabs-tab {
  width: 50%;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.gpt-assistant__mode .ant-tabs-nav {
  width: 100%;
}

.gpt-assistant__form--contextual-input {
  padding: 0;
  width: 300px;
}
.gpt-assistant__send-button--contextual-input {
  top: calc(50% - 15px);
  right: -1px;
}
.ant-input.gpt-assistant__input--contextual-input {
  border-radius: 4px;
}
.ant-input.gpt-assistant__input--contextual-input--has-reasoning {
  border-radius: 4px 4px 0 0;
}
.gpt-assistant__presets-dropdown-menu--hidden {
  display: none;
}
.gpt-assistant__input--contextual-input--loading {
  border-color: #d9d9d9;
}
.gpt-assistant__input--contextual-input--loading:hover {
  border-color: #d9d9d9;
}
.gpt-assistant__reasoning-field {
  width: 100%;
  max-width: 300px;
  padding: 4px 11px;
  background: #fafafa;
  font-size: 12px;
  color: #898989;
  border-radius: 0 0 4px 4px;
  border: 1px solid #d9d9d9;
  border-top: none;
}
.gpt-assistant__form-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.gpt-assistant__loading-indicator {
  bottom: 2px;
  position: relative;
}
.gpt-assistant__loading-indicator-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 11px;
  position: absolute;
  top: 5px;
}
.gpt-assistant__loading-indicator-text {
  color: #722ed1;
  font-weight: 500;
}
.gpt-assistant__loading-indicator-dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 1px;
  background-color: #722ed1;
  border-radius: 50%;
  animation: loading 1s infinite;
}
.gpt-assistant__loading-indicator-dot:first-child {
  margin-left: 0;
}
.gpt-assistant__loading-indicator-dot:last-child {
  margin-right: 0;
}

.gpt-assistant__loading-indicator-dot:nth-child(2) {
  animation-delay: 0.1s;
}

.gpt-assistant__loading-indicator-dot:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes loading {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-3px);
    opacity: 0.3;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.gpt-assistant__stop-button {
  top: calc(50% - 16.5px);
  right: 1px;
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
  line-height: 16px;
}
.gpt-assistant__stop-button:hover {
  opacity: 0.65;
}
.gpt-assistant__presets-dropdown-item--with-tooltip {
  padding: 0;
}
.gpt-assistant__presets-dropdown-tooltip-wrapper {
  padding: 5px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gpt-assistant__presets-dropdown-item--with-tooltip .anticon {
  margin-right: 8px;
}
.gpt-assistant__try-again-tooltip-subtitle {
  color: rgba(255, 255, 255, 0.65);
}
.gpt-assistant__form-wrapper {
  text-align: left;
}

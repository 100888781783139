.publish-page-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
}
.publish-page-form__inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.publish-page-form__input-box {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
.publish-page-form__label-box {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  margin-top: 4px;
}
.publish-page-form__input-container {
  width: 270px;
}

.other-03__widget-container {
  max-width: 640px;
  padding: 0 16px;
  margin: 0 auto;
}
.other-03__spinner {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border: 3px solid #303030;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: other03rotation 1s linear infinite;
}
.uni-is-dark-bg .other-03__spinner {
  border-color: #fff;
  border-bottom-color: transparent;
}

@keyframes other03rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.join-form__bottom_button{
    /* not DRY :( */
    display: inline-block;
}
.join-form__agreements{
    max-width: 350px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}
form.login-form__form{
    padding-bottom: 20px;
}
.login-form .ant-form-item-label{
    line-height: 1;
}
.login-form .ant-form-item{
    text-align: left;
    margin-bottom: 10px;

}
.login-form .ant-form-item label{
    font-size: 12px;
}

.login-form .ant-form-item:last-child{
    text-align: center;
}
.login-form__button{
    margin-top: 16px;
}
.opengraph__existing_icon{
    display: inline-block;

    /*border: 1px solid var(--piter);*/
    /*padding: 5px 20px;*/
    /**/
    /*width: 150px;*/
    /*padding-left: 10px;*/
    /*border-radius: 4px;*/
}

.opengraph__current_icon_box{
    position: relative;
    max-width: 500px;
    vertical-align: middle;

    box-shadow: var(--card-shadow);
}
.opengraph__mockup{
    width: 100%;
    height: auto;
}
.opengraph__current_icon{
    position: absolute;
    top: 95px;
    left: 27px;
    width: 372px;
    height: 217px;

    border: 2px solid #ececec;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}
.opengraph__current_overlay{
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255,255,255,0.8);
    transition: opacity 0.2s ease;
}
.opengraph__current_icon:hover .opengraph__current_overlay,
.opengraph__current_overlay--visible{
    opacity: 1;
}

/*opengraph__current_overlay*/
.opengraph__deleting_text{
    vertical-align: middle;

    margin-left: 10px;

    /*opacity: 0.6;*/
    color: var(--ocean);
}

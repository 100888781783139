.content-input{
    position: relative; /*we want the active content type radio buttons to be positioned absolutely*/
    margin-bottom: 30px;
    font-size: 14px;
}
.content-input__active_type{
    position: absolute;
    top: -6px;
    left: calc(100% - 0px);
    transform: translateX(-100%);
    text-align: right;
    display: flex;

    /*opacity: 0.2;*/
    /*transition: opacity 0.15s ease;*/
}
/*.content-input:hover .content-input__active_type{*/
    /*opacity: 1;*/
/*}*/
.content-input__label_box{
    display: flex;
    justify-content: flex-start;

    margin-bottom: 6px;
    line-height: 1.2;
}
.content-input__label_box.no-margin{
    margin-bottom: 0;
}
.content-input__label_box--space-between{
  justify-content: space-between;
}
.content-input__label_box--vertical-flex-start{
  align-items: flex-start;
}
.content-input__title{
    /*margin-top: 40px;*/
    /*margin-bottom: 20px;*/
    line-height: 0.9;
}
.content-input__title.has-tooltip{
    display: flex;
}
.content-input__title_vertical {
  display: flex;
  flex-direction: column;
}
.content-input__title_vertical .content-input__title_info {
  margin-left: 0;
  margin-top: 10px;
  position: static;
}
.content-input:first-child .content-input__title{
    /*margin-top: 0;*/
}
.content-input__title_info{
    margin-left: 9px;
    opacity: 0.6;

    font-size: 12px;
    font-weight: 300;
}
.content-input__tooltip_box{

}
.content-input__tooltip_icon{
    margin-left: 5px;
    font-size: 12px;
}
.ant-input-group-addon .content-input__tooltip_icon {
  margin-left: 0;
  margin-right: 5px;
}

.content-input__group{
    margin-bottom: 30px;
}
.content-input__group:last-child{
    margin-bottom: 0;
}
.content-input__input{
    margin-bottom: 10px;
}
.content-input__input--no-bottom-margin{
    margin-bottom: 0;
}
.content-input__input--monospace{
    font-family: monospace;
}
.content-input__input:last-child{
    margin-bottom: 0;
}

.content-input__cluster{
    position: relative;
    margin-bottom: 20px;
    padding: 16px 16px 16px 12px;

    border: 2px solid transparent;

    background-color: var(--main-bg-gray);
    border-radius: 8px;

    transition: background-color 0.25s ease, opacity 0.15s ease;

    /*box-shadow: 0 1px 10px 0 rgba(0,0,0,0.07);*/
}
.content-input__cluster:last-child{
    margin-bottom: 0;
}
.content-input__cluster .content-input__cluster{
    background-color: #f5f7fa;
    /*border: 1px solid #cfd1d3;*/
}
.content-input__cluster:not(.no-hover):hover{
    background-color: #e9edf3;
}
.content-input__cluster:hover .content-input__cluster:hover{
    background-color: #eff3f9;
}
.content-input__cluster--accent{
    border-color: var(--ocean);
}
.content-input__cluster--with-ghost:last-child{
    /* --with-ghost means this cluster if content inputs has the last so called ghost item.
     The ghost item is used to add new items.
     If the maxItems is reached, the ghost item is not needed anymore, so this class (.content-input__cluster--with-ghost) is not added to .content-input__cluster.
     */
    position: relative;
    opacity: 0.5;
}
.content-input__cluster--with-ghost:last-child:before,
.content-input__cluster--with-title:last-child:before{
    content: attr(data-add-new) " "; /*see https://developer.mozilla.org/en-US/docs/Web/CSS/attr*/
    /*content: "Add new ⤵";*/
    position: absolute;
    top: -10px;
    left: 10px;

    color: var(--ocean);
    font-size: 12px;
}
.content-input__cluster--with-ghost:only-child:before{
    content: "Fill in to add an item ⤵";
}
.content-input__cluster--with-ghost:only-child{
    margin-top: 20px;
}

.content-input__cluster:hover{
    opacity: 1;
}
.content-input__control_cluster{
    position: absolute;
    z-index: 100;
    opacity: 0.1;
    top: 50%;
    transform: translateY(-50%) translateX(50%) scale(0.8);
    right: 0;

    /*transition: opacity 0.15s ease;*/
}
.content-input__control_cluster--bottom{
    top: auto;
    bottom: 0;
    transform: translateY(-10%) translateX(50%) scale(0.7);
}
.content-input__control_cluster--left{
    right: auto;
    left: 0;
    transform: translateY(-50%) translateX(-50%);
}
.content-input__control_cluster--top{
    transform: translateY(10%) translateX(50%) scale(0.7);
    top: 0;
}
.content-input__cluster--small .content-input__control_cluster--top{
    /*transform: translateY(-5px) translateX(50%);*/
}
.content-input__cluster--small .content-input__control_cluster--bottom{
    /*transform: translateY(5px) translateX(50%);*/
}
.content-input__cluster--hide-move-buttons:first-child .content-input__control_cluster--move-up,
.content-input__cluster--hide-move-buttons.content-input__cluster--hide-move-buttons--no-add-btn:nth-last-child(2) .content-input__control_cluster--move-down, /*to disable moving down below ghost items, not needed when we have "add new" button*/
.content-input__cluster--hide-move-buttons:last-child .content-input__control_cluster--move-down{
    display: none;
}
.content-input__cluster:hover > .content-input__control_cluster,
.content-input__cluster:hover > div > .content-input__control_cluster{
    /* .content-input__cluster:hover > .content-input__control_cluster - because move up/down controls are placed inside an empty div because React only returns one DOM element*/
    opacity: 1;
}
.content-input__cluster--with-ghost:last-child .content-input__control_cluster{
    /* The last child in always an empty list item. It is there to let users to add a new item. No necessary to delete it.*/
    display: none;
}

.content-input .ant-collapse-borderless > .ant-collapse-item{
    border: none;
}

.content-input__integrations_radio_group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    /*margin-bottom: 20px;*/
}
.content-input__integrations_radio{
    position: relative;
    display: inline-flex;
    align-items: center;

    padding: 0px 5px 0px 10px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: 12px;

    border-radius: var(--main-border-radius);
    border: 1px solid var(--gray);

    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;

    transition: border-color 0.20s ease, background-color 0.20s ease;
}
.content-input__integrations_radio:not(.content-input__integrations_radio--small){
    min-width: 200px;
    max-width: 380px;
}
.content-input__integrations_radio.content-input__integrations_radio--small{
    min-width: 150px;
}
.content-input__integrations_radio.ant-radio-wrapper-checked{
    border-color: var(--ant) !important;
    color: var(--ant);
}
.content-input__integrations_radio.ant-radio-wrapper-checked{
    cursor: pointer; /*fallback*/
}
.content-input__integrations_radio.ant-radio-wrapper-checked:hover{
    border-color: var(--gray);
}


.content-input__integrations_radio_button{
    display: inline-block;

    /*margin-right: 10px;*/
}
.content-input__integrations_radio_button--activate{

}
.content-input__integrations_radio_button--disabled{
    display: none;
}

.content-input__integrations_radio_checked-icon{
    display: none;

    color: #fff;
}
.content-input__integrations_radio__del{
    position: absolute;
    right: -15px;

    opacity: 0;
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    transition: all .2s ease;
}
.content-input__integrations_radio__del.is-popup {
  right: 0;
}
.content-input__integrations_radio:hover .content-input__integrations_radio__del{
    opacity: 1;
}
.content-input__integrations_radio__del_button{
    transform: scale(0.7);
}
.content-input__integrations_radio__rename_button {
  margin-left: 5px;
}

.content-input__integrations_radio.ant-radio-wrapper-checked .content-input__integrations_radio_button--activate{
    display: none;
}
.content-input__integrations_radio.ant-radio-wrapper-checked .content-input__integrations_radio_button--disabled{
    display: inline-block;
}
.content-input__integrations_radio.ant-radio-wrapper-checked .content-input__integrations_radio_button--disabled *,
.content-input__integrations_radio.ant-radio-wrapper-checked .ant-radio-input,
.content-input__integrations_radio.ant-radio-wrapper-checked .ant-radio-checked{
    cursor: default; /*fallback*/
    cursor: not-allowed;
}

.content-input__integrations_radio--small .content-input__integrations_radio_button{
    display: none !important;
}
.content-input__integrations_radio--small{
    height: 26px;
    font-size: 10px;
}


.content-input__integrations_radio:hover{
    border-color: var(--ocean);
}
.content-input__integrations_radio_icon{
    height: 14px;
    margin-right: 7px;
    vertical-align: text-bottom;
    width: 24px;
    object-fit: contain;
    object-position: top center;
}
.content-input__integrations_empty{
    background-color: var(--main-bg-gray);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}


.content-input__integrations_radio__title{
    position: relative;
    padding-right: 10px;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
}
.content-input__integrations_radio__title_text{
    color: inherit;
    transition: opacity 0.2s ease;
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
.content-input__integrations_radio__created{
    position: absolute;
    left: 0;
    top: -1px;
    color: inherit;
    opacity: 0;
    transition: opacity 0.2s ease;
    /*text-transform: capitalize;*/
    vertical-align: middle;
    pointer-events: none;
}
/* .content-input__integrations_radio:hover .content-input__integrations_radio__title_text{
    opacity: 0;
}
.content-input__integrations_radio:hover .content-input__integrations_radio__created{
    opacity: 1;
}

.content-input__integrations_radio:hover .content-input__integrations_radio_icon{
    opacity: 0;
} */
.content-input__integrations_radio_icon{
    transition: opacity 0.2s ease;
}

.content-input__integrations_radio__created_text--small{
    font-size: 0.7em;
    position: relative;
    top: -1px;
}


.content-input__edit_button_opener{
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 10px;
}
.comps .content-input__edit_button_opener .button{
    /*50% smaller*/

    padding: 6px 10px 6px;
    min-width: 60px;
    min-height: 22px;
    border-radius: 2px;
    font-size: 9px;
    border-width: 1px;

    vertical-align: middle;
}
.comps .content-input__edit_button_opener .button .button__text{
    font-size: inherit;
}
.comps .content-input__edit_button_opener .store-button{
    height: 24px;
    border-radius: 3px;
    width: 75px;
    min-width: 60px;
}
.comps .content-input__edit_button_opener .store-button .store-button__img svg{
    height: 75%;
    width: 100%;
}
.comps .content-input__edit_button_opener .pill-link{
    font-size: 8px;
    padding: 3px 7px 3px 4px;

    margin-bottom: 4px;
}
.comps .content-input__edit_button_opener .pill-link__pill{
    height: 12px;
    line-height: 12px;
    padding: 0 3px;
    font-size: 5px;
}
.comps .content-input__edit_button_opener .pill-link__text{
    margin-left: 5px;
}
.comps .content-input__edit_button_opener .pill-link__icon{
    margin-left: 3px;
    transform: scale(0.8);
}

.comps .content-input__edit_button_opener .button:hover,
.comps .content-input__edit_button_opener .button:focus,
.comps .content-input__edit_button_opener .button:active{
    transform: none;
}

.content-input__button_style_options{
    margin-top: 20px;
    padding: 10px 10px 0 10px;
}
.content-input__button_style_option{
    position: relative;
    z-index: 10;

    margin-right: 10px;
    margin-bottom: 15px;

    padding: 3px;
    line-height: 0;
    border-radius: 4px;
}
.content-input__button_style_option .pill-link .icon{
    /*don't know why the icon acts weirdly*/
    position: relative;
    top: 3px;
}
.content-input__edit_button_example{
    margin-left: 15px;
}
.content-input__button_style_option--gray,
.content-input__edit_button_example--gray{
    background-color: #e2e2e2;
    box-shadow: 0 0 9px 4px #e2e2e2;
}
.content-input__edit_img_opener{
    width: 150px;
    height: 32px;
    overflow: hidden;

    margin-right: 10px;

    background-image: linear-gradient(45deg, #D6D6D6 25%, transparent 25%), linear-gradient(-45deg, #D6D6D6 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #D6D6D6 75%), linear-gradient(-45deg, transparent 75%, #D6D6D6 75%);
}
.content-input__edit_img_opener, .content-input__edit_img_opener:hover, .content-input__edit_img_opener:active, .content-input__edit_img_opener:focus{
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    background-color: var(--gray);
}
.content-input__edit_img_opener:hover{
    background-image: none;
}
.content-input__edit_img_opener svg{
    height: 24px;
    width: 24px;
    vertical-align: middle;
}
.content-input__edit_img_opener img{
    height: 24px;
    vertical-align: middle;
}
.content-input__edit_img_opener .primary-layout__icon_placeholder{
    height: 20px;
    width: 20px;
    font-size: 12px;

    transform: scale(0.7);
    transform-origin: center;
}
.content-input__edit_img_opener_box{
    display: flex;
    align-items: center;

    margin-bottom: 10px;
}

.content-input__img_remove_button{
    opacity: 0.1;
}
.content-input__img_preview{
    margin-right: 10px;
}
.content-input__edit_img_opener_box:hover .content-input__img_remove_button{
    opacity: 1;
}

/*.content-input__edit_img_opener_wrapper{*/
    /*margin-bottom: 40px;*/
/*}*/
.content-input__edit_img_opener_wrapper .image-picker{
    background-color: #e8e8e8;
}

.content-input__abstract_icon_items{
    padding-left: 10px;
}
.content-input__abstract_icon_item,
.content-input__emoji_button{
    border: none;
    height: auto;

    padding: 15px;
    box-shadow: none;
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 0;
}
.content-input__abstract_icon_item:hover,
.content-input__emoji_button:hover{
    background-color: var(--main-bg-gray);
}

.content-input__emoji_group{
    margin-bottom: 50px;
}
.content-input__emoji_image{
    height: 34px;
}
.content-input__input__detail_icon{
    max-width: 16px;
}

.content-input__image_picker_box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.content-input__edit_logo_help{
    margin-left: 20px;
    vertical-align: middle;
    opacity: 0.5;

    transition: opacity 0.2s ease;
}
.content-input__edit_logo_icon{
    transform: scale(0.8) translateY(2px);

    transition: transform 0.2s ease;
}
.content-input__image_picker_box:hover .content-input__edit_logo_icon{
    transform: scale(0.8) translateY(2px) translateX(-5px);
}

.content-input__image_picker_box:hover .content-input__edit_logo_help{
    opacity: 0.8;
}

.content-input .ant-collapse-content-active{
    overflow: visible; /*to make sure draftjs popups visible*/
}
.content-input__fixed_nav_box{
    margin-top: 20px;
    padding: 5px;
}
.content-input__switch_box{
  margin-bottom: 10px;
}
.content-input__switch_box:last-child{
  margin-bottom: 0;
}
.content-input__switch_box_label{
  margin-left: 10px;
}
.content-input__input--removable{
    position: relative;
}
.content-input__input--removable:hover .content-input__remove_input_button{
    opacity: 1;
}
.content-input__remove_input_button{
    z-index: 100;
    transform: scale(0.7) translateX(15px);
    position: absolute;
    top: 4px;
    right: 0;
    opacity: 0.1;
}
.uploadcare--widget_status_started {
  display: flex;
  align-items: center;
  gap: 4px;
}
.content-input__move_place-left {
    left: 0;
    transform: translateX(-50%) scale(0.7);
}

.content-input__tooltip-content p:last-child {
    margin-bottom: 0;
}

.content-input__toltip-value {
  background: rgba(255,255,255, 0.1);
  border-radius: 10px;
  padding: 7px 10px;
  margin: 10px 0;
}

.content-input__info-box {
  margin-top: 20px;
}
.content-input__info-item {
  margin-bottom: 20px;
}
.content-input__info-item:last-child {
  margin-bottom: 0;
}
.content-input__info-item p:last-child {
  margin-bottom: 0;
}
.content-input__tooltip-link {
  color: #fff;
  text-decoration: underline;
}
.content-input__tooltip-link:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.content-input__tooltip-link-icon {
  margin-left: 3px;
}
.content-input__tooltip-link-icon--loading {
  margin-left: 8px;
}
.content-input__tooltip-link--disabled {
  text-decoration: none;
  cursor: default !important;
}
.content-input__period-group {
  margin-bottom: 10px;
}
.content-input__period-group .content-input__period-input {
  width: 50%;
}
.content-input__input .content-input__cluster--single {
  margin-bottom: 20px;
}
.content-input__dropdown .ant-dropdown-menu {
  max-height: 213px;
  overflow-y: auto;
}

.content-input .ant-select {
  flex-grow: 1;
}
.content-input .ant-select-selection, .ant-select.is-text-cursor .ant-select-selection {
  cursor: text;
}
.content-input .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.content-input .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .anticon-check {
  display: none;
}
.content-input .ant-select-selection--multiple .ant-select-selection__choice {
  max-width: 300px;
}

.content-input__select-dropdown-with-tooltips .ant-select-dropdown-menu-item {
  padding: 0;
}
.content-input__select-dropdown-with-tooltips .ant-select-dropdown-menu-item .content-input__select-option-wrapper {
  padding: 5px 12px;
}
.content-input__select-option-wrapper {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-component-drawer .ant-drawer-content .ant-drawer-body {
  padding: 0 16px;
}
.edit-component-drawer.is-main .ant-drawer-content .ant-drawer-body {
  padding: 0 8px;
}

.edit-component-drawer input.ant-input, 
.edit-component-drawer textarea.ant-input,
.edit-component-drawer .ant-select-selection,
.edit-component-drawer .ant-input-number
{
  border-radius: var(--border-radius);
}
.edit-component-drawer .ant-input-number-handler-wrap {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.edit-component-drawer .content-input__input_box .rdw-editor-wrapper {
  border-radius: var(--border-radius);
}
.edit-component-drawer .content-input__input_box .rdw-editor-toolbar {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.edit-component-drawer .ant-tabs.ant-tabs-top .ant-tabs-small-bar .ant-tabs-tab {
  padding: 12px 0;
}

.edit-component-drawer .ant-drawer-content .ant-drawer-header {
  padding: 10px 16px;
}

.edit-component-drawer .ant-input-group > .ant-input:last-child, .edit-component-drawer .ant-input-group-addon:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.edit-component-drawer .ant-input-group > .ant-input:first-child, .edit-component-drawer .ant-input-group-addon:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.edit-component-drawer div .ant-input-group.ant-input-group-compact > *:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.edit-component-drawer div .ant-input-group.ant-input-group-compact > *:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
.edit-component-drawer div .ant-input-group.ant-input-group-compact > *:only-child {
  border-radius: var(--border-radius);
}

.edit-component-drawer button.ant-btn:not(.ant-btn-circle):not(.ant-input-search-button) {
  border-radius: var(--border-radius);
}
.edit-component-drawer button.ant-btn.ant-input-search-button {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}
.edit-component-drawer div .ant-radio-button-wrapper:first-child {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.edit-component-drawer div .ant-radio-button-wrapper:last-child {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

/* .edit-component-drawer .content-input:first-child{
  margin-top: 15px;
} */
.edit-component-drawer .content-input__title {
font-size: 14px;
font-weight: 600;
color: #1F1F1F;
margin-bottom: 17px;
}
.edit-component-drawer .content-input__title .content-input__tooltip_icon {
  color: rgba(0, 0, 0, 0.65);
}
.edit-component-drawer .content-input__label {
font-size: 12px;
color: #595959;
}

.edit-component-drawer .content-input:last-child{
  margin-bottom: 100px;
}

.edition-drawer-title {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.content-input__integrations_radio span.ant-radio-inner {
  margin-right: 8px;
}
.content-input__integrations_radio .ant-radio-checked::after {
  width: 16px !important;
}

.content-input__select .ant-select-selection {
  cursor: text;
}

.primary-layout{
    min-height: 100vh;
    background: #fff;
    min-width: 1000px;
}
.primary-layout__home_link{
    position: relative;
    display: inline-block;

    text-decoration: none;
    color: white;

    transition: color 0.3s ease;
    cursor: pointer;
}
.primary-layout__xmas{
    position: absolute;
    left: -10px;
    height: 20px;
    top: 6px;
}
.primary-layout__home_link:focus{
    text-decoration: none;
}
.primary-layout__home_link:hover{
    /*opacity: 0.85;*/
    color: white;
}
.primary-layout__home_link_text{
    margin-left: 10px;

    border-radius: var(--main-border-radius);
    padding: 3px 6px 5px;
    transition: background-color 0.3s ease, opacity 0.3s ease;

    color: inherit;
}
.primary-layout__nav_divider{
    margin-left: 10px;
    margin-right: 10px;

    color: var(--night);
}

.primary-layout__home_link_text:hover{
    background-color: var(--night);
}

.primary-layout__icon_placeholder{
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.primary-layout__nav_container {
  max-width: 1280px;
  margin: 0 auto;
}
.primary-layout .ant-layout-header{
  height: 48px;
  line-height: 48px;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 990;
  border-bottom: 1px solid #595959;
}
.footer__editor {
  transition: background-color 0.25s ease;
}

.primary-layout__notification .ant-notification-notice-description,
.primary-layout__notification .ant-notification-notice-description .paragraph-custom {
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-domain-settings {
  max-width: 600px;
}
.custom-domain-settings__steps-content {
  margin-top: 30px;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 30px;
}
.custom-domain-settings__steps-action {
  margin-top: 30px;
  display: flex;
  gap: 10px;
}
.custom-domain-settings__title {
  margin-top: 0;
  display: flex;
  align-items: center;
}
.custom-domain-settings__content {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}
.custom-domain-settings__buttons-box {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: flex-end
}
.custom-domain-settings__subtitle {
  margin: 0;
}
.custom-domain-settings__title-icon {
  font-size: 14px;
}
.settings-box__input .custom-domain-settings__content .ant-input {
  width: 100%;
}
.custom-domain-settings__content .ant-alert-message {
  margin-bottom: 0.5em;
}

.custom-domain-settings .settings-box__form {
  flex-grow: 1;
}

.content-input-box__footer_button{
    position: relative;
    display: block;
    height: auto;
    padding: 0;

    margin-bottom: 20px;
    overflow: hidden;
}
.content-input-box__footer_button:last-child{
    margin-bottom: 0;
}
.content-input-box__footer_button--active{
   border-color: var(--ocean);
}
.content-input-box__footer_buttons_box{
    margin-top: 30px;
}
.content-input-box__footer_button_icon{
    position: absolute;
    left: 7px;
    top: 3px;

    font-size: 20px;

    transition: opacity 0.2s ease, transform 0.2s ease;

    opacity: 0;
}
.content-input-box__footer_button--active .content-input-box__footer_button_icon{
    opacity: 1;
    transform: none;
}
.content-input-box__footer_button_thumb{
    width: 100%;
}
.content-input__icon {
    font-size: 12px;
    margin-right: 6px;
}
.content-input__button-text {
    display: flex;
    align-items: center;
}
.content-input__inline-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-input__inline-input .content-input__label_box {
  margin-bottom: 0;
}
.content-input__inline-input .ant-input {
  width: 320px;
}

.switch-component {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: flex-start;
}
.switch-component__button {
  position: relative;
  width: calc(50% - 10px);
  box-shadow: var(--card-shadow-small);
  cursor: pointer;
  border-radius: 8px;
  min-height: 32px;
  transition: transform .2s, box-shadow .2s;
}
.switch-component__button.is-full-width {
  width: 100%;
}
.switch-component__button:not(.is-selected):not(.is-loading):hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.switch-component__icon {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 20px;
  z-index: 2;
}
.switch-component__button.is-selected:before,
.switch-component__button.is-loading:before {
  content: '';
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none !important;
  border-radius: 8px;
  background: rgba(255,255,255,0.5);
}
.switch-component__button.is-selected:after {
  content: '';
  z-index: 1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  position: absolute;
  left: -2px;
  top: -2px;
  box-shadow: 0 0 0 2px var(--ant);
  border-radius: 8px;
  pointer-events: none !important;
}
.switch-component .add-page-component__thumb {
  border-radius: 8px;
}

.switch-component__button.is-loading:after {
  display: none;
}
.switch-component__button.is-loading .switch-component__icon {
  display: none;
}

.switch-component__loading-icon {
  position: absolute;
  top: 50%;
  right: 50%;
  font-size: 20px;
  z-index: 2;
  color: var(--ant);
  transform: translate(50%, -50%);
}

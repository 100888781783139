@keyframes skeleton-loader-animation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.skeleton-loader {
  display: inline-block;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 25%,
    rgba(255, 255, 255, 0.2) 35%,
    rgba(255, 255, 255, 0.1) 45%
  );
  background-size: 400% 100%;
  animation: directory-loading 0.9s ease infinite;
  border-radius: 10px;
}

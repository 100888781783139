.image-picker{
    display: inline-block;
    position: relative;

    padding: 20px 5px 10px;
    border: 1px solid var(--main-bg-gray);
    border-radius: 2px;

    min-width: 200px;
    text-align: center;
    border-radius: 8px;
}
.image-picker__img{
    max-width: 100%;
    max-height: 200px;

    transition: opacity 0.25s ease, transform 0.4s ease;
}

.image-picker:hover .image-picker__img{
    opacity: 0.7;
    transform: scale(0.97);
}

.image-picker__controls_box{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255,255,255, 0.85);

    /*transform: scale(0.95);*/
    opacity: 0;
    transition: opacity 0.25s ease, transform 0.25s ease;
}

.image-picker:hover .image-picker__controls_box{
    transform: none;
    opacity: 1;
}

.image-picker__controls{
    transform: translateY(-36px); /*because when an image is small the alt text input overlaps the controls*/
}
.image-picker__controls--small{
    transform: translateY(-17px); /*because when an image is small the alt text input overlaps the controls*/
}
.image-picker__button{
    margin: 0 5px;
}

.image-picker__alt_box{
    margin-top: 15px;
    width: 100%;
    position: relative;
    z-index: 100;
}

.image-picker__filepond .filepond--wrapper {
  width: 100%;
}
.image-picker__filepond .filepond--root {
  margin-bottom: 0;
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  transition: all 0.3s ease;
}
.image-picker__filepond .filepond--drop-label {
  background-color: #FBFBFB !important;
  font-size: 14px;
  color: #595959;
}

.image-picker__filepond .filepond--root:hover {
  cursor: pointer;
  border-color: #40A9FF;
}

.image-picker__filepond .filepond--drop-label.filepond--drop-label label {
  cursor: pointer;
  background-color: #1890FF;
  border-radius: 6px;
  color: #fff;
  padding: 5px 12px;
  transition: background-color 0.3s ease;
}
.image-picker__filepond .filepond--root:hover .filepond--drop-label.filepond--drop-label label {
  background-color: #40A9FF;
}

.image-picker__filepond .filepond--panel-root {
  background-color: #FBFBFB !important;
  border-radius: 8px;
}

.image-picker__filepond .filepond--root {
  border-radius: 8px;
  overflow: hidden;
}

.image-picker__filepond .filepond--root,
.image-picker__filepond .filepond--root .filepond--drop-label {
  min-height: 100px;
}

.image-picker__filepond .filepond--item[data-filepond-item-state="load-invalid"] {
  top: 11px;
}

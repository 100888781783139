.google-preview {
  padding: 12px 16px;
  border-radius: 12px;
  font-family: arial, sans-serif;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px #e8e8e8;
}
.google-preview__title {
  color: #1b0fab;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding: 5px 0;
  word-wrap: break-word;
}
.google-preview__url {
  font-size: 12px;
  max-width: 88%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.google-preview__description {
  word-wrap: break-word;
}

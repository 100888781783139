.blog-post-editor{
    padding: 50px 20px 100px;
    min-height: 100vh;
}
.blog-post-editor__wrapper{
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    // box-shadow: var(--card-shadow);
    /*overflow: hidden;*/ /*it looks slightly better with overflow: hidden but we can not use position:sticky for a fixed nav*/
    background-color: white;
    box-shadow: 0px -1.46302px 7.24861px 0px rgba(0, 0, 0, 0.07), 0px -4.91399px 24.34658px 0px rgba(0, 0, 0, 0.04), 0px -22px 109px 0px rgba(0, 0, 0, 0.03);
}
.blog-post-editor__wrapper .editor__templates_nav:not(.editor__templates_nav--sticky) {
    position: relative; //the displayNav function displays markup with the editor__templates_nav class in it. editor__templates_nav is pos:absolute but we need it position: relative for blog post editor screen
  }
.blog-post-editor__wrapper .nav-02:not(.nav-02--sticky){
//  nav is pos: absolute by default. sometimes it is pos:static (when the first component of a page is not Header). But in case of blog we do not have first component - there are no components at all. So it is forced to relative here in styles
    position: relative;

    z-index: 400; //lower than .editor__nav_edit_control
}
.blog-post-editor__main-title{
    margin: 0 auto;
    width: 100%;
    max-width: 680px;
    padding-top: 30px;
    margin-bottom: 15px;
}
.blog-post-editor__main-title_input {
    width: 100%;
    height: 100%;
    min-height: 30px;
    padding: 0;
    box-shadow: none;
    border: none;
    resize: none;
    font-weight: 700;
    color: #37352f;
    caret-color: #37352f;
    line-height: 1.2;
    font-size: 50px;
    overflow: hidden;
    
    &::placeholder {
      color: #cccccc;
    }
  
    &:focus {
      box-shadow: none;
    }
}
.custom_fonts .custom-google-fonts-enabled .blog-post-editor__main-title_input{
    font-family: inherit; // we need to override the font styles for h1 because inside <h1> tag there is textarea which inherits regular text font family
}

.blog-post-editor__bottom-center{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.blog-post-editor__save_button_tick{
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    text-align: center;
    margin: auto;
    z-index: 100;
    font-size: 30px;
    animation: move_up_and_hide 1s ease-out forwards; //see Editor.css
}
.blog-post-editor__designed_with{
    margin-top: 50px;
    text-align: center;
    opacity: 0.3;
    text-shadow: 0 1px 1px #fff;
    user-select: none;
    font-size: 12px;
}
.blog-post-editor__save_button_box{
    position: fixed;
    z-index: 1020;
    bottom: 28px;
    left: 50%;
    transform: translateX(-50%);

    border-radius: 2px;
}
.blog-post-editor__save_button_box--accent{
    box-shadow: 0 0 0 rgba(204, 99, 91, 0.8);
    animation: pulsation 2s infinite; //see Editor.css
}

.website-redirects{

}
.website-redirects__item{
    margin-bottom: 15px;
}
.website-redirects__item:last-child{
    margin-bottom: 0;
}
.website-redirects__items{
    margin-bottom: 15px;
}

.website-redirects__form{
    display: flex;

    align-items: center;
}
.website-redirects__inputs{
    position: relative; /*for del button*/
    display: flex;

    width: 100%;
    max-width: 650px;

    align-items: center;
    justify-content: flex-start;

}
.website-redirects__input{
}
.website-redirects__input .ant-input{
    width: 100%;
}
.website-redirects__input--from{
    /*margin-right: 20px;*/
}
.website-redirects__inputs_divider{
    margin-left: 10px;
    margin-right: 10px;
    opacity: 0.4;
}
.website-redirects__input--to{
    margin-right: 15px;
}

.website-redirects__item:hover .website-redirects__age{
    opacity: 0.6;
}
.website-redirects__age{
    margin-top: 3px;

    font-size: 10px;
    opacity: 0;
    transition: 0.2s ease opacity;
}
.website-redirects__item:hover .website-redirects__delete{
    opacity: 1;
}
.website-redirects__delete{
    z-index: 10;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%) scale(0.7);

    opacity: 0;
    transition: 0.2s ease opacity;
}

.add-page-component .ant-anchor-link-title {
    text-decoration: none !important;
}
.add-page-component .ant-anchor-wrapper {
  padding-bottom: 80px;
}

.add-page-component__menu {
    height: 100%;
    overflow: auto;
}

.add-page-component .ant-anchor-link:nth-of-type(4n) {
    margin-bottom: 20px;
}

.add-page-component .ant-modal-body {
    padding: 0;
}

.add-page-component .ant-layout-sider,
.add-page-component .ant-layout-content {
    height: calc(100vh - 100px);
}

.add-page-component .ant-layout-sider {
    /* DELETE WHEN ADD THE SIDER*/
    /*display: none;*/
    /* DELETE WHEN ADD THE SIDER*/


    background-color: #FAFAFA;
    border-radius: 0 0 0 4px; /*I do not want to apply overflow: hidden; to the whole ant.design modal becuase it may lead to unpredictable bugs*/
}

.add-page-component .ant-anchor-wrapper {
    background-color: transparent;
}

.add-page-component .ant-menu-root {
    padding-top: 10px;
    height: 100%;
}

.add-page-component .ant-layout-content {

    background-color: white;
}

.add-page-component__search-bar-container {
  position: sticky;
  top: 0;
  z-index: 20;
  background: #fff;
  margin-left: 200px;
}

@media (max-width: 1200px) {
    /*no sider on smart laptops*/
    .add-page-component .ant-layout-sider {
        display: none;
    }

    .ant-layout-content .add-page-component__container {
        margin-left: 0;
    }

    .ant-layout-content .add-page-component__search-bar-container {
        margin-left: 0;
    }
}

.add-page-component__container {
  padding: 0 20px;
  margin-left: 200px;
}

.add-page-component__menu {
    padding: 0 10px 0px 20px;
    overflow: hidden;
}

.add-page-component .ant-layout-content::-webkit-scrollbar, .add-page-component .ant-anchor-wrapper::-webkit-scrollbar {
    width: 4px;
}

.add-page-component .ant-layout-content::-webkit-scrollbar-track, .add-page-component .ant-anchor-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.add-page-component .ant-layout-content::-webkit-scrollbar-thumb, .add-page-component .ant-anchor-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}



.add-page-component__categories {
    list-style: none;
    padding: 0;
}

.add-page-component__category {

}


.add-page-component__category_title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.add-page-component__info {
    margin-left: 5px;

    font-size: 12px;
    font-weight: 400;

    opacity: 0.6;
}

.add-page-component__components_list {
    list-style: none;
}

.add-page-component__component_item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(33% - 20px);
    margin-right: 16px;
    margin-bottom: 40px;
    font-size: 0;
}

.add-page-component__component_item:nth-child(3n) {
    margin-right: 0;
}

.add-page-component__lock {
    border-radius: 999px;
    background-color: white;
    border: 2px solid var(--night);
    font-size: 12px;
    line-height: 12px;
    padding: 3px 10px;
    width: 50px;
    text-transform: uppercase;
    font-weight: 700;
}

.add-page-component__lock, .add-page-component__premium_mark {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: 10;
}

.add-page-component__premium_mark {
    font-size: 14px;
}

.add-page-component__button {
    position: relative;
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.05), 0 0 0 1px var(--gray);
    padding: 0;
    border-radius: var(--main-border-radius);
    overflow: hidden;
    outline: none;
    z-index: 0;

    transition: box-shadow 0.15s ease, transform 0.15s ease, z-index 0.15s ease;
}
.add-page-component__button:not(.is-loading) {
  cursor: pointer;
}

.add-page-component__thumb {
    width: 100%;
}

.add-page-component__button:not(.is-loading):hover {
    transform: scale(1.25);
    z-index: 10;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}


@media (max-width: 1200px) {
    .add-page-component__button:not(.is-loading):hover {
        transform: translateY(-4px);
        box-shadow: var(--card-shadow), 0 0 0 1px var(--gray);
        z-index: 0;
    }
}

.add-page-component__label {
    position: absolute;
    left: 7px;
    bottom: 7px;
    padding: 1px 4px;
    border-radius: 2px;

    background-color: white;
    font-size: 10px;

    opacity: 0.2;

    transition: opacity 0.25s ease;
}

.add-page-component__description {
    position: absolute;
    left: 0px;
    bottom: 0px;
    font-size: 12px;
    width: 100%;
    color: #fff;
    opacity: 0;
    display: flex;
    align-items: flex-end;
    padding: 12px 12px 12px;
    text-align: left;

    transform: translateY(8px);
    transition: opacity 0.25s ease, transform 0.15s ease;
}
.add-page-component__description::after {
  content: '';
  background: rgba(0,0,0,0.9);
  filter: blur(24px);
  position: absolute;
  left: -50%;
  bottom: -32px;
  width: 200%;
  height: calc(100% + 32px);
  z-index: -1;
  will-change: filter;
}

.add-page-component__button:not(.is-loading):hover .add-page-component__label {
    opacity: 0.9;
}
.add-page-component__button:not(.is-loading):hover .add-page-component__description {
    opacity: 1;
    transform: translateY(0);
}

.add-page-component__loading-overlay {
  position: absolute;
  left: 0px;
  bottom: 0px;
  font-size: 32px;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.7);
  color: var(--ant);
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-height: 900px) {

    .add-page-component .ant-anchor-link {
        padding-bottom: 5px;
        padding-top: 5px;

        /*font-size: 12px;*/
    }
}

@media (max-height: 800px) {
    .add-page-component .ant-anchor-link:nth-of-type(4n) {
        margin-bottom: 10px;
    }
}

@media (max-height: 700px) {
    .add-page-component .ant-anchor-link {
        font-size: 12px;
    }
}

.add-page-component .ant-anchor {
    padding-top: 10px;
}

.add-page-component .ant-anchor-ink::before {
    width: 1px;
    background-color: #e8e8e8;
}

.add-page-component__paste_component_button {
    position: absolute;
    bottom: 20px;
    right: 50px;
    z-index: 11;
}

.add-page-component__search-bar-container .add-page-component__search-bar-input.ant-input-affix-wrapper .ant-input {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgb(232, 232, 232);
  border-color: rgb(232, 232, 232);
  height: 48px;
  padding-left: 40px;
}
.add-page-component__search-bar-input .ant-input:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) inset;
}
.add-page-component__search-bar-input .ant-input-prefix {
  left: 20px; 
}

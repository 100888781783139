.html-export{

}
.html-export__buttons_list{
    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: column;
}

.html-export__button_item{
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 10px;
    width: auto;
    gap: 2px;
}
.html-export__button_item > .ant-btn {
    flex-shrink: 0;
}
.html-export__page_title_text{
    position: relative;
    padding-right: 10px;
}

.html-export__button_item:last-child{
    margin-bottom: 0;
}
.html-export__link{
    position: absolute;
    opacity: 0;
    right: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translateX(5px);

    transition: opacity 0.25s ease, transform 0.25s ease;
}
.html-export__button_item:hover .html-export__link{
    opacity: 1;
    transform: translateX(0);
}

.html-export__page_title{
    margin-left: 10px;
}
.html-export__page_title_prefix{
    font-size: 14px;
}
.html-export__big_title{
    position: relative;
}
.html-export__big_title:after{
    content: "";

    position: absolute;
    background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
    right: -2px;
    height: 17px;
    width: 40px;
}
.html-export__assets{
}
.html-export__step{
    margin-bottom: 40px;
}
.html-export__step:last-child {
  margin-bottom: 0;
}
.html-export__step_info{
    display: block;
    margin-bottom: 15px;
}
.html-export__step_image{
    float: right;
    width: 205px;
}

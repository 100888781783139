.account_fields__rows{
  margin-top: 20px;
}
.account_fields__row{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.account_fields__action{
  flex-grow: 1;
  text-align: right;
}
.account_fields__value .anticon {
  color: var(--ant);
}
.account_fields__value_wrapper {
  display: inline-block;
}
.account_fields__icon {
  margin-left: 5px;
}
.account_fields__icon.account_fields__icon--warning {
  color: var(--orange);
}
.account_fields__warning_text {
  max-width: 500px;
  margin-bottom: 20px;
}
.account_fields__second_warning_text {
  margin-bottom: 24px;
}
.account_fields__value {
  color: rgba(0,0,0,0.45);
  font-size: 12px;
  margin-top: 2px;
  max-width: 325px;
}

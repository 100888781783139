.popup-menu__content > * {
    margin-bottom: 10px;
}
.popup-menu__content > *:last-child {
    margin-bottom: 0;
}
.popup-menu__items-wrapper > * {
    margin-bottom: 10px;
}
.popup-menu__items-wrapper > *:last-child {
    margin-bottom: 0;
}
.popup-menu__item {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--piter);
    border-radius: 4px;
    padding: 8px 5px 8px 8px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.popup-menu__item.ant-radio-wrapper-checked:not(.popup-menu__item_disabled) {
    border-color: var(--ant);
    color: var(--ant);
}
.popup-menu__item:hover {
    color: var(--ant);
}
.popup-menu__item:hover .content-input__integrations_radio__del {
    opacity: 1;
}
.popup-menu__edit {
    right: -28px;
}
.popup-menu__edit-button {
    transform: scale(1);
}
.popup-menu__item_disabled.ant-radio-wrapper {
    cursor: not-allowed;
}

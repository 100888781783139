.editor{
    padding: 50px 20px 250px;
    min-height: 100vh;
}
.editor__wrapper{
    position: relative;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    /* box-shadow: var(--card-shadow); */
    /*overflow: hidden;*/ /*it looks slightly better with overflow: hidden but we can not use position:sticky for a fixed nav*/
    background-color: white;
    box-shadow: 0px -1.46302px 7.24861px 0px rgba(0, 0, 0, 0.07), 0px -4.91399px 24.34658px 0px rgba(0, 0, 0, 0.04), 0px -22px 109px 0px rgba(0, 0, 0, 0.03);
}

.editor__add_button{
    height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.editor__add_button_box{
    text-align: center;
}
.editor__add_button_info{
    margin-top: 10px;
    max-width: 300px;
}
.editor__components{
    padding: 0;
    margin: 0;

    background-color: white;
}
.editor__component{
    list-style: none;
}
.editor__save_button_box{
    position: fixed;
    z-index: 1020;
    bottom: 28px;
    left: 50%;
    transform: translateX(-50%);

    border-radius: 2px;

}
.editor__save_button_box--accent{
    box-shadow: 0 0 0 rgba(204, 99, 91, 0.8);
    animation: pulsation 2s infinite;
}
@keyframes pulsation {
    0% {
        box-shadow: 0 0 0 0 rgba(204, 99, 91, 0.8);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204, 99, 91, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204, 99, 91, 0);
    }
}


.editor__templates_nav{
    position: absolute;
    width: 100%;
    z-index: 200;
}
.editor__templates_nav--static{
    background-color: #fff;
    position: static;
}
.editor__templates_nav--sticky{
    position: sticky;
    top: 48px;
}

.editor__templates_nav--static .nav-02{
    /* Nav is static when the first component is not header. If header - it is position:absolute */
    position: relative;
}
.comps .custom_fonts .editor__nav_edit_control * {
  font-family: -apple-system, "system-ui", "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.editor__nav_edit_control, .editor__footer_edit_control{
    position: absolute;
    opacity: 0;
    right: 20px;
    top: 0;
    z-index: 500;
    transition: opacity .15s ease;

    /*padding: 15px 30px;*/
}

.editor__templates_nav:hover .editor__nav_edit_control,
.editor__templates_nav.editor__templates_nav--force-visible-controls .editor__nav_edit_control {
    opacity: 1;
}
.editor__templates_footer{
    position: relative;
}
.editor__templates_footer:hover .editor__footer_edit_control,
.editor__templates_footer.editor__templates_footer--force-visible-controls .editor__footer_edit_control {
    opacity: 1;
}

.editor__designed_with{
    margin-top: 50px;
    text-align: center;
    opacity: 0.3;
    text-shadow: 0 1px 1px #fff;

    font-size: 12px;
}



.editor__save_button_tick{
    position: absolute;
    left: 0;
    right: 0;
    top: -20px;
    text-align: center;

    margin: auto;

    z-index: 100;

    font-size: 30px;

    animation: move_up_and_hide 1s ease-out forwards;
}

@keyframes move_up_and_hide {
    0%{

    }
    50%{
        opacity: 1;
    }
    90%{
        transform: translateY(-30px);
    }
    100%{
        opacity: 0;
    }
}

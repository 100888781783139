.other-04__spinner {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border: 3px solid #303030;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  animation: other04rotation 1s linear infinite;
}
.uni-is-dark-bg .other-04__spinner {
  border-color: #fff;
  border-bottom-color: transparent;
}

@keyframes other04rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.other-04__widget-container.is-loading {
  padding: 40px 30px;
}

.other-04__widget-container {
  padding: 0 30px;
  max-width: 1440px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
  iframe {
    border-radius: 12px;
  }
}

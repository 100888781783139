.payment_info__empty_box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 200px;
  text-align: center;

  margin: auto;
}
.payment_info__empty_text{

}
.payment_info__empty_image{
  height: 70px;
  margin-bottom: 20px;
}

.payment_info__billing{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  padding: 20px 30px;

  border: 1px solid var(--gray);
  border-radius: 4px;

}
.payment_info__method_card_blur{
  filter: blur(5px);
  opacity: 0.5;
  margin-left: 20px;
  margin-right: 6px;
}
.payment_info__method_card_number{
  font-weight: 700;
}

.payment_info__list_box{
  margin-top: 40px;
  max-width: 800px;
}
.payment_info__skeleton_box{
  /* max-width: 800px; */
  /* padding-top: 20px; */
}
.payment_info__skeleton_box .ant-skeleton-paragraph li:last-child{
  display: none;
}
.payment_info__skeleton_box .ant-skeleton-content .ant-skeleton-paragraph > li{
  height: 56px
}
.payment_info__skeleton_box .ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 1px;
}

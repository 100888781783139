
.settings-box__title{
    margin: 0;
    font-size: 16px;
    padding-bottom: 2px;
}
.settings-box__input{
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;
}
.settings-box__input:last-child{
    margin-bottom: 0;
}
.settings-box__input_label{
    display: flex;
    align-items: center;

    margin-bottom: 8px;
}
.settings-box__input_info{
    position: relative;
    top: 9px;
    margin-right: 8px;
}
.settings-box__input_info_text{
    margin-left: 8px;
    margin-right: 12px;

    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
}
.settings-box__input_info_subtext{
    margin-left: 9px;
    opacity: 0.6;

    font-size: 12px;
    font-weight: 300;
}

.settings-box__input .ant-input{
    width: 256px;
}
.settings-box__input .full-width-input .ant-input{
    width: 100%;
}
.settings-box__input_box.full-width {
    width: 100%;
}
.settings-box__input .settings-box__input_box.full-width .ant-input,
.settings-box__input .settings-box__input_box.full-width .ant-input-number {
  width: 100%;
}
.settings-box__input_box--large .ant-input{
    width: 100%;
}
.settings-box__form{
    display: flex;
    align-items: flex-start;
}
.settings-box__form_save{
    /*Shameful centering*/
    position: relative;
    bottom: 1px;
    flex-shrink: 0;

    /*margin-left: 15px;*/
}
.settings-box__input_box{
    /*because save button on the right*/
    margin-right: 15px;
}
.settings-box__input_box--large{
    width: 100%;
}
.settings-box__error_message{
    max-width: 400px;
    margin-top: 3px;

    color: var(--blood);

    transition: opacity 0.3s ease;
}

.settings-box__palettes{
}
.settings-box__features_list{
  list-style: none;
}
.settings-box__features_list_item{
  margin-bottom: 5px;
}
.settings-box__features_list_item:last-child{
  margin-bottom: 0;
}
.settings-box__features_list .anticon{
  margin-right: 5px;
}
.settings-box__shared_website_restriction_box{
  border-radius: 4px;
  padding: 10px;
  width: fit-content;
  background-color: var(--main-bg-gray);
}
.settings-box__extra-button {
    margin-top: 15px;
}

.subdomain-select .ant-select-selection {
  border-radius: 0 4px 4px 0;
  border-left: none;
}

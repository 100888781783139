.fill-template-modal {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}
.fill-template-modal__item {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 20px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  cursor: pointer;
}
.fill-template-modal__title,
.fill-template-modal__subtitle {
  display: block;
}
.fill-template-modal__title {
  line-height: 22px;
}
.fill-template-modal__subtitle {
  font-size: 12px;
  line-height: 20px;
}
.fill-template-modal__icon {
  font-size: 20px;
}
.fill-template-modal__text-wrapper {
  text-align: left;
}

.fill-template-modal__item:hover {
  background-color: #f5f5f5;
}

.fill-template-modal__item,
.fill-template-modal__title,
.fill-template-modal__subtitle,
.fill-template-modal__icon,
.fill-template-modal__item--ai path {
  transition: all 0.2s;
}

.fill-template-modal__generate-button {
  display: inline-flex;
  align-items: center;
  line-height: 0;
  background-color: var(--ai);
  border-color: var(--ai);
}
.fill-template-modal__generate-button:not(.ant-btn-loading) {
  gap: 8px;
}
.fill-template-modal__generate-button-icon path {
  fill: #fff;
}
.fill-template-modal__generate-button:hover,
.fill-template-modal__generate-button:focus {
  background-color: #9254de;
  border-color: #9254de;
}
.fill-template-modal__generate-button:active {
  background-color: #531dab;
  border-color: #531dab;
}

form textarea.fill-template-modal__textarea {
  margin-bottom: 0;
}
.fill-template-modal__textarea:hover:not([disabled]) {
  border-color: #9254de;
}
.fill-template-modal__textarea:focus:not([disabled]) {
  border-color: #9254de;
  box-shadow: 0 0 0 2px rgb(114 46 209 / 20%);
}
.fill-template-modal__form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}
.fill-template-modal__character-counter {
  color: #00000073;
}

.plugins_drawer{
}

.plugins_drawer__option{
    position: relative;

    border-radius: 4px;
    border: 1px solid transparent;
}
.plugins_drawer__option_header{
    padding: 20px 20px 20px 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.plugins_drawer__option_title{
    margin: 0;
}

.plugins_drawer__option--stripe{
    border-color: #32325d;
    background-color: #32325d;
}
.plugins_drawer__option--utm{
    border-color: #ef9233;
    background-color: #ef9233;
}

.plugins_drawer__option_title_logo{
    height: 40px;
    vertical-align: middle;
}

.stripe_logo_fill{
    transition: fill 0.25s ease;
}
.plugins_drawer__option--stripe .stripe_logo_fill{
    fill:  #ffffff;
}

.plugins_drawer__option_title_word{
    /*text-transform: uppercase;*/
    font-weight: 400;
    font-size: 14px;
    vertical-align: middle;

    display: inline-flex;
    align-items: center;
    color: #fff;
}

.plugins_drawer__option_title_word_text{
    /*vertical-align: middle;*/
    margin-right: 7px;
}
.plugins_drawer__option_title_word_logo{
    height: 1em;
    /*vertical-align: middle;*/
}

/*.plugins_drawer__option_before, .plugins_drawer__option_after{*/
    /*display: flex;*/
    /*align-items: center;*/
    /**/
    /*position: absolute;*/
    /*top: 14px;*/
    /*right: 20px;*/
    /**/
    /*height: 32px;*/
/*}*/



.plugins_drawer__option_body{
    padding: 20px 20px 40px 20px;
    background-color: white;
}


.plugins_drawer__stripe_edit_product_row{
    margin-bottom: 20px;
}
.plugins_drawer__stripe_edit_product_row:last-child{
    margin-bottom: 0;
}


.plugins_drawer__option_body_products_items{
    margin-top: 30px;
    margin-bottom: 30px;
}
.plugins_drawer__stripe_product{
    position: relative;
    padding: 20px;
    border-radius: 4px;
    /*border: 1px solid var(--);*/

    box-shadow: var(--card-shadow);
    margin-bottom: 30px;
}
.plugins_drawer__stripe_product:last-child{
    margin-bottom: 0;
}

.plugins_drawer__stripe_product_side{
    display: flex;
    flex-direction: column;

    position: absolute;
    z-index: 100;
    opacity: 0.1;
    top: 50%;
    transform: translateY(-50%) translateX(50%);
    right: 0;

    transition: opacity 0.2s ease;
}
.plugins_drawer__stripe_product:hover .plugins_drawer__stripe_product_side{
    opacity: 1;
}


.plugins_drawer__stripe_product_control{
    margin: 5px 0;
}

.plugins_drawer__stripe_product_body{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.plugins_drawer__stripe_product_info{
    display: flex;
    align-items: center;
}
.plugins_drawer__stripe_product_image{
    max-height: 50px;
    max-width: 50px;

    margin-right: 15px;
}
.plugins_drawer__stripe_product_title{
    margin-right: 7px;
}
.plugins_drawer__stripe_product_currency{
    font-size: 10px;
    opacity: 0.6;
    margin-left: 3px;
}

.plugins_drawer__option_body_add_product_button_box{
    display: flex;
    justify-content: center;
}
.plugins_drawer__fields{
    width: 100%;
}
.plugins_drawer__test_url{
    margin-left: 13px;
}
.changelog {
    --modal-width: 1000px;
    --modal-height: 500px;
    --frame-offset: -352px;

    padding: 20px 32px;
    width: 100%;
    max-width: var(--modal-width);
}
.changelog__content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0 0 4px 4px;
    position: absolute;
    height: calc(var(--modal-height) - var(--frame-offset));
    top: var(--frame-offset);
}
.changelog .ant-modal-body {
    padding: 0;
    position: relative;
    overflow: hidden;
    height: var(--modal-height);
}
.changelog__spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.ant-modal-mask {
    z-index: 1200;
}
.ant-modal-wrap {
    z-index: 1210;
}

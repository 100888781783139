.deals_modal__items_box {
    /*text-align: center;*/
}

.deals_modal__item {
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 40px;
    width: 285px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--card-shadow);
    vertical-align: top;
    text-align: left;
}

.deals_modal__item_top {
    background-color: white;
    padding: 24px;
}

.deals_modal__item_logo {
    max-height: 34px;
}

.deals_modal__item_body {
    padding: 14px 24px 20px;
}

.deals_modal__item_info {

}

.deals_modal__item_title {
    font-size: 20px;
    color: inherit;
}

.deals_modal__item_description {
    color: inherit;
}

.deals_modal__item_action {

}

.deals_modal__item_code_box {
    margin-bottom: 20px;
}

.deals_modal__item_code {
    user-select: all;
}

.deals_modal__hidden {
    filter: blur(5px);
}

.deals_modal__item_project_url {
    border: 1px solid white;
}
.blog-editor-post-controls{
    list-style: none;

    display: flex;
    margin-bottom: 0;
}


.blog-editor-post-controls__item{
    margin-left: 15px;
}
.blog-editor-post-controls__item:first-child{
    margin-left: 0;
}

.blog-editor-post-controls__input_label{
    display: flex;
    align-items: center;

    margin-bottom: 15px;
}

.blog-editor-post-controls__modal-item{ 
    margin-bottom: 45px;
}

.blog-editor-post-controls__modal .ant-modal-footer{
    position: sticky;
    bottom: 0;
    z-index: 999;
    background-color: #fff;
}

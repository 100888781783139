.cms-sidebar iframe {
  border: none;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}
.cms-sidebar {
  height: 100vh;
}

.website-seo-settings{

}

.website-seo-settings__url{
    width: fit-content;
}
.website-seo-settings__url_text{
    transition: opacity 0.2s ease;
}
.website-seo-settings__url_text--opacity{
    opacity: 0.4;
}
.website-seo-settings__disallow{
    width: fit-content;
}
